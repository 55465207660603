export const ProdProducts = [
  {
    id: "prod_PsGnChekwNf6Am",
    name: "Pay Once Plan - 10GB Storage",
    price: 19.99,
    storage: 10,
    plan: "payOnce",
    priceId: "price_1PyUjpLznOFmolWvpuj35uOY",
  },
  {
    id: "prod_PsGnL6grQiweBy",
    name: "Pay Once Plan - 20GB Storage",
    price: 29.99,
    storage: 20,
    plan: "payOnce",
    priceId: "price_1PyUkKLznOFmolWvJwWH9eYk",
  },
  {
    id: "prod_PsGnexL1BuXiZx",
    name: "Pay Once Plan - 30GB Storage",
    price: 39.99,
    storage: 30,
    plan: "payOnce",
    priceId: "price_1PyUkrLznOFmolWvLt8K1syi",
  },
  {
    id: "prod_PsGnhdTAV94yUQ",
    name: "Pay Once Plan - 40GB Storage",
    price: 49.99,
    storage: 40,
    plan: "payOnce",
    priceId: "price_1PyUlaLznOFmolWvxyUBNME6",
  },
  {
    id: "prod_PsGnaf1q03tNKp",
    name: "Pay Once Plan - 50GB Storage",
    price: 59.99,
    storage: 50,
    plan: "payOnce",
    priceId: "price_1PyUmhLznOFmolWvmXvjSVw1",
  },
  {
    id: "prod_PsGnioHb1uPr86",
    name: "Yearly Subscription Plan - 10GB Storage",
    price: 29.99,
    storage: 10,
    plan: "subscription",
    priceId: "price_1PyUnOLznOFmolWvEEFAS6x6",
  },
  {
    id: "prod_PsGnm78rGPUiEE",
    name: "Yearly Subscription Plan - 20GB Storage",
    price: 49.99,
    storage: 20,
    plan: "subscription",
    priceId: "price_1PyUo8LznOFmolWvIlKELpIW",
  },
  {
    id: "prod_PsGnRC1WaXVgcq",
    name: "Yearly Subscription Plan - 30GB Storage",
    price: 69.99,
    storage: 30,
    plan: "subscription",
    priceId: "price_1PyUolLznOFmolWvWm7yyPJ8",
  },
  {
    id: "prod_PsGnuvwIbiTUMh",
    name: "Yearly Subscription Plan - 40GB Storage",
    price: 89.99,
    storage: 40,
    plan: "subscription",
    priceId: "price_1PyUpRLznOFmolWvKfJYBG2F",
  },
  {
    id: "prod_PsGnSxnMmCc8Vt",
    name: "Yearly Subscription Plan - 50GB Storage",
    price: 109.99,
    storage: 50,
    plan: "subscription",
    priceId: "price_1PyUqOLznOFmolWvPvxxZFoe",
  },
  {
    id: "prod_PsGmbh39heNU3h",
    name: "Time Capsule Plan",
    price: 99.0,
    storage: null,
    plan: "timeCapsule",
    priceId: "price_1P2WFOLznOFmolWvkLS7mKZI",
  },
  {
    id: "prod_PsGlyNistMHS14",
    name: "Metallic badge",
    price: 45.99,
    storage: null,
    plan: "badge",
    priceId: "price_1P2WDyLznOFmolWvYIJBIrCK",
  },
];
