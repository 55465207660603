import { useState, useRef, useEffect } from "react";
import { Back, LinkifyText, ResponsiveContainer, Text } from "../../../common";
import { Album, Layout } from "../../../types/album.type";
import { useTranslations } from "use-intl";

const AlbumDescription = ({ album }: { album: Album }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isClamped, setIsClamped] = useState(false);
  const textRef = useRef<HTMLDivElement>(null);
  const t = useTranslations("Album");

  useEffect(() => {
    const checkClamped = () => {
      if (textRef.current) {
        setIsClamped(
          textRef.current.scrollHeight > textRef.current.clientHeight
        );
      }
    };

    checkClamped();
    window.addEventListener("resize", checkClamped);
    return () => window.removeEventListener("resize", checkClamped);
  }, [album.description]);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    album.description && (
      <div
        className={`mt-6 mx-auto ${
          album.layout !== Layout.SINGLE ? "md:mb-14 max-w-[520px]" : "md:mb-8"
        } mb-10 text-center`}
      >
        <div className="relative">
          <Text ref={textRef} className={`line-clamp-4`} color="gray">
            <LinkifyText text={album.description} />
          </Text>
          {isClamped && (
            <span
              className="hover:underline cursor-pointer"
              onClick={toggleExpand}
            >
              {t("seeMore")}
            </span>
          )}
        </div>

        <ResponsiveContainer opened={isExpanded} close={toggleExpand}>
          <>
            <Back onClick={toggleExpand} />
            <Text color="gray" className="pt-4 px-2 text-left">
              <LinkifyText text={album.description} />
            </Text>
          </>
        </ResponsiveContainer>
      </div>
    )
  );
};

export default AlbumDescription;
