import React, { useEffect, useRef, useState } from "react";
import { VideoComponent } from "../../../common/Image";
import { getImageUrl } from "../../../lib/utils";
import { ImageType } from "../../../types/image-type.enum";
import { Image } from "../../../types/image.type";
import { motion } from "framer-motion";

const Slide = ({
  image,
  currentIndex,
}: {
  image: Image;
  currentIndex: number;
}) => {
  const imageUrl = getImageUrl(image?.url);
  const thumbnailUrl = getImageUrl(image?.thumbnailUrl);
  const [isVertical, setIsVertical] = useState(
    window.innerHeight > window.innerWidth
  );
  const [imageDimensions, setImageDimensions] = useState({
    width: 0,
    height: 0,
  });
  const imageRef = useRef<HTMLImageElement>(null);

  const calculateImageDimensions = (
    naturalWidth: number,
    naturalHeight: number
  ) => {
    let maxHeight = window.innerHeight * 0.9;
    let maxWidth = isVertical
      ? window.innerWidth - 16 * 2
      : window.innerWidth * 0.6;

    const aspectRatio = naturalWidth / naturalHeight;
    let width = maxWidth;
    let height = maxWidth / aspectRatio;

    if (height > maxHeight) {
      height = maxHeight;
      width = maxHeight * aspectRatio;
    }

    setImageDimensions({ width, height });
  };

  useEffect(() => {
    const handleResize = () => {
      setIsVertical(window.innerHeight > window.innerWidth);
      if (imageRef.current) {
        calculateImageDimensions(
          imageRef.current.naturalWidth,
          imageRef.current.naturalHeight
        );
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isVertical]);

  // Load and resize handler
  const handleImageLoad = (
    event: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    const target = event.target as HTMLImageElement;
    calculateImageDimensions(target.naturalWidth, target.naturalHeight);
  };

  return (
    <motion.div
      key={currentIndex}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{
        duration: 1,
      }}
      className={`absolute inset-0 ${
        !isVertical && "h-full"
      } flex flex-col justify-center items-center`}
    >
      {image.type === ImageType.VIDEO ? (
        <div
          style={{
            ...(isVertical && { width: window.innerWidth - 16 * 2 }),
          }}
          className={`${!isVertical && "max-h-[90%] max-w-[60%]"} flex-1`}
        >
          <VideoComponent
            withPlayIcon={false}
            isVisible
            thumbnailUrl={thumbnailUrl}
            videoUrl={imageUrl}
            className="rounded-xl"
          />
        </div>
      ) : (
        <img
          ref={imageRef}
          src={imageUrl}
          alt={image.description}
          onLoad={handleImageLoad}
          style={{
            width: `${imageDimensions.width}px`,
            height: `${imageDimensions.height}px`,
            maxWidth: "100%",
            maxHeight: "90%",
          }}
          className="rounded-xl"
        />
      )}
    </motion.div>
  );
};

export default React.memo(Slide);
