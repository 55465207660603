import { useMemo, useState } from "react";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { ScrollArea } from "@mantine/core";
import Filter from "./Filter";
import Pagination from "./Pagination";
import { Text } from "..";

function ReactTable<T>({
  columns,
  loading,
  page = 1,
  search,
  data,
  withFilters,
  emptyMessage,
  filterOnChange,
  debug = false,
  initialPageSize = 20,
  onRowClick,
  withPagination,
  visibleColumns = {},
  onPageChange,
}: {
  loading?: boolean;
  page?: number;
  search?: string;
  data: T[];
  columns: ColumnDef<T, any>[];
  withFilters?: boolean;
  initialPageSize?: number;
  emptyMessage: string;
  debug?: boolean;
  filterOnChange?: (value: string | null) => void;
  onRowClick?: (row: T) => void;
  withPagination?: boolean;
  visibleColumns?: Record<string, boolean>;
  onPageChange?: (page: number) => void;
}) {
  const [columnVisibility, setColumnVisibility] = useState(visibleColumns);

  // Deserialize URL query to filters
  const deserializeColumnFilters = () => {
    const params = new URLSearchParams(window.location.search);
    return Array.from(params.entries())
      .filter(([key]) => key !== "search" && key !== "tab")
      .map(([key, value]) => ({
        id: key,
        value,
      }));
  };

  // Deserialize global filter from URL
  const deserializeGlobalFilter = () => {
    const params = new URLSearchParams(window.location.search);
    return params.get("search") || "";
  };

  const table = useReactTable<T>({
    data,
    columns,
    state: {
      columnVisibility,
      globalFilter: search,
      pagination: {
        pageIndex: page - 1,
        pageSize: initialPageSize,
      },
    },
    initialState: {
      globalFilter: deserializeGlobalFilter(),
      columnFilters: deserializeColumnFilters(),
    },
    ...{
      ...(withFilters && {
        getFilteredRowModel: getFilteredRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
      }),
    },
    getColumnCanGlobalFilter: () => true,
    onColumnVisibilityChange: setColumnVisibility,
    defaultColumn: { maxSize: 300 },
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getCoreRowModel: getCoreRowModel(),
    debugTable: debug,
    debugHeaders: debug,
    debugColumns: debug,
  });

  // const state = table.getState();
  const colSpans = useMemo(() => {
    let accumulator = 0;
    table.getHeaderGroups().flatMap((headerGroup) => {
      headerGroup.headers.map((header) => {
        if (header.subHeaders.length === 0) {
          accumulator += 1;
        }
      });
    });
    return accumulator;
  }, [columns]);

  // Serialize filters to URL
  // useEffect(() => {
  //   const currentColumnFilters = state.columnFilters;
  //   const params = new URLSearchParams(window.location.search);
  //   currentColumnFilters.map(({ id, value }) => {
  //     params.set(id, value as string);
  //   });
  //   window.history.replaceState(null, "", `?${params.toString()}`);
  // }, [state]);

  const pagination = (
    <div className="mt-6">
      <Pagination
        pageSize={table.getState().pagination.pageSize}
        totalCount={table.getState().pagination.pageSize * table.getPageCount()}
        currentPage={table.getState().pagination.pageIndex + 1}
        onPageChange={(page: number) => {
          onPageChange && onPageChange(page);
        }}
      />
    </div>
  );

  return (
    <>
      <div>
        <ScrollArea className="flex">
          <table className=" table-auto w-full rounded-lg overflow-hidden">
            <thead className=" bg-light-beige/50">
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header, index) => (
                    <th
                      className="px-6 py-3"
                      key={header.id + index}
                      colSpan={header.colSpan}
                    >
                      <div className="flex justify-between items-center">
                        <Text wrap="nowrap" size="sm" className="font-semibold">
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                        </Text>
                        {header.column.getCanFilter() &&
                          !loading &&
                          withFilters &&
                          !header.isPlaceholder && (
                            <Filter
                              column={header.column}
                              onChange={filterOnChange}
                              type={
                                header.column.columnDef.meta?.filterType ===
                                "input"
                                  ? "input"
                                  : "select"
                              }
                            />
                          )}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className="bg-white">
              {table.getRowModel().rows.length > 0 ? (
                table.getRowModel().rows.map((row, index) => (
                  <tr
                    key={row.id + index}
                    className="hover:bg-light-gray"
                    onDoubleClick={() => {
                      onRowClick && onRowClick(row.original);
                    }}
                  >
                    {row.getVisibleCells().map((cell, index) => (
                      <td
                        className="px-6 py-4 cursor-pointer"
                        key={cell.id + index}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={colSpans}>
                    <Text className="text-center py-10">{emptyMessage}</Text>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </ScrollArea>
      </div>
      {withPagination && pagination}
    </>
  );
}

export default ReactTable;
