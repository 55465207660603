import { useLocale, useTranslations } from "use-intl";
import {
  AuthPageWrapper,
  Button,
  Error,
  Input,
  Text,
  PasswordInput,
} from "../../common";
import { Formik } from "formik";
import * as Yup from "yup";
import { ApolloError, useMutation } from "@apollo/client";
import {
  googleSignUpMutation,
  signUpMutation,
} from "../../graphql/authQueries";
import { AuthenticationResponse } from "../../types/authentication.response.type";
import { useDispatch } from "react-redux";
import { setUser } from "../../store/user.reducer";
import { useState } from "react";
import { LocalizedLink } from "../../router";
import { Helmet } from "react-helmet-async";
import { TokenResponse, useGoogleLogin } from "@react-oauth/google";
import { ErrorService } from "../../services";
import { apolloClientWithoutErrorLink } from "../../lib/apolloClient";
import Google from "../../assets/google.svg?react";

const SignUpPage = () => {
  const locale = useLocale();
  const t = useTranslations("SignUp");
  const tV = useTranslations("Validation");
  const dispatch = useDispatch();
  const [error, setError] = useState<string>();
  const [googleLoading, setGoogleLoading] = useState(false);

  /**
   * Process google token and get access token and refresh token
   * @param tokenResponse google token response
   */
  const handleGoogleLogin = (tokenResponse: TokenResponse) => {
    apolloClientWithoutErrorLink
      .mutate<
        { signUpWithGoogle: AuthenticationResponse },
        {
          input: {
            accessToken: string;
          };
        }
      >({
        mutation: googleSignUpMutation,
        variables: { input: { accessToken: tokenResponse.access_token } },
      })
      .then((resp) => {
        dispatch(setUser(resp.data?.signUpWithGoogle.user ?? null));
      })
      .catch((_) => {
        setError(t("googleAuthenticationError"));
      })
      .finally(() => {
        setGoogleLoading(false);
      });
  };

  const onGoogleError = (
    error: Pick<TokenResponse, "error" | "error_description" | "error_uri">
  ) => {
    console.error(error);
    ErrorService.showError(t("googleAuthenticationError"));
  };

  const login = useGoogleLogin({
    onSuccess: handleGoogleLogin,
    onError: onGoogleError,
  });

  const [signUp, { loading }] = useMutation<
    { signUp: AuthenticationResponse },
    {
      input: {
        email: string;
        password: string;
      };
    }
  >(signUpMutation);

  return (
    <AuthPageWrapper title={t("createAccount")} subtitle={t("startPreserving")}>
      <Helmet>
        <title>Rompolo - {t("createAccount")}</title>
      </Helmet>
      <div>
        <Button
          loading={googleLoading}
          onClick={() => {
            setGoogleLoading(true);
            login();
          }}
          isBold={false}
          fullWidth
          type="outline"
          icon={<Google className=" w-5 h-5" />}
          title={t("signupWithGoogle")}
        />
      </div>
      <div className="flex items-center justify-center my-6">
        <hr className=" border-very-light-black w-full" />
        <Text color="gray" className="mx-4">
          {t("or")}
        </Text>
        <hr className=" border-very-light-black w-full" />
      </div>
      <Formik
        validateOnChange={false}
        enableReinitialize
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email(tV("invalidEmail"))
            .required(tV("required")),
          fullName: Yup.string().min(2).required(tV("required")),
          password: Yup.string()
            .min(8)
            .required(tV("required"))
            .matches(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9])(?=.{8,})/,
              tV("passwordError")
            ),
        })}
        initialValues={{
          email: "",
          password: "",
          fullName: "",
        }}
        onSubmit={(values) => {
          signUp({ variables: { input: values } })
            .then((resp) => {
              dispatch(setUser(resp.data?.signUp.user ?? null));
            })
            .catch((err) => {
              const apolloError = err as ApolloError;
              const errorCode =
                apolloError.graphQLErrors?.[0]?.extensions?.status;
              if (errorCode === 409) {
                setError(t("conflictEmail"));
              } else {
                setError(apolloError.message);
              }
            });
        }}
      >
        {(props) => {
          return (
            <div>
              {error && <Error message={error} />}
              <Input
                name="fullName"
                onChange={props.handleChange}
                className="mb-3"
                placeholder={t("fullName")}
                error={props.errors.fullName}
              />
              <Input
                name="email"
                onChange={props.handleChange}
                className="mb-3"
                placeholder={t("email")}
                error={props.errors.email}
              />
              <PasswordInput
                name="password"
                onChange={props.handleChange}
                className="mb-3"
                placeholder={t("password")}
                error={props.errors.password}
              />
              <Button
                loading={loading}
                onClick={props.submitForm}
                fullWidth
                title={t("continueWithEmail")}
                small={false}
              />
              <div className="pt-8">
                <Text className=" text-center">
                  {t.rich("agreeWithTerms", {
                    terms: (chunks) => (
                      <a
                        href={`https://rompolo.com/${locale}/terms-conditions`}
                        className="underline"
                      >
                        {chunks}
                      </a>
                    ),
                    policy: (chunks) => (
                      <a
                        href={`https://rompolo.com/${locale}/privacy-policy`}
                        className="underline"
                      >
                        {chunks}
                      </a>
                    ),
                  })}
                </Text>
                <Text className="mt-14 md:mt-16 text-center">
                  {t.rich("alreadyHasAccount", {
                    signin: (chunks) => (
                      <LocalizedLink to={"/signin"} className="underline">
                        {chunks}
                      </LocalizedLink>
                    ),
                  })}
                </Text>
              </div>
            </div>
          );
        }}
      </Formik>
    </AuthPageWrapper>
  );
};

export default SignUpPage;
