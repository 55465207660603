import { useTranslations } from "use-intl";
import { Text } from ".";
import { IconChevronLeft } from "@tabler/icons-react";

const Back = ({ onClick }: { onClick: () => void }) => {
  const t = useTranslations("General");
  return (
    <div
      data-testid="back-button"
      onClick={onClick}
      className=" cursor-pointer flex gap-2"
    >
      <IconChevronLeft size={24} className=" stroke-black" />
      <Text>{t("back")}</Text>
    </div>
  );
};

export default Back;
