import { notifications } from "@mantine/notifications";
import { IconExclamationCircle } from "@tabler/icons-react";

const defaultNotificationConfig = {
  autoClose: 4000,
  withCloseButton: true,
  color: "white",
  className: "text-base mt-4 text-center",
  loading: false,
  radius: "md",
};

class ErrorService {
  showMessage(message: string, timeout: number = 4000) {
    // Most used notification props
    notifications.show({
      ...{ ...defaultNotificationConfig, autoClose: timeout },
      message,
    });
  }
  showError(message: string, timeout: number = 4000) {
    // Most used notification props
    notifications.show({
      icon: <IconExclamationCircle className="stroke-red" />,
      ...defaultNotificationConfig,
      autoClose: timeout,
      message,
    });
  }
}

export default new ErrorService();
