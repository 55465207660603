import { useSelector } from "react-redux";
import { RootState } from "../store";
import { Role, User } from "../types/user.type";

/**
 * Hook to get user data
 *
 * @returns user object and authentication state
 */
const useAuth = () => {
  const user = useSelector((state: RootState) => state.user.user) as User;
  const roles = {
    [Role.ADMIN]: user?.role === Role.ADMIN,
    [Role.USER]: user?.role === Role.USER,
  };
  const isAdmin = roles.ADMIN;
  return { user, roles, isAdmin };
};

export default useAuth;
