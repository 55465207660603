import {
  CSSProperties,
  Textarea as MantineTextArea,
  TextareaProps,
  __InputStylesNames,
} from "@mantine/core";

interface CustomTextareaProps extends TextareaProps {
  colorScheme?: "black" | "white";
  styles?: Partial<Record<__InputStylesNames, CSSProperties>>;
}

const TextArea = ({
  className,
  styles = {},
  colorScheme = "white",
  minRows = 6,
  maxRows = 10,
  ...props
}: CustomTextareaProps) => {
  const dynamicStyles = {
    ...styles,
    input: {
      fontSize: 16,
      borderRadius: 8,
      backgroundColor: colorScheme === "black" ? "#222222" : "white",
      color: colorScheme === "black" ? "white" : "#222222",
      ...styles.input,
    },
    label: {
      fontSize: 16,
      fontWeight: 400,
      marginBottom: 8,
      color: colorScheme === "black" ? "#7A7A7A" : "#626262",
      ...styles.label,
    },
  };

  return (
    <MantineTextArea
      minRows={minRows}
      maxRows={maxRows}
      autosize
      classNames={{
        input: `border-very-light-black focus:border-black`,
      }}
      className={`${className}`}
      styles={dynamicStyles}
      {...props}
    />
  );
};

export default TextArea;
