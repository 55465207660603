import { useLayoutEffect, useRef, useState } from "react";
import { useTranslations } from "use-intl";
import Title from "../Title";
import Text from "../Text";
import { IconChevronRight, IconX } from "@tabler/icons-react";
import { useDispatch, useSelector } from "react-redux";
import { closeHowTo } from "../../store/howto.reducer";
import { RootState } from "../../store";
import Back from "../Back";
import { motion, AnimatePresence } from "framer-motion";
import ResponsiveContainer from "../ResponsiveContainer";

const HowToTemplate = ({ t }: { t: any }) => {
  return (
    <div className="">
      <Title size="H3">{t("customize.title")}</Title>
      <Text className="mt-6">{t("customize.description")}</Text>
      {/* Video of how to */}
      <div className="w-full md:mx-auto relative mt-6 rounded-xl overflow-hidden mb-6">
        <video
          controls
          src="/how-to/customizing-templates.mp4 "
          className="w-full h-full"
        />
      </div>
    </div>
  );
};

const HowToQr = ({ t }: { t: any }) => {
  return (
    <div className="">
      <Title size="H3">{t("replaceQrCode.title")}</Title>
      <ul className="*:ml-6 list-decimal mt-6 space-y-4">
        <li>
          <Text className="">{t("replaceQrCode.one")}</Text>
        </li>
        <li>
          <Text className="">{t("replaceQrCode.two")}</Text>
        </li>
        <li>
          <Text className="">{t("replaceQrCode.three")}</Text>
        </li>
        <li>
          <Text className="">{t("replaceQrCode.four")}</Text>
        </li>
      </ul>
      {/* Video of how to */}
      <div className="w-full md:mx-auto relative mt-6 rounded-xl overflow-hidden">
        <video
          muted
          controls
          src="/how-to/qr-code-on-templates.mp4 "
          className="w-full h-full"
        />
      </div>
    </div>
  );
};

const HowToLink = ({
  children,
  onClick,
}: {
  children: React.ReactNode;
  onClick: () => void;
}) => {
  return (
    <div
      className="p-4 bg-light-gray rounded-xl flex justify-between gap-4 cursor-pointer md:hover:translate-x-2 transition-all"
      onClick={onClick}
    >
      {children}
      <IconChevronRight size={24} className="stroke-medium-black" />
    </div>
  );
};

// HowItWorksBody Component
const HowItWorksBody = ({
  t,
  view,
  setView,
  close,
}: {
  t: any;
  view: string;
  setView: (view: string) => void;
  close: () => void;
}) => {
  const [height, setHeight] = useState("auto");
  const contentRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const header = 50;
    if (contentRef.current) {
      setHeight(`${contentRef.current.scrollHeight + header}px`);
    }
  }, [view]);

  const getViewContent = () => {
    switch (view) {
      case "template":
        return <HowToTemplate t={t} />;
      case "qr":
        return <HowToQr t={t} />;
      default:
        return (
          <div className="h-full">
            <Title size="H3">{t("title")}</Title>
            <Text className="mt-2" color="gray">
              {t("description")}
            </Text>
            <div className="mt-10 flex flex-col gap-4">
              <HowToLink onClick={() => setView("template")}>
                <Text className="font-semibold">{t("customize.title")}</Text>
              </HowToLink>
              <HowToLink onClick={() => setView("qr")}>
                <Text className="font-semibold">
                  {t("replaceQrCode.title")}
                </Text>
              </HowToLink>
            </div>
          </div>
        );
    }
  };

  const motionVariants = {
    initial: (direction: number) => ({
      x: direction > 0 ? "100%" : "-100%",
      opacity: 0,
    }),
    animate: { x: 0, opacity: 1 },
    exit: (direction: number) => ({
      x: direction > 0 ? "-100%" : "100%",
      opacity: 0,
    }),
  };

  return (
    <div style={{ height }} className="overflow-hidden transition-all relative">
      <div className="flex mb-6">
        {view !== "main" && <Back onClick={() => setView("main")} />}
        <div className="flex-1 justify-end flex">
          <IconX
            size={24}
            className="stroke-black cursor-pointer"
            onClick={close}
          />
        </div>
      </div>
      <AnimatePresence initial={false} custom={view !== "main" ? 1 : -1}>
        <motion.div
          ref={contentRef}
          key={view}
          custom={view !== "main" ? 1 : -1}
          variants={motionVariants}
          initial="initial"
          animate="animate"
          className="absolute"
          exit="exit"
          transition={{ duration: 0.3 }}
        >
          {getViewContent()}
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

// Main HowItWorks component
const HowItWorksTemplates = () => {
  const dispatch = useDispatch();
  const howToOpened = useSelector((state: RootState) => state.howto.opened);
  const opened = howToOpened === "templates";
  const t = useTranslations("HowItWorks.Templates");
  const close = () => dispatch(closeHowTo());
  const [view, setView] = useState("main");

  return (
    <ResponsiveContainer opened={opened} close={close}>
      <HowItWorksBody t={t} view={view} setView={setView} close={close} />
    </ResponsiveContainer>
  );
};

export default HowItWorksTemplates;
