import React from "react";
import { TextInput, TextInputProps } from "@mantine/core";

interface CustomInputProps extends TextInputProps {
  colorScheme?: "black" | "white";
}

const Input = React.forwardRef<HTMLInputElement, CustomInputProps>(
  ({ className, styles, colorScheme = "white", ...props }, ref) => {
    const dynamicStyles = {
      input: {
        height: 44,
        fontSize: 16,
        borderRadius: 8,
        backgroundColor: colorScheme === "black" ? "#222222" : "white",
        color: colorScheme === "black" ? "white" : "#222222",
      },
      label: {
        fontSize: 16,
        fontWeight: 400,
        marginBottom: 8,
        color: colorScheme === "black" ? "#7A7A7A" : "#626262",
      },
      ...styles,
    };

    return (
      <TextInput
        ref={ref}
        classNames={{
          input: "border-very-light-black focus:border-black",
        }}
        className={`${className}`}
        styles={dynamicStyles}
        {...props}
      />
    );
  }
);

export default Input;
