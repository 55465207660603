import { Loader } from "@mantine/core";
import { Text } from ".";
import React from "react";

type ButtonType = "outline" | "filled";

type ButtonProps = {
  onClick?: () => void;
  title: string | React.ReactNode;
  type?: ButtonType;
  color?: "black" | "white" | "red" | "green";
  small?: boolean;
  fullWidth?: boolean;
  loading?: boolean;
  className?: string;
  disabled?: boolean;
  icon?: React.ReactNode;
  iconRight?: React.ReactNode;
  minWidth?: string | number;
  withMinWidth?: boolean;
  isBold?: boolean;
};

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      withMinWidth = true,
      onClick,
      title,
      type = "filled",
      color = "black",
      fullWidth = false,
      small = false,
      loading = false,
      className,
      disabled,
      icon,
      iconRight,
      minWidth = 140,
      isBold = true,
      ...props
    },
    ref
  ) => {
    // Define button style classes based on type and color
    const buttonTypeClass =
      type === "filled"
        ? color === "black"
          ? "bg-black hover:bg-very-black"
          : color === "red"
          ? "bg-red hover:bg-red"
          : color === "green"
          ? "bg-green/80 hover:bg-green"
          : "bg-white hover:bg-light-gray"
        : color === "black"
        ? "border border-black bg-transparent hover:bg-light-gray"
        : color === "red"
        ? "border border-red bg-transparent hover:bg-red"
        : color === "green"
        ? "border border-bg-green/80 bg-transparent hover:bg-light-green hover:border-green"
        : "border border-white bg-transparent hover:bg-light-gray/30";

    const buttonSizeClass = small ? "h-[37px] text-sm" : "h-[44px]";
    const buttonWidthClass = fullWidth ? "w-full" : "md:w-auto w-full";
    const textColor =
      type === "filled" || color === "white"
        ? "white"
        : color === "red"
        ? "red"
        : "black";
    const textSizeClass = small ? "sm" : "base";
    const disabledClass = disabled ? "opacity-50 cursor-not-allowed" : "";
    const minWidthStyle = withMinWidth && minWidth ? { minWidth } : {};

    return (
      <button
        ref={ref}
        onClick={onClick}
        type="button"
        style={minWidthStyle}
        className={`block rounded-lg px-4 py-[10px] text-center font-medium ${buttonTypeClass} ${buttonSizeClass} ${buttonWidthClass} ${
          className || ""
        } ${disabledClass}`}
        disabled={disabled || loading}
        {...props}
      >
        {loading ? (
          <Loader color={textColor} size="sm" />
        ) : (
          <Text
            className={`leading-none flex items-center ${
              isBold ? "font-semibold" : "font-normal"
            } justify-center`}
            size={textSizeClass}
            color={textColor}
            wrap="nowrap"
          >
            {icon && <span className="mr-2">{icon}</span>}
            {title}
            {iconRight && <span className="ml-2">{iconRight}</span>}
          </Text>
        )}
      </button>
    );
  }
);

export default Button;
