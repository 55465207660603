import enMessages from "../locales/en.json";

// Assuming the Messages type and messages object are defined as in LocaleLayout
export type Messages = {
  en: typeof enMessages;
  de: typeof enMessages;
  // Add other locales here
};

export const messages: Messages = {
  en: enMessages,
  de: enMessages,
  // Add additional locales and their message bundles here
};

export const DEFAULT_LOCALE: keyof Messages = "en";
export const LOCALES: (keyof Messages)[] = Object.keys(
  messages
) as (keyof Messages)[];
