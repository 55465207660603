import { useTranslations } from "use-intl";
import { Button, Text, Title } from "../../../common";
import { BadgeProduct } from "../../../config/pricing.config";

const Badge = ({
  currency = "$",
  purchase,
  loading,
}: {
  currency?: "$" | "€";
  purchase: (products: string[]) => void;
  loading: boolean;
}) => {
  const t = useTranslations("Pricing");
  const handlePurchase = () => {
    BadgeProduct?.priceId && purchase([BadgeProduct?.priceId]);
  };

  return (
    <div className="flex gap-6 mt-14 md:flex-row flex-col">
      <div className="flex-1 bg-light-blue md:rounded-lg">
        <div className="flex flex-col xl:flex-row xl:gap-0 gap-6 items-center justify-center md:py-20 py-6 md:px-10 px-4 md:pr-0">
          <div className=" *:bg-medium-blue *:py-4 *:md:px-10 *:rounded-lg flex flex-col gap-2 w-full md:w-auto *:md:text-left *:text-center">
            <div>
              <span>{t("madeInEu")}</span>
            </div>
            <div>
              <Text>{t("waterproof")}</Text>
            </div>
            <div>
              <Text>{t("stainlessSteel")}</Text>
            </div>
            <div>
              <Text>{t("laserEngraved")}</Text>
            </div>
          </div>
          <div className="flex-1 flex justify-center items-center">
            <img
              src="/badge.webp"
              alt={t("perfectForGravestones")}
              className="h-[180px] w-[180px] rounded-lg"
            />
          </div>
        </div>
      </div>
      <div className="flex-1 bg-white md:rounded-lg">
        <div className="py-10 px-7 flex gap-8 md:flex-row flex-col">
          <div>
            <Title size="H5">{t("qrBadge")}</Title>
            <Title size="H2">{currency}45.99</Title>
            <Text size="sm" className="mt-2 font-semibold">
              {t("freeShipping")}
            </Text>
            <Text color="gray" className="py-4" size="sm">
              {t("qrBadgeDescription")}
            </Text>
            <Button
              loading={loading}
              onClick={handlePurchase}
              type="outline"
              title={t("orderNow")}
            />
          </div>
          <div>
            <ul className="list-image-checkmark space-y-6 ml-6">
              <li>{t("durable")}</li>
              <li>{t("includesStickySide")}</li>
              <li>{t("qrCodeConnects")}</li>
              <li>{t("madeWithHighQuality")}</li>
              <li>{t("perfectForGravestones")}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Badge;
