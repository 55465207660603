import { useTranslations } from "use-intl";
import { useFormikContext } from "formik";
import { InfoToolitp, SettingsBlock, Text, Toggle } from "../";
import { FileButton, NumberInput, RingProgress } from "@mantine/core";
import { Album, CreateAlbumInput } from "../../types/album.type";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { getImageUrl } from "../../lib/utils";
import { useMutation } from "@apollo/client";
import { removeLogoMutation } from "../../graphql/albumQueries";
import { IconTrash, IconUpload } from "@tabler/icons-react";
import { ErrorService } from "../../services";
import { setAlbum } from "../../store/album.reducer";
import axiosClient from "../../lib/axiosClient";

const SlideShowSettings = ({
  handleFieldChange,
  album,
}: {
  album?: Album;
  handleFieldChange: (e: React.ChangeEvent<any> | string, value?: any) => void;
}) => {
  const t = useTranslations();
  const formik = useFormikContext<CreateAlbumInput>();
  const [uploadProgress, setUploadProgress] = useState(0);
  const [file, setFile] = useState<File | null>(null);
  const dispatch = useDispatch();
  const uploadButtonRef = useRef<HTMLDivElement>(null);

  const [removeLogo] = useMutation<{ removeLogo: boolean }, { id: string }>(
    removeLogoMutation
  );

  useEffect(() => {
    const handleUpload = async () => {
      if (!file || !album) return;

      const formData = new FormData();
      try {
        formData.append("files", file, file.name);
      } catch (error) {
        console.error("Error processing file:", file.name, error);
      }
      try {
        const response = await axiosClient.post<{
          logo: string;
        }>(`/image/album-logo/${album?.id}`, formData, {
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / (progressEvent?.total ?? 0)
            );
            setUploadProgress(percentCompleted);
          },
        });

        dispatch(
          setAlbum({
            ...album,
            logo: response.data.logo,
          })
        );
      } catch (error) {
        ErrorService.showError(t("Files.errorUploading"));
      }
    };

    if (file) {
      handleUpload();
    }
  }, [file]);

  // Reset upload progress when it reaches 100
  useEffect(() => {
    if (uploadProgress === 100) {
      setUploadProgress(0);
    }
  }, [uploadProgress]);

  const isFree = album?.plan === "free";

  return (
    <SettingsBlock
      premium={isFree}
      description={t("AlbumSettings.slideShowSettingsDescription")}
    >
      {album && (
        <div>
          <Text className="mb-2" color="gray">
            {t("AlbumSettings.logo")}
          </Text>
          <div className="flex items-center gap-4">
            <FileButton
              onChange={setFile}
              accept="image/png,image/webp,image/jpeg"
              disabled={isFree}
            >
              {(props) => (
                <div
                  ref={uploadButtonRef}
                  {...props}
                  className={`rounded-lg border-dashed cursor-pointer ${
                    !album.logo && "border"
                  } border-light-black w-16 h-16 bg-light-gray flex items-center justify-center relative`}
                >
                  {album?.logo && uploadProgress === 0 ? (
                    <img
                      src={getImageUrl(album.logo)}
                      alt={album.name}
                      className="w-full h-full object-cover rounded-lg absolute"
                    />
                  ) : uploadProgress > 0 ? (
                    <RingProgress
                      size={60}
                      sections={[{ value: uploadProgress, color: "#222222" }]}
                    />
                  ) : (
                    <IconUpload
                      size={16}
                      className="stroke-black cursor-pointer"
                    />
                  )}
                </div>
              )}
            </FileButton>
            {album.logo ? (
              <div className="flex gap-4">
                <Text
                  className=" cursor-pointer font-medium flex gap-1 items-center "
                  onClick={() => {
                    uploadButtonRef.current?.click();
                  }}
                >
                  <IconUpload
                    size={16}
                    className="stroke-black cursor-pointer"
                  />
                  {t("AlbumSettings.replace")}
                </Text>
                <Text
                  color="red"
                  className=" cursor-pointer font-medium flex gap-1 items-center"
                  onClick={() => {
                    album &&
                      removeLogo({ variables: { id: album.id } })
                        .then((resp) => {
                          if (resp.data?.removeLogo) {
                            dispatch(
                              setAlbum({
                                ...album,
                                logo: undefined,
                              })
                            );
                          } else {
                            ErrorService.showError(
                              t("AlbumSettings.errorRemovingLogo")
                            );
                          }
                        })
                        .catch((_) => {
                          ErrorService.showError(
                            t("AlbumSettings.errorRemovingLogo")
                          );
                        });
                  }}
                >
                  <IconTrash size={16} className="stroke-red cursor-pointer" />
                  {t("AlbumSettings.remove")}
                </Text>
              </div>
            ) : (
              <Text className="mb-4" color="gray">
                <span>
                  {t("AlbumSettings.logoDescription")}
                  <span className=" whitespace-nowrap">
                    <InfoToolitp text={t("AlbumSettings.logoTooltip")} />
                  </span>
                </span>
              </Text>
            )}
          </div>
        </div>
      )}
      <div className="flex gap-6">
        <div>
          <Text className="mb-4" color="gray">
            {t("AlbumSettings.videoDuration")}
          </Text>
          <NumberInput
            disabled={isFree}
            className="w-[120px]"
            min={3}
            value={formik.values.videoDuration}
            onChange={(val) => handleFieldChange("videoDuration", val)}
            suffix={t("AlbumSettings.seconds")}
            name="videoDuration"
          />
        </div>
        <div>
          <Text className="mb-4" color="gray">
            {t("AlbumSettings.imageDuration")}
          </Text>
          <NumberInput
            disabled={isFree}
            className="w-[120px]"
            min={3}
            value={formik.values.imageDuration}
            onChange={(val) => handleFieldChange("imageDuration", val)}
            suffix={t("AlbumSettings.seconds")}
            name="imageDuration"
          />
        </div>
      </div>

      <div>
        <Text className="mb-4" color="gray">
          {t("AlbumSettings.wallPreferences")}
        </Text>
        <div className="flex flex-col gap-4">
          <Toggle
            disabled={isFree}
            fontSize={16}
            label={t("AlbumSettings.wallShowCaptions")}
            checked={formik.values.wallDisplayCaptions ?? true}
            onChange={(value) =>
              handleFieldChange("wallDisplayCaptions", value)
            }
          />
          <Toggle
            disabled={isFree}
            fontSize={16}
            label={t("AlbumSettings.wallShowCode")}
            checked={formik.values.wallDisplayCode ?? true}
            onChange={(value) => handleFieldChange("wallDisplayCode", value)}
          />
        </div>
      </div>
    </SettingsBlock>
  );
};

export default SlideShowSettings;
