import { useTranslations } from "use-intl";
import { useFormikContext } from "formik";
import { FileButton, Radio, RingProgress } from "@mantine/core";
import { Album, CreateAlbumInput, Layout } from "../../types/album.type";
import { getImageUrl } from "../../lib/utils";
import { AlbumColorPicker, Premium, SettingsBlock, Text, Toggle } from "..";
import { EventType } from "../../types/event.enum";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useMutation } from "@apollo/client";
import { removeProfileImageMutation } from "../../graphql/albumQueries";
import { IconTrash, IconUpload } from "@tabler/icons-react";
import { ErrorService } from "../../services";
import { setAlbum } from "../../store/album.reducer";
import axiosClient from "../../lib/axiosClient";
import SingleColumnStylePreview from "./StylePreviews/SingleColumnStylePreview";
import CardStylePreview from "./StylePreviews/CardStylePreview";
import CircleStylePreview from "./StylePreviews/CircleStylePreview";
import DoubleColumnStylePreview from "./StylePreviews/DoubleColumnStylePreview";
import { useIsSmallScreen } from "../../hooks";

const StyleSettings = ({
  album,
  handleFieldChange,
}: {
  album?: Album;
  handleFieldChange: (e: React.ChangeEvent<any> | string, value?: any) => void;
}) => {
  const t = useTranslations();
  const formik = useFormikContext<CreateAlbumInput>();
  const [file, setFile] = useState<File | null>(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const dispatch = useDispatch();
  const uploadButtonRef = useRef<HTMLDivElement>(null);
  const thumbnailUrl =
    album?.profileThumbnail && getImageUrl(album?.profileThumbnail);
  const isSmallScreen = useIsSmallScreen();

  const [removeProfileImage] = useMutation<
    { removeProfileImage: boolean },
    { id: string }
  >(removeProfileImageMutation);

  useEffect(() => {
    const handleUpload = async () => {
      if (!file || !album) return;

      const formData = new FormData();
      try {
        formData.append("files", file, file.name);
      } catch (error) {
        console.error("Error processing file:", file.name, error);
      }
      try {
        const response = await axiosClient.post<{
          profileImage: string;
          profileThumbnail: string;
        }>(`/image/album-profile/${album?.id}`, formData, {
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / (progressEvent?.total ?? 0)
            );
            setUploadProgress(percentCompleted);
          },
        });

        dispatch(
          setAlbum({
            ...album,
            profileImage: response.data.profileImage,
            profileThumbnail: response.data.profileThumbnail,
          })
        );
      } catch (error) {
        ErrorService.showError(t("Files.errorUploading"));
      }
    };

    if (file) {
      handleUpload();
    }
  }, [file]);

  // Reset upload progress when it reaches 100
  useEffect(() => {
    if (uploadProgress === 100) {
      setUploadProgress(0);
    }
  }, [uploadProgress]);

  const isFree = album?.plan === "free";

  return (
    <SettingsBlock description={t("AlbumSettings.styleDescription")}>
      {album && (
        <div>
          <Text className="mb-2" color="gray">
            {formik.values.type === EventType.MEMORIAL
              ? t("AlbumSettings.profileImage")
              : t("AlbumSettings.coverImage")}
          </Text>
          <div className="flex items-center gap-4">
            <FileButton
              onChange={setFile}
              accept="image/png,image/webp,image/jpeg"
            >
              {(props) => (
                <div
                  ref={uploadButtonRef}
                  {...props}
                  className={`rounded-lg border-dashed cursor-pointer ${
                    !album.profileImage && "border"
                  } border-light-black w-16 h-16 bg-light-gray flex items-center justify-center relative`}
                >
                  {album?.profileThumbnail && uploadProgress === 0 ? (
                    <img
                      src={thumbnailUrl}
                      alt={album.name}
                      className="w-full h-full object-cover rounded-lg absolute"
                    />
                  ) : uploadProgress > 0 ? (
                    <RingProgress
                      size={60}
                      sections={[{ value: uploadProgress, color: "#222222" }]}
                    />
                  ) : (
                    <IconUpload
                      size={16}
                      className="stroke-black cursor-pointer"
                    />
                  )}
                </div>
              )}
            </FileButton>
            {album.profileImage ? (
              <div className="flex gap-4">
                <Text
                  className=" cursor-pointer font-medium flex gap-1 items-center "
                  onClick={() => {
                    uploadButtonRef.current?.click();
                  }}
                >
                  <IconUpload
                    size={16}
                    className="stroke-black cursor-pointer"
                  />
                  {t("AlbumSettings.replace")}
                </Text>
                <Text
                  color="red"
                  className=" cursor-pointer font-medium flex gap-1 items-center"
                  onClick={() => {
                    album &&
                      removeProfileImage({ variables: { id: album.id } })
                        .then((resp) => {
                          if (resp.data?.removeProfileImage) {
                            dispatch(
                              setAlbum({
                                ...album,
                                profileImage: undefined,
                                profileThumbnail: undefined,
                              })
                            );
                          } else {
                            ErrorService.showError(
                              t("AlbumSettings.errorRemovingProfileImage")
                            );
                          }
                        })
                        .catch((_) => {
                          ErrorService.showError(
                            t("AlbumSettings.errorRemovingProfileImage")
                          );
                        });
                  }}
                >
                  <IconTrash size={16} className="stroke-red cursor-pointer" />
                  {t("AlbumSettings.remove")}
                </Text>
              </div>
            ) : (
              <Text color="gray">
                {formik.values.type === EventType.MEMORIAL
                  ? t("AlbumSettings.profileImageDescription")
                  : t("AlbumSettings.bannerImageDescription")}
              </Text>
            )}
          </div>
        </div>
      )}
      <div>
        <Text className="mb-4" color="gray">
          {t("AlbumSettings.backgroundColor")}
        </Text>
        <AlbumColorPicker
          value={formik.values.color}
          name="color"
          handleChange={handleFieldChange}
        />
      </div>
      {formik.values.type !== EventType.MEMORIAL && (
        <>
          <Radio.Group
            value={formik.values.layout}
            onChange={(val) => handleFieldChange("layout", val)}
            name="layout"
          >
            <Text className="mb-4" color="gray">
              {t("AlbumSettings.coverImagePreferences")}
            </Text>
            <div className="flex gap-4 lg:flex-row flex-col mb-6">
              <div className="flex flex-col flex-1">
                <CircleStylePreview
                  album={album}
                  formik={formik}
                  thumbnailUrl={thumbnailUrl}
                />
                <Radio
                  variant="outline"
                  color="#222222"
                  styles={{ label: { fontSize: 16 } }}
                  value={Layout.CIRCLE}
                  label={
                    isSmallScreen
                      ? t("AlbumSettings.circleHalfMobile")
                      : t("AlbumSettings.circleHalf")
                  }
                />
              </div>
              <div className="flex flex-col flex-1">
                <CardStylePreview
                  album={album}
                  formik={formik}
                  thumbnailUrl={thumbnailUrl}
                />
                <Radio
                  variant="outline"
                  color="#222222"
                  styles={{ label: { fontSize: 16 } }}
                  value={Layout.CARD}
                  label={
                    isSmallScreen
                      ? t("AlbumSettings.cardHalfMobile")
                      : t("AlbumSettings.cardHalf")
                  }
                />
              </div>
            </div>
            <div className="flex gap-4 lg:flex-row flex-col">
              <div className="flex flex-col flex-1">
                <SingleColumnStylePreview
                  album={album}
                  formik={formik}
                  thumbnailUrl={thumbnailUrl}
                />
                <Radio
                  variant="outline"
                  color="#222222"
                  styles={{ label: { fontSize: 16 } }}
                  value={Layout.SINGLE}
                  label={t("AlbumSettings.coverFullPage")}
                />
              </div>
              <div className="flex flex-col flex-1">
                <DoubleColumnStylePreview
                  album={album}
                  formik={formik}
                  thumbnailUrl={thumbnailUrl}
                />
                <Radio
                  variant="outline"
                  color="#222222"
                  styles={{ label: { fontSize: 16 } }}
                  value={Layout.DOUBLE}
                  label={
                    isSmallScreen
                      ? t("AlbumSettings.coverHalfPageMobile")
                      : t("AlbumSettings.coverHalfPage")
                  }
                />
              </div>
            </div>
          </Radio.Group>

          {formik.values.layout === Layout.DOUBLE && (
            <div>
              <Text className="mb-4" color="gray">
                {t("AlbumSettings.bannerBackgroundColor")}
              </Text>
              <AlbumColorPicker
                value={formik.values.bannerColor}
                name="bannerColor"
                handleChange={handleFieldChange}
              />
            </div>
          )}
        </>
      )}
      <div>
        <Text className="mb-4" color="gray">
          {t("AlbumSettings.captionPreferences")}
        </Text>
        <div className="flex flex-col gap-4">
          <Toggle
            fontSize={16}
            label={t("AlbumSettings.displayCaption")}
            checked={formik.values.displayCaption ?? true}
            onChange={(value) => handleFieldChange("displayCaption", value)}
          />
          <Toggle
            fontSize={16}
            label={t("AlbumSettings.displayContributor")}
            checked={formik.values.displayContributor ?? true}
            onChange={(value) => handleFieldChange("displayContributor", value)}
          />
        </div>
      </div>
      <div>
        <div className="mb-4 flex gap-2 items-center">
          <Text className="" color="gray">
            {t("AlbumSettings.headerPreferences")}
          </Text>
          {isFree && <Premium gray />}
        </div>

        <div className="flex flex-col gap-4">
          <Toggle
            tooltip={t("AlbumSettings.displayMoreActionsTooltip")}
            disabled={isFree}
            fontSize={16}
            label={t("AlbumSettings.displayMoreActions")}
            checked={formik.values.displayMoreActionsButton ?? true}
            onChange={(value) =>
              handleFieldChange("displayMoreActionsButton", value)
            }
          />
          <Toggle
            tooltip={t("AlbumSettings.displayPhotoWallTooltip")}
            disabled={isFree}
            fontSize={16}
            label={t("AlbumSettings.displayPhotoWall")}
            checked={formik.values.displayPhotowallButton ?? true}
            onChange={(value) =>
              handleFieldChange("displayPhotowallButton", value)
            }
          />
          <Toggle
            disabled={isFree}
            fontSize={16}
            label={t("AlbumSettings.removeBranding")}
            checked={formik.values.removeBranding ?? false}
            onChange={(value) => handleFieldChange("removeBranding", value)}
          />
        </div>
      </div>
    </SettingsBlock>
  );
};

export default StyleSettings;
