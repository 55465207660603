import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { Album } from "../types/album.type";
import { useQuery } from "@apollo/client";
import { PaginatedType } from "../types/paginated.type";
import { userAlbumsQuery } from "../graphql/albumQueries";
import { useEffect, useMemo, useState } from "react";
import { ErrorService } from "../services";
import { useTranslations } from "use-intl";
import { Plan } from "../types/plan.enum";
import { ScrollArea, Skeleton, Tooltip } from "@mantine/core";
import Text from "./Text";
import { IconChevronDown, IconX } from "@tabler/icons-react";
import { useIsSmallScreen } from "../hooks";
import Title from "./Title";
import moment from "moment";
import Button from "./Button";
import {
  resetAlbumsRefreshNeeded,
  setAlbum,
  setAlbums,
} from "../store/album.reducer";
import CreateAlbum from "./CreateAlbum";
import Back from "./Back";
import ResponsiveContainer from "./ResponsiveContainer";
import { showConfetti } from "../store/confetti.reducer";
import { useLocation } from "react-router-dom";

// Function to check if a date is expired
function isExpired(expirationDate: Date) {
  const currentDate = moment(); // Current date/time
  const expiration = moment(expirationDate); // Format according to your input date format
  return expiration.isBefore(currentDate);
}

const AlbumSelectWithModal = () => {
  const t = useTranslations("Album");
  const tEmoji = useTranslations("Emoji");
  const [opened, setOpened] = useState(false);
  const [createAlbum, setCreatedAlbum] = useState(false);
  const album = useSelector((state: RootState) => state.album.album) as Album;
  const refreshOfAlbumsNeeded = useSelector(
    (state: RootState) => state.album.refreshNeeded
  );
  const location = useLocation();
  const albums =
    useSelector((state: RootState) => state.album.albums) ?? ([] as Album[]);
  const dispatch = useDispatch();
  const isSmallScreen = useIsSmallScreen();

  const { loading, error, data, refetch } = useQuery<
    { userAlbums: PaginatedType<Album> },
    { first?: number; after?: string }
  >(userAlbumsQuery, {
    variables: {
      first: 9999999, // Fetching a large number of albums; consider pagination.
    },
    fetchPolicy: "no-cache",
  });

  const freeAlbumCount = useMemo(
    () =>
      data?.userAlbums.nodes.filter((a) => a.plan === Plan.free).length ?? 0,
    [data]
  );

  const maximalFreeCountReched = freeAlbumCount < 2 ? false : true;

  const onAlbumSelect = (album: Album) => {
    setOpened(false);
    dispatch(setAlbum(album));
  };

  const onCreateAlbumClick = () => {
    if (freeAlbumCount < 2) {
      setCreatedAlbum(true);
    }
  };

  useEffect(() => {
    if (refreshOfAlbumsNeeded) {
      refetch();
      dispatch(resetAlbumsRefreshNeeded());
    }
  }, [refreshOfAlbumsNeeded]);

  useEffect(() => {
    if (data) {
      dispatch(setAlbums(data.userAlbums.nodes));
    }
  }, [data]);

  // Albums list updated each time, when we fetch from server
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const passedAlbumId = params.get("albumId");

    // If albumId is passed, select that album, else continue with defaul process
    if (passedAlbumId) {
      const selectedAlbum = albums.find((a) => a.id === passedAlbumId);
      if (selectedAlbum) {
        dispatch(setAlbum(selectedAlbum));
        return;
      }
    }

    if (album) {
      const selectedAlbumInServerList = albums.find((a) => a.id === album.id);
      if (!selectedAlbumInServerList) {
        //Use case is when album is deleted, we refetch list, and select first album
        dispatch(setAlbum(albums[0]));
      } else {
        //Use case when we want to refresh album with data from server, and it is selected
        dispatch(setAlbum(selectedAlbumInServerList));
      }
    } else {
      //Solving when no album is selected
      dispatch(setAlbum(albums[0]));
    }
  }, [albums]);

  useEffect(() => {
    if (error) {
      ErrorService.showError(t("errorFetching"));
    }
  }, [error]);

  const sortedAlbums = useMemo(() => {
    return [...albums].sort((a, b) => {
      if (a.id === album?.id) {
        return -1;
      }
      if (b.id === album?.id) {
        return 1;
      }
      return 0;
    });
  }, [albums, album]);

  const albumItem = (a: Album) => {
    const expirationText =
      a?.expirationDate && isExpired(a.expirationDate)
        ? `${t("expiredOn")} ${moment(a.expirationDate).format("MMM DD, YYYY")}`
        : a.expirationDate
        ? `${t("expirationDate")} ${moment(a.expirationDate).format(
            "MMM DD, YYYY"
          )} (${moment(a.expirationDate).diff(moment(), "days")} ${t(
            "remaining"
          )})`
        : t("notStarted");

    return (
      <div
        onClick={() => onAlbumSelect(a)}
        key={a.id}
        className="pb-4 border-b border-b-black/10 flex w-full gap-4 justify-between cursor-pointer"
      >
        <div className="flex gap-2 items-center flex-1">
          <div className="w-8 h-8 bg-light-beige rounded-lg flex items-center justify-center">
            <Text size="xs">{tEmoji(a.type)}</Text>
          </div>
          <div className="flex-1">
            <Text className="font-semibold flex justify-between items-end w-full">
              {a.name}
              {isSmallScreen && album.id === a.id && (
                <span className="font-semibold text-medium-black text-sm">
                  {t("selected")}
                </span>
              )}
            </Text>
            <Text size="xs" color="gray">
              {expirationText}
            </Text>
          </div>
        </div>
        <div className="md:block hidden">
          {album?.id === a.id ? (
            <Text className="font-semibold" color="gray">
              {t("selected")}
            </Text>
          ) : (
            <Button
              type="outline"
              onClick={() => onAlbumSelect(a)}
              title={t("choose")}
              withMinWidth={false}
            />
          )}
        </div>
      </div>
    );
  };

  const body = (
    <div
      className={`transition-all duration-300 overflow-hidden ${
        createAlbum ? "md:h-[635px]" : "md:h-[520px]"
      }`}
    >
      <div className="flex mb-6">
        {createAlbum && <Back onClick={() => setCreatedAlbum(false)} />}
        <div className="flex-1 justify-end flex">
          <IconX
            size={24}
            className="stroke-black cursor-pointer"
            onClick={() =>
              createAlbum ? setCreatedAlbum(false) : setOpened(false)
            }
          />
        </div>
      </div>
      {createAlbum ? (
        <CreateAlbum
          onCreated={async (a) => {
            setOpened(false);
            dispatch(setAlbum(a));
            refetch();
            dispatch(showConfetti(true));
          }}
          closeForm={() => setCreatedAlbum(false)}
        />
      ) : (
        <>
          <div>
            <div className="flex gap-2 items-center">
              <Title size="H3">{t("yourAlbums")}</Title>
              <Title className=" text-medium-black" size="H4">
                ({albums.length})
              </Title>
            </div>
            <Text color="gray" className="mt-2">
              {t("yourAlbumsDescription")}
            </Text>
          </div>
          <ScrollArea offsetScrollbars className="mt-10 h-[275px]">
            <div className="flex flex-col gap-4 transition-all">
              {album && sortedAlbums.map(albumItem)}
            </div>
          </ScrollArea>
          <div className="mt-10">
            <Tooltip
              w={250}
              multiline
              disabled={!maximalFreeCountReched}
              label={t("tooManyFreeAlbumsDescription")}
            >
              <Button
                disabled={maximalFreeCountReched}
                type="outline"
                onClick={onCreateAlbumClick}
                title={t("createNewAlbum")}
                fullWidth={true}
              />
            </Tooltip>
          </div>
        </>
      )}
    </div>
  );

  if (loading || !album) return <Skeleton height={32} width={200} />;

  return (
    <>
      <ResponsiveContainer opened={opened} close={() => setOpened(false)}>
        {body}
      </ResponsiveContainer>
      <div
        id="album-select"
        onClick={() => setOpened(true)}
        className="flex gap-2 items-center cursor-pointer"
      >
        <div className="w-8 h-8 bg-light-beige rounded-lg flex items-center justify-center">
          <Text size="xs">{tEmoji(album?.type)}</Text>
        </div>
        <div className="">
          <p className="font-semibold text-ellipsis overflow-hidden max-w-[200px] whitespace-nowrap md:text-base text-sm">
            {album?.name}
          </p>
        </div>
        <div>
          <IconChevronDown size={16} className="stroke-black" />
        </div>
      </div>
    </>
  );
};

export default AlbumSelectWithModal;
