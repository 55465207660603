export const COLORS: { [key: string]: string } = {
  white: "#ffffff",
  "light-gray": "#F7F7F7",
  "light-green": "#e0ece0",
  "medium-green": "#C5DEC7",
  "medium-purple": "#D4D1E8",
  "light-blue": "#e4edf9",
  "light-purple": "#e7e4f9",
  "light-beige": "#ece9e0",
  "light-orange": "#F7E6C6",
  black: "#222222",
};

const AlbumColorPicker = ({
  className,
  handleChange,
  withBlack,
  name,
  value,
  ...props
}: {
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  value?: string;
  withBlack?: boolean;
} & React.HTMLAttributes<HTMLDivElement>) => {
  const colorsToShow = { ...COLORS };
  if (!withBlack) {
    delete colorsToShow.black;
  }
  return (
    <div className={`flex flex-wrap gap-3 ${className || ""}`} {...props}>
      {Object.values(colorsToShow).map((c, index) => {
        return (
          <div key={c}>
            <input
              value={c}
              type="radio"
              checked={
                (!value && index === 0) ||
                value?.toUpperCase() === c.toUpperCase()
              }
              className="peer/radio hidden"
              name={name}
              radioGroup={name}
              id={`${c}-${name}`}
              onChange={handleChange}
            />
            <label
              htmlFor={`${c}-${name}`}
              style={{ backgroundColor: c }}
              className={`md:w-8 w-10 inline-block md:h-8 h-10 rounded-[4px] border-light-black/10 border peer-checked/radio:border-black cursor-pointer`}
            ></label>
          </div>
        );
      })}
    </div>
  );
};

export default AlbumColorPicker;
