import {
  IconArchive,
  IconCheck,
  IconPhotoUp,
  IconTrash,
} from "@tabler/icons-react";
import { Image } from "../../types/image.type";
import { ErrorService } from "../../services";
import { useTranslations } from "use-intl";
import { useDisclosure } from "@mantine/hooks";
import { Modal } from "@mantine/core";
import { Button, Text } from "..";
import { ActionIcons } from ".";
import useImageActions from "../../hooks/useImageActions";
import { useAuth } from "../../hooks";
import { Album } from "../../types/album.type";
import { useState } from "react";

const ImageActions = ({
  image,
  onRemoved,
  onUpdated,
  hover = true,
  large = false,
  album,
}: {
  image: Image;
  large?: boolean;
  onUpdated: (image: Image) => void;
  onRemoved: (id: string) => void;
  hover?: boolean;
  album: Album;
}) => {
  const { user } = useAuth();
  const [removing, setRemoving] = useState(false);
  const [opened, { open, close }] = useDisclosure(false);
  const t = useTranslations("Photos");
  const { remove, unPublish, publish, approve } = useImageActions(album?.id);

  const handleRemove = async () => {
    setRemoving(true);
    try {
      const resp = await remove(image.id);
      if (resp) {
        onRemoved(image.id);
        close();
      }
    } catch (error) {
      ErrorService.showError(t("errorRemoval"));
    } finally {
      setRemoving(false);
    }
  };

  const handleUnPublish = async () => {
    try {
      const resp = await unPublish(image.id);
      if (resp) {
        onUpdated(resp);
        close();
        ErrorService.showMessage(t("imageUnpublished"));
      }
    } catch (error) {
      ErrorService.showError(t("errorUnpublishing"));
    }
  };

  const handlePublish = async () => {
    try {
      const resp = await publish(image.id);
      if (resp) {
        onUpdated(resp);
        ErrorService.showMessage(t("imagePublished"));
      }
    } catch (error) {
      ErrorService.showError(t("errorPublishing"));
    }
  };

  const handleApprove = async () => {
    try {
      const resp = await approve(image.id);
      if (resp) {
        onUpdated(resp);
        ErrorService.showMessage(t("imageApproved"));
      }
    } catch (error) {
      ErrorService.showError(t("errorApproving"));
    }
  };

  const onRemoveClick = () => {
    open();
  };

  const containerClass = hover
    ? "absolute top-4 right-4 flex-col"
    : "relative flex-row";
  const iconSize = large ? 24 : 16;
  const isUserOrAdmin = user?.role === "ADMIN" || user?.id === album?.user.id;

  const actionIcons = [
    // Other icons are conditionally included
    ...(isUserOrAdmin
      ? [
          ...(!image.approved
            ? [
                {
                  icon: IconCheck,
                  onClick: handleApprove,
                  className: "stroke-black bg-white approve",
                  width: iconSize,
                  height: iconSize,
                  tooltip: t("approve"),
                },
              ]
            : []),
          ...(image.published
            ? [
                {
                  icon: IconArchive,
                  onClick: handleUnPublish,
                  className: "stroke-black bg-white unpublish",
                  width: iconSize,
                  height: iconSize,
                  tooltip: t("unpublish"),
                },
              ]
            : []),
          ...(!image.published
            ? [
                {
                  icon: IconPhotoUp,
                  onClick: handlePublish,
                  className: "stroke-green bg-white publish",
                  width: iconSize,
                  height: iconSize,
                  tooltip: t("publish"),
                },
              ]
            : []),
        ]
      : []),
    ...(isUserOrAdmin ||
    image?.user?.id === user.id ||
    image?.userId === user.id || //This is being returned from REST request as we are not joining the user table
    image?.guestUserId === user.id
      ? [
          {
            icon: IconTrash,
            onClick: onRemoveClick,
            className: "stroke-red bg-white remove",
            width: iconSize,
            height: iconSize,
            tooltip: t("remove"),
          },
        ]
      : []),
  ];
  const isNotAlbumCreator = user?.id !== album?.user.id;

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      className={`${containerClass} flex gap-2`}
    >
      <Modal
        centered
        radius={8}
        withCloseButton={false}
        opened={opened}
        onClose={close}
      >
        <Text color="gray">
          {isNotAlbumCreator ? t("sureToDelete") : t("deleteOrUnpublish")}
        </Text>
        <div className="flex items-end justify-end gap-2 mt-6">
          {!isNotAlbumCreator ? (
            <Button
              small
              onClick={handleUnPublish}
              type="outline"
              title={t("unpublish")}
            />
          ) : (
            <Button
              small
              onClick={() => close()}
              type="outline"
              title={t("cancel")}
            />
          )}
          <Button
            small
            onClick={handleRemove}
            color="red"
            loading={removing}
            title={t("remove")}
          />
        </div>
      </Modal>
      <ActionIcons
        icons={actionIcons.map((icon) => ({
          ...icon,
          width: iconSize,
          height: iconSize,
        }))}
      />
    </div>
  );
};

export default ImageActions;
