import React, { ReactNode } from "react";
import { Modal } from "@mantine/core";
import { useIsSmallScreen, useLockBodyScroll } from "../hooks";
import BottomToolbar from "./BottomToolbar";
import CustomScrollArea from "./CustomScrollArea";

interface ResponsiveContainerProps {
  children: ReactNode;
  opened: boolean;
  close: () => void;
}

const ResponsiveContainer: React.FC<ResponsiveContainerProps> = ({
  children,
  opened,
  close,
}) => {
  const isSmallScreen = useIsSmallScreen();
  useLockBodyScroll(opened);

  return (
    <>
      {isSmallScreen ? (
        <BottomToolbar opened={opened} onClose={close}>
          {children}
        </BottomToolbar>
      ) : (
        <Modal
          padding={0}
          radius={8}
          size="lg" // Adjust size as needed, example uses 'lg' which corresponds to 600px typically
          onClose={close}
          centered
          opened={opened}
          withCloseButton={false}
        >
          <CustomScrollArea
            classNames={{ viewport: "p-10" }}
            styles={{
              viewport: {
                maxHeight: "calc(100vh - 10vh)", // Adjusted for clarity
              },
            }}
          >
            {children}
          </CustomScrollArea>
        </Modal>
      )}
    </>
  );
};

export default ResponsiveContainer;
