import { useTranslations } from "use-intl";
import { InfoToolitp, RoundedBox, Text, Title } from "../../../common";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { LocalizedLink } from "../../../router";
import { useQuery } from "@apollo/client";
import { Badge } from "../../../types/badge.type";
import { findBadgeByAlbumIdQuery } from "../../../graphql/badgeQueries";

const AlbumStatus = () => {
  const album = useSelector((root: RootState) => root.album.album);
  const t = useTranslations("Album");
  const { data } = useQuery<
    { badgeByAlbumId: Badge[] },
    {
      albumId?: string;
    }
  >(findBadgeByAlbumIdQuery, {
    variables: {
      albumId: album?.id,
    },
    skip: !album,
  });

  const getPrivacyStatus = () => {
    if (!album) return t("noAlbumInfo");

    if (album.passwordProtected && album.public) {
      return t("protectedPublicPrivacy");
    } else if (album.passwordProtected) {
      return t("protectedPrivacy");
    } else if (album.public) {
      return t("publicPrivacy");
    }

    return t("standardPrivacy");
  };

  const withBadge = data && data?.badgeByAlbumId?.length > 0;

  return (
    <RoundedBox
      roundedOnSmallScreens={false}
      className="flex flex-col gap-6 md:flex-shrink-0 lg:w-[340px]"
    >
      <div className="flex justify-between gap-6 items-center">
        <Title wrap="nowrap" size="H5">
          {t("albumStatus")}
        </Title>
        <LocalizedLink
          className=" font-semibold hover:text-very-black text-black"
          to="/album-settings#privacy"
        >
          {t("editSettings")}
        </LocalizedLink>
      </div>
      <div>
        <Text className="font-semibold mb-2">
          {t("privacy")}
          <InfoToolitp text={t("privacyTooltip")} />
        </Text>
        <Text color="gray">{getPrivacyStatus()}</Text>
      </div>
      <div>
        <Text className="font-semibold mb-2">{t("moderation")}</Text>
        <Text color="gray">
          {album?.requireApproval ? t("withModeration") : t("noModeration")}
        </Text>
      </div>
      {withBadge && (
        <div>
          <Text className="font-semibold mb-2">{t("badge")}</Text>
          <Text color="gray">{t("active")}</Text>
        </div>
      )}
    </RoundedBox>
  );
};

export default AlbumStatus;
