import React, { useState, useMemo } from "react";
import { useTranslations } from "use-intl";
import {
  Back,
  Button,
  ResponsiveContainer,
  Text,
  Title,
} from "../../../common";
import { Album } from "../../../types/album.type";
import { PlanConfig } from "./Plans";
import { PlanProducts } from "../../../config/pricing.config";
import { Tooltip } from "@mantine/core";

interface UpgradePlanProps {
  purchase: (products: string[]) => void;
  storageOptions: number[];
  album: Album;
  planConfig: PlanConfig;
  loading?: boolean;
  disabled?: boolean;
  maximumStorageReached?: boolean;
}

const UpgradePlan: React.FC<UpgradePlanProps> = ({
  purchase,
  storageOptions,
  album,
  planConfig,
  loading,
  disabled = false,
  maximumStorageReached,
}) => {
  const t = useTranslations("Pricing.upgradePlan");
  const [isOpen, setIsOpen] = useState(false);
  const [chosenStorage, setChosenStorage] = useState<number | null>(null);

  const availableOptions = useMemo(
    () => storageOptions.filter((option) => option > album.maxSize),
    [storageOptions, album.maxSize]
  );

  const handleUpgrade = () => {
    if (chosenStorage) {
      const product = PlanProducts[planConfig.productNamespace][chosenStorage];
      product && purchase([product.priceId]);
    }
  };

  const renderStorageOptions = () => (
    <div className="flex flex-wrap gap-2 mt-2">
      {availableOptions.map((option) => (
        <button
          key={option}
          onClick={() => setChosenStorage(option)}
          className={`px-4 py-2 text-sm border rounded-full cursor-pointer transition-colors ${
            chosenStorage === option
              ? "border-medium-black bg-light-gray"
              : "border-black/10 hover:bg-light-gray/50"
          }`}
        >
          {option}
        </button>
      ))}
    </div>
  );

  return (
    <>
      <Tooltip
        disabled={!disabled}
        label={
          disabled
            ? maximumStorageReached
              ? t("disabledMaxReached")
              : t("disabledNotInPeriod")
            : ""
        }
        events={{ hover: true, focus: true, touch: true }}
        multiline
      >
        <Button
          disabled={disabled}
          minWidth={190}
          onClick={() => setIsOpen(true)}
          title={t("button")}
        />
      </Tooltip>

      <ResponsiveContainer opened={isOpen} close={() => setIsOpen(false)}>
        <div className="">
          <Back onClick={() => setIsOpen(false)} />
          <Title className="mt-6" size="H3">
            {t("title")}
          </Title>
          <Text className="mt-6" color="gray">
            {t("description")}
          </Text>
          <Text color="gray" className="mt-4">
            {t.rich("descriptionTwo", {
              b: (chunk) => <b>{chunk}</b>,
            })}
          </Text>
          <Text color="gray" className="mt-6">
            {t("select")}
          </Text>
          {renderStorageOptions()}
          <Text className="mt-6" color="gray">
            {t("footer")}
          </Text>
          <div className="flex justify-end mt-10">
            <Tooltip
              events={{ hover: true, focus: true, touch: true }}
              multiline
              disabled={!!chosenStorage}
              label={t("tooltip")}
            >
              <Button
                loading={loading}
                className="mt-6"
                onClick={handleUpgrade}
                disabled={!chosenStorage}
                title={t("continue")}
              />
            </Tooltip>
          </div>
        </div>
      </ResponsiveContainer>
    </>
  );
};

export default UpgradePlan;
