import { gql } from "@apollo/client";
import { UserDetails } from "./userQueries";

// Mutation for signing in
export const signInMutation = gql`
  mutation SignIn($input: SignInInput!) {
    signIn(signInInput: $input) {
      accessToken
      refreshToken
      user {
        ...UserDetails
      }
    }
  }
  ${UserDetails}
`;

// Mutation for sign up
export const signUpMutation = gql`
  mutation SignUp($input: SignUpInput!) {
    signUp(signUpInput: $input) {
      accessToken
      refreshToken
      user {
        ...UserDetails
      }
    }
  }
  ${UserDetails}
`;

// Mutation for google sign up
export const googleSignUpMutation = gql`
  mutation SignUpWithGoogle($input: GoogleSignUpInput!) {
    signUpWithGoogle(googleSignUpInput: $input) {
      accessToken
      refreshToken
      user {
        ...UserDetails
      }
    }
  }
  ${UserDetails}
`;

// Mutation for logging out
export const logOutMutation = gql`
  mutation LogOut {
    logOut
  }
`;

// Mutation for refreshing tokens
export const refreshTokensMutation = gql`
  mutation RefreshTokens {
    refreshTokens {
      accessToken
      refreshToken
      user {
        ...UserDetails
      }
    }
  }
  ${UserDetails}
`;
