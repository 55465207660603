import { useTranslations } from "use-intl";
import { Text } from ".";

const WhatsAppShareButton = ({
  link,
  onClick,
  password,
}: {
  link: string;
  onClick?: () => void;
  password?: string;
}) => {
  const t = useTranslations("Album");
  // Make sure your link starts with http:// or https://
  const formattedLink =
    link.startsWith("http://") || link.startsWith("https://")
      ? link
      : `http://${link}`;
  const message = t("whatsAppMessage");
  const passwordMessage = password ? t("passwordShareMessage") + password : "";

  const encodedMessage = encodeURIComponent(
    `${message} ${formattedLink} \n \n${passwordMessage}`
  );
  const whatsappUrl = `https://wa.me/?text=${encodedMessage}`;

  return (
    <div
      onClick={() => {
        onClick && onClick();
        window.open(whatsappUrl, "_blank");
      }}
      className="flex items-center gap-2 cursor-pointer"
    >
      <img className="h-8 w-8 " src="/whatsapp.png" alt="Share on WhatsApp" />
      <Text size="xs">{t("whatsApp")}</Text>
    </div>
  );
};

export default WhatsAppShareButton;
