import { FormikContextType } from "formik";
import { Album, CreateAlbumInput } from "../../../types/album.type";
import AlbumDetails from "../AlbumDetails";
import ImageMock from "../ImageMock";

const DoubleColumnStylePreview = ({
  album,
  formik,
  thumbnailUrl,
}: {
  album?: Album;
  formik: FormikContextType<CreateAlbumInput>;
  thumbnailUrl?: string;
}) => {
  return (
    <div
      style={{
        ...(formik.values.color === "#F7F7F7" && {
          backgroundColor: "#E0E0E0",
        }),
      }}
      className="flex flex-col justify-center flex-1 md:py-14 py-10 px-5 mb-4 bg-light-gray max-h-[250px] rounded-lg"
    >
      <div className="md:w-auto w-[100px] mx-auto md:mx-0">
        <div className="flex flex-col-reverse md:flex-row">
          <div
            style={{
              backgroundColor: formik.values.bannerColor,
            }}
            className="md:h-[90px] flex-1 flex items-center justify-center py-2 md:py-0"
          >
            <div className="px-2 md:px-6 min-h-4">
              <AlbumDetails album={album} />
            </div>
          </div>
          <div className="h-[90px] bg-light-beige flex-1 relative">
            <img
              src={thumbnailUrl ?? "/gradient-pattern.webp"}
              alt={album?.name}
              className="w-full h-full object-cover"
            />
          </div>
        </div>
        <div
          style={{ backgroundColor: formik.values.color }}
          className="flex gap-1 py-2 md:px-4 px-1"
        >
          <ImageMock formik={formik} />
        </div>
      </div>
    </div>
  );
};

export default DoubleColumnStylePreview;
