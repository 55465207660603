import { useTranslations } from "use-intl";
import { CenteredBlockWrapper, Text, Title } from "../../common";

const ErrorPage = () => {
  const t = useTranslations("Error");
  return (
    <CenteredBlockWrapper>
      <Title size="H1" className=" text-center mt-4 animate-bounce">
        404
      </Title>
      <Text>{t("suchPageDoesntExist")}</Text>
    </CenteredBlockWrapper>
  );
};

export default ErrorPage;
