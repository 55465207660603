import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

type QueryParam = { [key: string]: any };
/**
 * Converts a string value from the URL to its intended primitive type.
 */
const convertToPrimitive = (
  value: string,
  initialValue: any,
  isArray: boolean = false
): any => {
  if (isArray) {
    if (!value) return []; // Return an empty array if the value is empty
    return value
      .split(",")
      .map((item) => convertToPrimitive(item, initialValue[0]));
  } else if (initialValue === true || initialValue === false) {
    // Convert to boolean if the initial value is a boolean
    return JSON.parse(value);
  } else if (!isNaN(Number(value))) {
    // Convert to number if the value is numeric
    return Number(value);
  }
  // Return as string by default
  return value;
};

function useSearchParamsState<T extends QueryParam>(
  initialQueryParams: T
): [searchParamsState: T, setSearchParamsState: (param: Partial<T>) => void] {
  const [searchParams, setSearchParams] = useSearchParams();
  const [params, setParams] = useState<T>(() => {
    const paramsFromURL: Partial<T> = {};
    Object.keys(initialQueryParams).forEach((key) => {
      const value = searchParams.get(key);
      const isArray = Array.isArray(initialQueryParams[key]);
      if (value !== null) {
        paramsFromURL[key as keyof T] = convertToPrimitive(
          value,
          initialQueryParams[key],
          isArray
        ) as T[keyof T];
      }
    });
    return { ...initialQueryParams, ...paramsFromURL };
  });

  const setSearchParamsState = (providedParams: Partial<T>) => {
    const newParams = { ...params, ...providedParams };

    setParams(newParams); // Update the state with the new parameters
    const newSearchParams = new URLSearchParams();
    Object.entries(newParams).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        if (value.length > 0) {
          newSearchParams.set(key, value.join(","));
        } else {
          newSearchParams.delete(key); // Remove the key if the array is empty
        }
      } else if (value !== undefined && value !== initialQueryParams[key]) {
        newSearchParams.set(key, String(value));
      }
    });
    setSearchParams(newSearchParams, { replace: true });
  };

  // Effect to update local state when relevant URL search parameters change
  useEffect(() => {
    const updatedParams: Partial<T> = {};
    let isParamUpdated = false;

    Object.keys(initialQueryParams).forEach((key) => {
      const value = searchParams.get(key);
      const isArray = Array.isArray(initialQueryParams[key]);

      if (value !== null) {
        const convertedValue = convertToPrimitive(
          value,
          initialQueryParams[key],
          isArray
        );

        if (params[key] !== convertedValue) {
          // Using type assertion here as well
          updatedParams[key as keyof T] = convertedValue as T[keyof T];
          isParamUpdated = true;
        }
      }
    });

    if (isParamUpdated) {
      setParams((prev) => ({ ...prev, ...updatedParams }));
    }
  }, [searchParams]);

  return [params, setSearchParamsState];
}

export default useSearchParamsState;
