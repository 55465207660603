const RoundedBox = ({
  children,
  className,
  color,
  roundedOnSmallScreens = true,
  ...props
}: {
  roundedOnSmallScreens?: boolean;
  children: React.ReactNode;
  color?:
    | "light-blue"
    | "white"
    | "red"
    | "light-orange"
    | "light-green"
    | "light-beige"
    | "blue"
    | "black"
    | "orange";
} & React.HTMLProps<HTMLDivElement>) => {
  // Function to determine the background class based on the color prop
  const backgroundColorClass = () => {
    switch (color) {
      case "blue":
        return "bg-blue";
      case "light-beige":
        return "bg-light-beige";
      case "light-blue":
        return "bg-light-blue";
      case "red":
        return "bg-red/20";
      case "light-orange":
        return "bg-light-orange";
      case "light-green":
        return "bg-light-green";
      case "orange":
        return "bg-orange";
      case "black":
        return "bg-black";
      default:
        return "bg-white";
    }
  };

  const roundedStyles = roundedOnSmallScreens
    ? "rounded-xl"
    : "md:rounded-xl rounded-none";

  return (
    <div
      {...props}
      className={`${backgroundColorClass()} relative ${roundedStyles} p-4 md:p-6 ${
        className || ""
      }`}
    >
      {children}
    </div>
  );
};

export default RoundedBox;
