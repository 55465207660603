import { useTranslations } from "use-intl";
import { Button, Text } from ".";
import { IconDownload } from "@tabler/icons-react";
import { Album } from "../types/album.type";
import { ErrorService } from "../services";
import { useState } from "react";
import { Loader } from "@mantine/core";
import { closeModal, openModal } from "@mantine/modals";
import { useAuth } from "../hooks";

const DownloadAll = ({
  onClose,
  album,
  disabled,
  type = "text",
  title,
}: {
  disabled?: boolean;
  onClose?: () => void;
  album: Album;
  type?: "text" | "button";
  title: string;
}) => {
  const t = useTranslations("Album");
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();

  const checkIfReallyWantDownload = () => {
    onClose && onClose();
    openModal({
      styles: {
        content: {
          borderRadius: 8,
        },
      },
      modalId: "sureToDownload",
      centered: true,
      withCloseButton: false,
      children: (
        <div>
          <Text color="gray">{t("sureToDownload")}</Text>
          <div className="flex items-end justify-end gap-2 mt-6">
            <Button
              small
              onClick={() => closeModal("sureToDownload")}
              type="outline"
              title={t("cancel")}
            />
            <Button
              loading={loading}
              small
              onClick={handleDownloadAll}
              color="green"
              title={t("yesDownload")}
            />
          </div>
        </div>
      ),
    });
  };

  const handleDownloadAll = async () => {
    closeModal("sureToDownload");
    setLoading(true);

    try {
      let downloadUrl =
        import.meta.env.VITE_API_URL + `/image/download/all/${album.id}`;
      // If user is guest, add guest user id to download url
      if (user && user.isGuest) {
        downloadUrl = downloadUrl + `?guestUserId=${user.id}`;
      }
      //Open url in new tab
      window.open(downloadUrl, "_blank");
      ErrorService.showMessage(t("successfullyDownloaded"));
      onClose && onClose();
    } catch (error) {
      ErrorService.showError(t("errorDownloadingImages"));
    } finally {
      setLoading(false);
    }
  };

  if (type === "button") {
    return (
      <>
        <Button
          loading={loading}
          type="outline"
          onClick={() => !disabled && checkIfReallyWantDownload()}
          disabled={disabled}
          title={title}
        />
      </>
    );
  } else {
    return (
      <>
        <Text
          onClick={() => !disabled && checkIfReallyWantDownload()}
          className={`flex gap-2 items-center font-semibold ${
            disabled ? "opacity-50 cursor-not-allowed" : "cursor-pointer"
          }`}
        >
          {loading ? (
            <Loader size={16} color="#222222" />
          ) : (
            <IconDownload stroke={3} size={16} className="stroke-black" />
          )}
          {title}
        </Text>
      </>
    );
  }
};

export default DownloadAll;
