import { useState, useEffect } from "react";

const useIsInPhotoWall = (): boolean => {
  const [isInPhotoWall, setIsInPhotoWall] = useState<boolean>(false);

  useEffect(() => {
    const checkIfInPhotoWall = () => {
      setIsInPhotoWall(window.location.href.includes("wall"));
    };

    checkIfInPhotoWall();
  }, []);

  return isInPhotoWall;
};

export default useIsInPhotoWall;
