import React, { useState, useEffect } from "react";
import { useTranslations } from "use-intl";
import { Text } from "../../../common";

const EnterFullScreen: React.FC = () => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isFullScreenSupported, setIsFullScreenSupported] = useState(true);
  const t = useTranslations("Album");

  useEffect(() => {
    // Check if fullscreen is supported
    const fullScreenSupported =
      document.documentElement.requestFullscreen ||
      (document as any).documentElement.mozRequestFullScreen ||
      (document as any).documentElement.webkitRequestFullscreen ||
      (document as any).documentElement.msRequestFullscreen;

    setIsFullScreenSupported(!!fullScreenSupported);

    const handleFullScreenChange = () => {
      setIsFullScreen(
        !!(
          document.fullscreenElement ||
          (document as any).webkitFullscreenElement ||
          (document as any).mozFullScreenElement ||
          (document as any).msFullscreenElement
        )
      );
    };

    document.addEventListener("fullscreenchange", handleFullScreenChange);
    document.addEventListener("webkitfullscreenchange", handleFullScreenChange);
    document.addEventListener("mozfullscreenchange", handleFullScreenChange);
    document.addEventListener("MSFullscreenChange", handleFullScreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
      document.removeEventListener(
        "webkitfullscreenchange",
        handleFullScreenChange
      );
      document.removeEventListener(
        "mozfullscreenchange",
        handleFullScreenChange
      );
      document.removeEventListener(
        "MSFullscreenChange",
        handleFullScreenChange
      );
    };
  }, []);

  const enterFullScreen = () => {
    const element = document.documentElement;

    if (element.requestFullscreen) {
      element
        .requestFullscreen()
        .catch((err) =>
          console.error("Error attempting to enable fullscreen:", err)
        );
    } else if ((element as any).mozRequestFullScreen) {
      // Firefox
      (element as any).mozRequestFullScreen();
    } else if ((element as any).webkitRequestFullscreen) {
      // Chrome, Safari and Opera
      (element as any).webkitRequestFullscreen();
    } else if ((element as any).msRequestFullscreen) {
      // IE/Edge
      (element as any).msRequestFullscreen();
    }
  };

  // Check if the user is on iOS Safari
  const isIOS =
    /iPad|iPhone|iPod/.test(navigator.userAgent) && !("MSStream" in window);
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  if (!isFullScreenSupported || isIOS || isSafari) {
    return null; // Don't render anything if fullscreen is not supported or on iOS Safari
  }

  return (
    <div>
      {!isFullScreen && (
        <div
          className="absolute top-4 left-1/2 transform -translate-x-1/2 p-2 px-4 bg-black bg-opacity-50 rounded-md cursor-pointer z-50"
          onClick={enterFullScreen}
        >
          <Text color="white">{t("enterFullScreen")}</Text>
        </div>
      )}
    </div>
  );
};

export default EnterFullScreen;
