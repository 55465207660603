import { useLocation, useParams } from "react-router-dom";
import {
  Button,
  CenteredBlockWrapper,
  Error,
  PasswordInput,
  Text,
  Title,
} from "../../common";
import {
  findAlbumByShortIdQuery,
  signInToAlbumMutation,
} from "../../graphql/albumQueries";
import { Album } from "../../types/album.type";
import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useLocalizedNavigate } from "../../hooks";
import { useTranslations } from "use-intl";
import { ErrorService } from "../../services";
import { isUnauthenticatedError } from "../../lib/utils";
import { apolloClientWithoutErrorLink } from "../../lib/apolloClient";

const AlbumPasswordProtectedPage = () => {
  const { shortId } = useParams<{ shortId: string }>();
  const navigate = useLocalizedNavigate();
  const [password, setPassword] = useState("");
  const t = useTranslations("AlbumPasswordProtected");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const { state } = useLocation();
  const fromPage = state?.from;

  //Load album
  const { error: albumError, data: { album } = {} } = useQuery<
    { album: Album },
    { shortId: string }
  >(findAlbumByShortIdQuery, {
    variables: {
      shortId: shortId ?? "",
    },
    skip: !shortId,
  });

  useEffect(() => {
    if (albumError) ErrorService.showError(t("errorLoading"));
  }, [albumError]);

  const loginToAlbum = () => {
    setLoading(true);
    album &&
      apolloClientWithoutErrorLink
        .mutate<
          { signInToAlbum: Boolean },
          { albumId: string; password: string }
        >({
          mutation: signInToAlbumMutation,
          variables: { albumId: album.id, password },
        })
        .then((response) => {
          const successfullySignedIn = response.data?.signInToAlbum;
          if (successfullySignedIn) {
            ErrorService.showMessage(t("successfullySignedIn"));
            if (fromPage) {
              navigate(fromPage);
            } else {
              //By default go to album
              navigate(`/album/${shortId}`);
            }
          } else {
            ErrorService.showError(t("errorSigning"));
          }
        })
        .catch((error) => {
          setError(error);
          if (isUnauthenticatedError(error)) {
            ErrorService.showError(t("wrongPassword"));
          } else {
            ErrorService.showError(t("errorSigning"));
          }
        })
        .finally(() => {
          setLoading(false);
        });
  };

  useEffect(() => {
    if (album && !album?.passwordProtected) {
      navigate(`/album/${album?.shortId}`);
    }
  }, [album]);
  return (
    <CenteredBlockWrapper>
      <div className="flex flex-col gap-2">
        <Title size="H3">{t("hi")}</Title>
        <Text color="gray">
          {t.rich("welcome", {
            album: () => <span className=" text-black">{album?.name}</span>,
          })}
        </Text>
        <Text color="gray">{t("description")}</Text>
      </div>
      {error && (
        <div className="mt-6">
          <Error
            message={
              isUnauthenticatedError(error)
                ? t("wrongPassword")
                : t("errorSigning")
            }
          />
        </div>
      )}
      <PasswordInput
        className="mt-6"
        placeholder={t("password")}
        value={password}
        name="password"
        label={t("password")}
        onChange={(event) => setPassword(event.target.value)}
      />
      <Button
        className="mt-4"
        loading={loading}
        fullWidth
        onClick={loginToAlbum}
        title={t("viewAlbum")}
      />
    </CenteredBlockWrapper>
  );
};

export default AlbumPasswordProtectedPage;
