import { Button, EventSelector, Input, Text, Title } from ".";
import { useTranslations } from "use-intl";
import { Formik } from "formik";
import { EventType } from "../types/event.enum";
import AlbumColorPicker, { COLORS } from "./AlbumColorPicker";
import { useMutation } from "@apollo/client";
import { Album, CreateAlbumInput } from "../types/album.type";
import { createAlbumMutation } from "../graphql/albumQueries";
import { useDispatch } from "react-redux";
import { ErrorService } from "../services";
import * as Yup from "yup";
import { addAlbum } from "../store/album.reducer";

const CreateAlbum = ({
  closeForm,
  onCreated,
}: {
  closeForm?: () => void;
  onCreated: (album: Album) => void;
}) => {
  const t = useTranslations();
  const dispatch = useDispatch();

  const [createAlbum, { loading }] = useMutation<
    { createAlbum: Album },
    { input: CreateAlbumInput }
  >(createAlbumMutation);

  const onSubmit = (values: CreateAlbumInput) => {
    createAlbum({ variables: { input: values } })
      .then((response) => {
        const createdAlbum = response.data?.createAlbum;
        if (createdAlbum) {
          dispatch(addAlbum(createdAlbum));
          ErrorService.showMessage(t("NewAlbum.albumCreated"));
          onCreated(createdAlbum);
          closeForm && closeForm();
        } else {
          ErrorService.showError(t("Album.errorOnAlbumCreation"));
        }
      })
      .catch(() => {
        ErrorService.showError(t("Album.errorOnAlbumCreation"));
      });
  };

  return (
    <Formik
      initialValues={{
        name: "",
        type: EventType.OTHER,
        color: COLORS["light-gray"],
        public: true,
        bannerColor: COLORS["light-beige"],
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string()
          .max(200, t("Validation.tooLong"))
          .required(t("Validation.required")),
        color: Yup.string().required(t("Validation.required")),
        type: Yup.string()
          .oneOf(Object.values(EventType), t("Validation.invalidType"))
          .required(t("Validation.required")),
      })}
      onSubmit={onSubmit}
      validateOnChange={false}
      enableReinitialize
    >
      {(props) => (
        <div className="flex flex-col justify-between flex-1 md:gap-10 gap-6">
          <div>
            <div className="flex justify-between">
              <Title size="H3">{t("NewAlbum.createAlbum")}</Title>
            </div>
            <Text color="gray" className="mt-2">
              {t("NewAlbum.createNewAlbumDescription")}
            </Text>
          </div>
          <div>
            <Text className="mb-4" color="gray">
              {t("NewAlbum.selectOccasion")}
            </Text>
            <EventSelector
              value={props.values.type}
              small
              handleChange={(event) => {
                props.setFieldValue("type", event.target.value);
              }}
            />
          </div>

          <Input
            value={props.values.name}
            name="name"
            label={t("NewAlbum.nameAlbum")}
            placeholder={t("NewAlbum.albumName")}
            onChange={props.handleChange}
            error={props.errors.name}
          />
          <div>
            <Text className="mb-4" color="gray">
              {t("NewAlbum.selectBackgroundColor")}
            </Text>
            <AlbumColorPicker
              value={props.values.color}
              name="color"
              handleChange={props.handleChange}
            />
          </div>
          <Button
            loading={loading}
            onClick={() => {
              props.submitForm();
            }}
            className="mb-6 md:m-0"
            title={t("NewAlbum.create")}
          />
        </div>
      )}
    </Formik>
  );
};

export default CreateAlbum;
