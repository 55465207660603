import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import * as Sentry from "@sentry/react";
import {
  useNavigationType,
  matchRoutes,
  createRoutesFromChildren,
} from "react-router-dom";
import useLocalizedLocation from "./hooks/useLocalizedLocation.tsx";
import { version } from "../package.json";
import { PostHogProvider } from "posthog-js/react";
import posthog from "posthog-js";

posthog.init(import.meta.env.VITE_APP_PUBLIC_POSTHOG_KEY, {
  disable_session_recording: import.meta.env.MODE === "development",
  api_host: import.meta.env.VITE_APP_PUBLIC_POSTHOG_HOST,
});

if (import.meta.env.MODE === "development") {
  posthog.debug();
  posthog.opt_out_capturing();
}

Sentry.init({
  debug: false,
  environment: import.meta.env.MODE,
  release: "rompolo-app@" + version,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration({
      enableInp: true,
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation: useLocalizedLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.05, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    "192.168.1.95",
    "rompolo.com",
    "api.rompolo.com",
  ],
  beforeSend(event) {
    // Ignore ResizeObserver loop error
    if (
      event.message &&
      event.message.includes(
        "ResizeObserver loop completed with undelivered notifications"
      )
    ) {
      return null;
    }
    return event;
  },
  // Session Replay
  replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <PostHogProvider client={posthog}>
      <App />
    </PostHogProvider>
  </React.StrictMode>
);
