import { useTranslations } from "use-intl";
import { CenteredBlockWrapper, Text } from "../../common";
import { LocalizedLink } from "../../router";

const AlbumNotFoundPage = () => {
  const t = useTranslations("AlbumNotFound");

  return (
    <CenteredBlockWrapper>
      <div>
        <Text color="gray">
          {t.rich("text", {
            link: (chunks) => (
              <LocalizedLink className="underline text-black" to="/">
                {chunks}
              </LocalizedLink>
            ),
          })}
        </Text>
      </div>
    </CenteredBlockWrapper>
  );
};

export default AlbumNotFoundPage;
