import { MantineProvider } from "@mantine/core";
import { ApolloProvider } from "@apollo/client";
import { apolloClient } from "./lib/apolloClient";
import { Provider } from "react-redux";
import { persistor, store } from "./store";
import { useEffect, useState } from "react";
import { AuthService } from "./services";
import { setUser } from "./store/user.reducer";
import { ModalsProvider } from "@mantine/modals";
import { Notifications } from "@mantine/notifications";
import { RouterProvider } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { HelmetProvider } from "react-helmet-async";
import { ErrorCatcher, Loading } from "./common";
import * as Sentry from "@sentry/react";
import "@stripe/stripe-js";
import "@mantine/core/styles.css";
import "./styles/global.css";
import "@mantine/notifications/styles.css";
import "@mantine/dates/styles.css";
import "@mantine/charts/styles.css";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { router } from "./router/router";

const App = () => {
  const [isAuthenticationChecked, setAuthenticationChecked] = useState(false);
  const checkIfAuthenticated = async () => {
    let user = await AuthService.getUser();
    if (!user) {
      user = await AuthService.getGuestUser();
    }
    store.dispatch(setUser(user));
    setAuthenticationChecked(true);
  };

  useEffect(() => {
    checkIfAuthenticated();
  }, []);

  return (
    <Sentry.ErrorBoundary fallback={() => <ErrorCatcher />}>
      <GoogleOAuthProvider clientId={import.meta.env.VITE_GOOGLE_CLIENT_ID}>
        <HelmetProvider>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <ApolloProvider client={apolloClient}>
                <MantineProvider>
                  <ModalsProvider>
                    <Notifications containerWidth={450} position="top-center" />
                    {!isAuthenticationChecked ? (
                      <div className="absolute flex justify-center items-center w-screen h-screen">
                        <Loading />
                      </div>
                    ) : (
                      <RouterProvider router={router} />
                    )}
                  </ModalsProvider>
                </MantineProvider>
              </ApolloProvider>
            </PersistGate>
          </Provider>
        </HelmetProvider>
      </GoogleOAuthProvider>
    </Sentry.ErrorBoundary>
  );
};

export default App;
