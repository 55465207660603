import { useTranslations } from "use-intl";
import { OnboardingWrapper } from ".";
import { useFormikContext } from "formik";
import { Button, EventSelector, Text, Title } from "../../../common";
import { CreateAlbumInput } from "../../../types/album.type";

const FirstStep = ({ goNext }: { goNext: () => void }) => {
  const t = useTranslations();
  const formik = useFormikContext<CreateAlbumInput>();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue("type", event.target.value);
  };

  return (
    <OnboardingWrapper>
      <div className="flex justify-center px-4 md:px-0">
        <div className="md:max-w-[340px]">
          <div className="pt-14 md:pt-20">
            <Title size="H3">{t("Onboarding.aboutEvent")}</Title>
            <Text className="pt-2" color="gray">
              {t("Onboarding.aboutEventDescription")}
            </Text>
          </div>
          <div className=" mt-8 md:mt-10">
            <EventSelector
              value={formik.values.type}
              handleChange={handleChange}
            />
          </div>
        </div>
      </div>
      <div className="flex-1 justify-center flex mb-8 items-end md:justify-end md:flex-col pt-8 px-4 md:m-10">
        <Button
          title={t("Event.continue")}
          onClick={() => {
            const { values } = formik;
            if (values.type) {
              goNext();
            }
          }}
        />
      </div>
    </OnboardingWrapper>
  );
};

export default FirstStep;
