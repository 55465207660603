import { useLazyQuery } from "@apollo/client";
import { PaginatedType } from "../types/paginated.type";
import { Image, ImageSearchProps } from "../types/image.type";
import { albumImagesQuery } from "../graphql/imageQueries";

// Define the return type of your GraphQL query if not already defined
interface AlbumImagesData {
  albumImages: PaginatedType<Image>;
}

// Define the type for your hook's return value
interface LoadCountsHook {
  loadCounts: () => void;
  unPublishedCount: number;
  publishedCount: number;
  notApprovedCount: number;
  loading: boolean;
}

export const useLoadCounts = (albumId: string | null): LoadCountsHook => {
  const [
    loadUnPublishedCount,
    { data: unPublishedCountData, loading: unPublishedLoading },
  ] = useLazyQuery<AlbumImagesData, ImageSearchProps>(albumImagesQuery);

  const [
    loadPublishedCount,
    { data: publishedCountData, loading: publishedLoading },
  ] = useLazyQuery<AlbumImagesData, ImageSearchProps>(albumImagesQuery);

  const [
    loadNotApprovedCount,
    { data: notApprovedCountData, loading: notApprovedLoading },
  ] = useLazyQuery<AlbumImagesData, ImageSearchProps>(albumImagesQuery);

  const loadCounts = (): void => {
    if (albumId) {
      loadUnPublishedCount({
        variables: { albumId, first: 0, published: false },
        fetchPolicy: "network-only",
      });
      loadPublishedCount({
        variables: { albumId, first: 0, published: true, approved: true },
        fetchPolicy: "network-only",
      });
      loadNotApprovedCount({
        variables: { albumId, first: 0, approved: false },
        fetchPolicy: "network-only",
      });
    }
  };

  const unPublishedCount = unPublishedCountData?.albumImages.totalCount ?? 0;
  const publishedCount = publishedCountData?.albumImages.totalCount ?? 0;
  const notApprovedCount = notApprovedCountData?.albumImages.totalCount ?? 0;

  // Determine the overall loading state
  const loading = unPublishedLoading || publishedLoading || notApprovedLoading;

  return {
    loadCounts,
    unPublishedCount,
    publishedCount,
    notApprovedCount,
    loading,
  };
};
