import { useLocale, useTranslations } from "use-intl";
import {
  DateInput,
  Info,
  Input,
  SelectInput,
  SettingsBlock,
  Text,
  TextArea,
} from "../";
import { useFormikContext } from "formik";
import { Album, CreateAlbumInput } from "../../types/album.type";
import { EventType } from "../../types/event.enum";

const GeneralSettings = ({
  handleFieldChange,
}: {
  album?: Album;
  handleFieldChange: (e: React.ChangeEvent<any> | string, value?: any) => void;
}) => {
  const t = useTranslations();
  const formik = useFormikContext<CreateAlbumInput>();
  const locale = useLocale();

  const events: { value: EventType; label: string }[] = [
    { value: EventType.WEDDING, label: t("Event.wedding") },
    { value: EventType.MEMORIAL, label: t("Event.memorial") },
    { value: EventType.PARTY, label: t("Event.party") },
    { value: EventType.CORPORATE_EVENT, label: t("Event.corporate") },
    { value: EventType.OTHER, label: t("Event.other") },
  ];

  return (
    <SettingsBlock description={t("AlbumSettings.generalDescription")}>
      <SelectInput
        onChange={(value) => handleFieldChange("type", value)}
        value={formik.values.type}
        data={events}
        name="type"
        label={t("AlbumSettings.eventType")}
        error={formik.errors.type}
      />
      {formik.values.type === EventType.MEMORIAL && (
        <Info name="memorialInfo">
          <Text>{t("AlbumSettings.memorialInfo")}</Text>
        </Info>
      )}
      {formik.values.type === EventType.WEDDING && (
        <Info name="weddingInfo">
          <Text>{t("AlbumSettings.weddingDetails")}</Text>
        </Info>
      )}
      <Input
        value={formik.values.name}
        name="name"
        label={t("AlbumSettings.albumName")}
        onChange={handleFieldChange}
        error={formik.errors.name}
      />
      <TextArea
        styles={{
          input: {
            height: "200px",
          },
        }}
        value={formik.values.description}
        name="description"
        label={t("AlbumSettings.description")}
        onChange={handleFieldChange}
        error={formik.errors.description}
        minRows={6}
        maxRows={10}
      />
      {formik.values.type === EventType.MEMORIAL ? (
        <div className="flex gap-4">
          <DateInput
            locale={locale}
            name="yearsFrom"
            onChange={(value) => handleFieldChange("yearsFrom", value)}
            value={
              formik.values.yearsFrom
                ? new Date(formik.values.yearsFrom)
                : undefined
            }
            label={t("AlbumSettings.yearsFrom")}
            error={formik.errors.yearsFrom}
          />
          <DateInput
            locale={locale}
            id="yearsTo"
            name="yearsTo"
            onChange={(value) => handleFieldChange("yearsTo", value)}
            value={
              formik.values.yearsTo
                ? new Date(formik.values.yearsTo)
                : undefined
            }
            label={t("AlbumSettings.yearsTo")}
            error={formik.errors.yearsTo}
          />
        </div>
      ) : (
        <DateInput
          clearable
          locale={locale}
          name="eventDate"
          onChange={(value) => handleFieldChange("eventDate", value)}
          value={
            formik.values.eventDate
              ? new Date(formik.values.eventDate)
              : undefined
          }
          label={t("AlbumSettings.eventDate")}
          error={formik.errors.eventDate}
        />
      )}
    </SettingsBlock>
  );
};

export default GeneralSettings;
