import { useEffect, useState } from "react";
import {
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
  matchPath,
} from "react-router-dom";
import { IntlProvider } from "use-intl";
import { useAuth } from "../hooks";
import { applicationRoutes } from "./routes";
import { removeLocaleFromPath } from "../lib/utils";
import {
  DEFAULT_LOCALE,
  Messages,
  LOCALES,
  messages,
} from "../locales/Locales";

const LocaleProtectedRoutes = () => {
  // Extracting locale from URL parameters
  const { locale } = useParams<{ locale: keyof Messages | undefined }>();
  const navigate = useNavigate();
  const location = useLocation();
  const { user, roles } = useAuth();

  // Retrieving stored locale or using the default if not present
  const storedLocale = localStorage.getItem("locale") as keyof Messages | null;
  const [effectiveLocale, setEffectiveLocale] = useState<keyof Messages>(
    locale ?? storedLocale ?? DEFAULT_LOCALE
  );

  const [isLocaleValid, setIsLocaleValid] = useState(false);

  // Determining the set of routes based on the user's roles
  const routes = applicationRoutes(roles);
  const normalizedPathname = removeLocaleFromPath(location.pathname);

  useEffect(() => {
    const updateLocaleAndNavigate = (
      newLocale: keyof Messages,
      newPath: string
    ) => {
      setEffectiveLocale(newLocale);
      console.log(newLocale, "new locale");

      localStorage.setItem("locale", newLocale);
      navigate(newPath, { replace: true });
      setIsLocaleValid(true);
    };

    // Splitting the URL path into segments and identifying the first segment.
    const pathSegments = window.location.pathname.split("/").filter(Boolean);
    const potentialLocaleSegment = pathSegments[0];
    const isFirstSegmentLocale =
      potentialLocaleSegment &&
      LOCALES.includes(potentialLocaleSegment as keyof Messages);
    const searchParams = window.location.search;

    // Check if the first segment is exactly two characters (potentially a locale).
    if (potentialLocaleSegment && potentialLocaleSegment.length === 2) {
      if (isFirstSegmentLocale) {
        // If it's a valid locale, confirm it and set as valid.
        localStorage.setItem(
          "locale",
          potentialLocaleSegment as keyof Messages
        );
        setIsLocaleValid(true);
      } else {
        // If it's not a valid locale, replace it with the effective locale and navigate.
        const newLocale =
          storedLocale && LOCALES.includes(storedLocale)
            ? storedLocale
            : DEFAULT_LOCALE;
        const newPath = `/${newLocale}/${pathSegments
          .slice(1)
          .join("/")}${searchParams}`;
        updateLocaleAndNavigate(newLocale, newPath);
      }
    } else {
      // If the first segment isn't two characters, proceed with the effective locale.
      const newLocale =
        storedLocale && LOCALES.includes(storedLocale)
          ? storedLocale
          : DEFAULT_LOCALE;
      const newPath = `/${newLocale}/${pathSegments.join("/")}${searchParams}`;
      updateLocaleAndNavigate(newLocale, newPath);
    }
  }, [locale, navigate]);

  if (!isLocaleValid) {
    return null; // Show loading indicator or a placeholder as needed
  }

  // Route matching based on the normalized path
  const route =
    normalizedPathname === ""
      ? routes.find((r) => r.index)
      : routes.find((route) =>
          matchPath(("/" + route?.path) as string, "/" + normalizedPathname)
        );

  // Redirect if no matching route is found
  if (!route) {
    return <Navigate to="/" replace state={{ from: location }} />;
  }

  // Determine the redirection path based on user status and route requirements
  const determineRedirection = () => {
    // If no user, or user is guest and guest is not having access to page, redirect to signin
    if (!user || user.isGuest) {
      if (!route.hasAccess) return "/signin";
      return null;
    }
    //If user is logged in, and open signin or signup page, redirect to root page
    if (
      route.hasAccess &&
      (route.path === "signin" || route.path === "signup")
    ) {
      return "/";
    }
    //If user is not verified and path is not verify, redirect to verify page
    if (!user.verified && route.path !== "verify") return "/verify";
    //If user verified and not onboarded, and route is not onboarding, redirect to onboarding page
    if (user.verified && !user.onboarded && route.path !== "onboarding")
      return "/onboarding";
    //If user is onboarded and is on onboarding, redirect to root page
    if (user.onboarded && route.path === "onboarding") return "/";
    return null;
  };

  const redirectUrl = determineRedirection();

  if (redirectUrl) {
    // Prefix the redirection URL with the effective locale
    return <Navigate to={`/${effectiveLocale}${redirectUrl}`} />;
  }

  // Render the internationalization provider and the child components
  return (
    <IntlProvider locale={effectiveLocale} messages={messages[effectiveLocale]}>
      <Outlet />
    </IntlProvider>
  );
};

export default LocaleProtectedRoutes;
