import { useRef } from "react";
import { QRCode as QRCodeLogo } from "react-qrcode-logo";
import { useTranslations } from "use-intl";
import { Text } from ".";
import { IconDownload } from "@tabler/icons-react";
import Logo from "../assets/qr-logo.png";
import QRCode from "qrcode";

const QRCodeComponent = ({
  url,
  withDownload,
  label,
  size = 120,
}: {
  url: string;
  withDownload?: boolean;
  label?: string;
  size?: number;
}) => {
  const t = useTranslations("QR");
  const qrRef = useRef<HTMLDivElement>(null);

  const downloadQR = async () => {
    const highResSize = 600; // High resolution size for download
    let logoPadding = Math.round(highResSize * 0.05);

    // Create a canvas element
    const canvas = document.createElement("canvas");
    canvas.width = highResSize;
    canvas.height = highResSize;

    // Generate QR code onto the canvas
    await QRCode.toCanvas(canvas, url, {
      width: highResSize,
      margin: 0,
      color: {
        dark: "#222",
        light: "#ffffff", // White background
      },
    });

    // Load the logo image
    const logo = new Image();
    logo.src = Logo;
    logo.onload = () => {
      const ctx = canvas.getContext("2d");
      if (ctx) {
        // Calculate logo size and position, including padding
        const logoSize = 100;
        const totalLogoSize = logoSize + 2 * logoPadding;
        const x = (canvas.width - totalLogoSize) / 2;
        const y = (canvas.height - totalLogoSize) / 2;

        // Draw a white rectangle as background for the logo
        ctx.fillStyle = "#ffffff";
        ctx.fillRect(x, y, totalLogoSize, totalLogoSize);

        // Draw the logo on the canvas
        ctx.drawImage(
          logo,
          x + logoPadding,
          y + logoPadding,
          logoSize,
          logoSize
        );

        // Trigger download
        const pngUrl = canvas
          .toDataURL("image/png")
          .replace("image/png", "image/octet-stream");

        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = "QRCode.png";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }
    };
  };

  // Calculate padding as 5% of the size, for example
  let logoPadding = Math.round(size * 0.05);

  // Ensure the padding is an odd number
  if (logoPadding % 2 === 0) {
    // Adjust by 1, but ensure it doesn't become negative
    logoPadding = logoPadding > 0 ? logoPadding - 1 : 1; // Ensures padding is odd and not negative
  }

  return (
    <div ref={qrRef} className="flex flex-col items-center">
      <div className="flex flex-row md:flex-col md:items-center gap-4">
        <QRCodeLogo
          quietZone={0}
          logoWidth={20}
          logoHeight={20}
          logoImage={Logo}
          logoPadding={logoPadding}
          removeQrCodeBehindLogo={true}
          value={url}
          size={size}
          fgColor="#222"
        />
        {withDownload && (
          <Text
            size="sm"
            wrap="nowrap"
            onClick={downloadQR}
            className="flex cursor-pointer items-center font-medium"
          >
            <IconDownload width={16} height={16} className="mr-1" />
            {t("download")}
          </Text>
        )}
      </div>
      {label && (
        <Text
          style={{ maxWidth: size }}
          size="xl"
          className="mt-3 font-semibold text-center"
          color="white"
        >
          {label}
        </Text>
      )}
    </div>
  );
};

export default QRCodeComponent;
