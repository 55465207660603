import {
  ApolloClient,
  ApolloError,
  ApolloLink,
  InMemoryCache,
  Observable,
  createHttpLink,
  from,
  fromPromise,
  split,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { AuthService } from "../services";
import { isUnauthenticatedError } from "./utils";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { getMainDefinition } from "@apollo/client/utilities";

const httpLink = createHttpLink({
  uri: import.meta.env.VITE_GRAPHQL_URL,
  credentials: "include",
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: import.meta.env.VITE_GRAPHQL_SUBSCRIPTION_URL,
  })
);

// The split function takes three parameters:
//
// * A function that's called for each operation to execute
// * The Link to use for an operation if the function returns a "truthy" value
// * The Link to use for an operation if the function returns a "falsy" value
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

let isTokenRefreshing = false;
let pendingRequests: ((success: boolean) => void)[] = [];

const processPendingRequests = (success: boolean): void => {
  pendingRequests.forEach((callback) => callback(success));
  pendingRequests = [];
};

const addRequestToQueue = (): Promise<boolean> => {
  return new Promise((resolve) => {
    pendingRequests.push(resolve);
  });
};

const errorLink: ApolloLink = onError(
  ({ graphQLErrors, operation, forward }) => {
    const isAuthenticationError = isUnauthenticatedError({
      graphQLErrors,
    } as ApolloError);

    if (operation.operationName === "RefreshTokens") return;

    if (isAuthenticationError) {
      if (!isTokenRefreshing) {
        isTokenRefreshing = true;

        return fromPromise(
          AuthService.refreshToken()
            .then((accessToken) => {
              const success = !!accessToken;
              processPendingRequests(success);
              if (success) {
                // If token refresh was successful, retry the original request
                return forward(operation);
              } else {
                // Token refresh failed, throw an error with original error
                throw new ApolloError({ graphQLErrors });
              }
            })
            .catch((error) => {
              isTokenRefreshing = false;
              processPendingRequests(false); // Process pending requests even if token refresh fails
              throw new ApolloError(error);
            })
            .finally(() => {
              isTokenRefreshing = false;
            })
        ).flatMap((result) => result || Observable.of());
      } else {
        // If token is already refreshing, queue this request
        return fromPromise(
          addRequestToQueue().then((success) => {
            if (success) {
              // If the token was successfully refreshed, retry the original request
              return forward(operation);
            } else {
              // If there's no success after waiting, throw an error
              throw new Error("Failed to refresh token after waiting");
            }
          })
        ).flatMap((result) => result || Observable.of());
      }
    }

    // If it's not an authentication error or any other case
    return;
  }
);

export const apolloClient = new ApolloClient({
  uri: import.meta.env.VITE_GRAPHQL_URL,
  cache: new InMemoryCache(),
  link: from([errorLink, splitLink]),
  ssrForceFetchDelay: typeof window === "undefined" ? 100 : 0,
});

/**
 * Needed for signIn page, as because of onError link, unauthorized is handled with
 * access token refresh, which is not needed in case of sign In
 */
export const apolloClientWithoutErrorLink = new ApolloClient({
  uri: import.meta.env.VITE_GRAPHQL_URL,
  cache: new InMemoryCache(),
  link: from([splitLink]),
});
