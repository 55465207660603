import { useTranslations } from "use-intl";
import { Text } from ".";

const EmailShareButton = ({
  link,
  onClick,
  password,
}: {
  link: string;
  onClick?: () => void;
  password?: string;
}) => {
  const t = useTranslations("Album");
  const subject = t("emailAlbumShareSubject");
  const passwordMessage = password ? t("passwordShareMessage") + password : "";
  const body = t("emailAlbumShareBody") + link + "\n \n" + passwordMessage;
  const mailtoUrl = `mailto:?subject=${encodeURIComponent(
    subject
  )}&body=${encodeURIComponent(body)}`;

  return (
    <div
      onClick={() => {
        onClick && onClick();
        window.open(mailtoUrl);
      }}
      className="flex items-center gap-2 cursor-pointer"
    >
      <img className=" h-8 w-8" src="/email.png" />
      <Text size="xs">{t("email")}</Text>
    </div>
  );
};

export default EmailShareButton;
