import { useTranslations } from "use-intl";
import { Accordion, Text, Title } from "../../../common";
import { LocalizedLink } from "../../../router";

const Questions = () => {
  const t = useTranslations("PricingQuestions");

  return (
    <div id="faq" className="flex flex-col md:flex-row mt-14 gap-6">
      <div className="flex-1 ">
        <div className="md:px-0 px-4">
          <Title size="H5">{t("gotQuestions")}</Title>
          <Text className="mt-4">
            {t.rich("gotQuestionsDescription", {
              link: (chunk) => (
                <a
                  href="https://rompolo.com/en/contact-us"
                  className="underline cursor-pointer text-black"
                >
                  {chunk}
                </a>
              ),
            })}
          </Text>
        </div>
      </div>
      <div className="flex-1">
        <Accordion
          elements={[
            {
              title: t("howDataStorageWorks"),
              description: (
                <div>
                  <div>
                    <Text className="font-semibold">{t("choosePayOnce")}</Text>
                    <Text color="gray" className="mt-3">
                      {t("choosePayOnceDescription")}
                    </Text>
                  </div>
                  <div className="mt-6">
                    <Text className="font-semibold">
                      {t("chooseSubscription")}
                    </Text>
                    <Text color="gray" className="mt-3">
                      {t("chooseSubscriptionDescription")}
                    </Text>
                  </div>

                  <div className="mt-6 italic">
                    <Text color="gray" className="">
                      {t("note")}
                    </Text>
                  </div>
                </div>
              ),
            },
            {
              title: t("howToChoosePlan"),
              description: (
                <div className="text-medium-black">
                  {t.rich("howToChoosePlanDescription", {
                    ul: (chunk) => (
                      <ul className=" list-disc mt-4 *:ml-6">{chunk}</ul>
                    ),
                    li: (chunk) => <li>{chunk}</li>,
                  })}
                </div>
              ),
            },
            {
              title: t("howToChooseStorage"),
              description: (
                <div className="text-medium-black">
                  {t.rich("howToChooseStorageDescription", {
                    ul: (chunk) => (
                      <ul className=" list-disc mt-4 *:ml-6 space-y-2 mb-6">
                        {chunk}
                      </ul>
                    ),
                    li: (chunk) => <li>{chunk}</li>,
                    link: (chunk) => (
                      <LocalizedLink
                        to=""
                        className="underline cursor-pointer text-black"
                      >
                        {chunk}
                      </LocalizedLink>
                    ),
                  })}
                </div>
              ),
            },
            {
              title: t("changeQuality.title"),
              description: (
                <div>
                  <Text color="gray">{t("changeQuality.intro")}</Text>
                  <ul className=" list-disc *:ml-6 space-y-2 mt-2">
                    <li>
                      <Text color="gray">{t("changeQuality.one")}</Text>
                    </li>
                    <li>
                      <Text color="gray">{t("changeQuality.two")}</Text>
                    </li>
                    <li>
                      <Text color="gray">{t("changeQuality.three")}</Text>
                    </li>
                  </ul>
                  <Text color="gray" className="mt-2">
                    {t.rich("changeQuality.conclusion", {
                      b: (chunk) => <b>{chunk}</b>,
                    })}
                  </Text>
                </div>
              ),
            },
            {
              title: t("howToUpgradePlan.title"),
              description: t("howToUpgradePlan.description"),
            },
            {
              title: t("changeQuality.title"),
              description: (
                <div>
                  <Text color="gray">{t("changeQuality.intro")}</Text>
                  <ul className=" list-disc *:ml-6 space-y-2 mt-2">
                    <li>
                      <Text color="gray">{t("changeQuality.one")}</Text>
                    </li>
                    <li>
                      <Text color="gray">{t("changeQuality.two")}</Text>
                    </li>
                    <li>
                      <Text color="gray">{t("changeQuality.three")}</Text>
                    </li>
                  </ul>
                  <Text color="gray" className="mt-2">
                    {t.rich("changeQuality.conclusion", {
                      b: (chunk) => <b>{chunk}</b>,
                    })}
                  </Text>
                </div>
              ),
            },
            {
              title: t("howToUpgrade"),
              description: t("howToUpgradeDescription"),
            },
            {
              title: t("canICancel"),
              description: t("canICancelDescription"),
            },
          ]}
        />
      </div>
    </div>
  );
};

export default Questions;
