import { useLocale, useTranslations } from "use-intl";
import { DateInput, SelectInput, SettingsBlock } from "../../../common";
import { AdminUpdateAlbumInput } from "../../../types/album.type";
import { useFormikContext } from "formik";
import { Plan } from "../../../types/plan.enum";

const PlanSettings = ({
  handleFieldChange,
}: {
  handleFieldChange: (e: React.ChangeEvent<any> | string, value?: any) => void;
}) => {
  const t = useTranslations();
  const formik = useFormikContext<AdminUpdateAlbumInput>();
  const locale = useLocale();

  const plans: { value: Plan; label: string }[] = [
    { value: Plan.free, label: "Free" },
    { value: Plan.payOnce, label: "Pay-Once" },
  ];

  const maxSize: { value: string; label: string }[] = [
    { value: "0.1", label: "0.1GB" },
    { value: "10", label: "10GB" },
    { value: "20", label: "20GB" },
    { value: "30", label: "30GB" },
    { value: "40", label: "40GB" },
    { value: "50", label: "50GB" },
  ];

  return (
    <SettingsBlock
      title={t("AlbumSettings.planSettings")}
      description={t("AlbumSettings.planSettingsDescription")}
    >
      <div className="flex gap-6">
        <SelectInput
          onChange={(value) => handleFieldChange("plan", value)}
          value={formik.values.plan}
          data={plans}
          name="plan"
          label={t("AlbumSettings.plan")}
          error={formik.errors.plan}
        />
        <SelectInput
          onChange={(value) =>
            handleFieldChange("maxSize", parseInt(value ?? ""))
          }
          value={String(formik.values.maxSize)}
          data={maxSize}
          name="maxSize"
          label={t("AlbumSettings.plan")}
          error={formik.errors.maxSize}
        />
      </div>
      <DateInput
        locale={locale}
        id="expirationDate"
        name="expirationDate"
        onChange={(value) => handleFieldChange("expirationDate", value)}
        value={
          formik.values.expirationDate
            ? new Date(formik.values.expirationDate)
            : undefined
        }
        label={t("AlbumSettings.expirationDate")}
        error={formik.errors.expirationDate}
      />
    </SettingsBlock>
  );
};

export default PlanSettings;
