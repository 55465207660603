import { useLocation as useReactRouterLocation } from "react-router-dom";
import { LOCALES, Messages } from "../locales/Locales";

const useLocalizedLocation = () => {
  const location = useReactRouterLocation();
  const pathParts = location.pathname.split("/").filter(Boolean);
  const locale = pathParts[0] as keyof Messages;
  // Check if the first part of the path is a locale identifier (e.g., 'en')
  // You can expand this list based on the locales you support
  if (LOCALES.includes(locale)) {
    // Remove the locale part
    pathParts.shift();
  }

  // Reconstruct the pathname without the locale
  const localeAdjustedPathname = "/" + pathParts.join("/");

  return {
    ...location,
    pathname: localeAdjustedPathname,
  };
};

export default useLocalizedLocation;
