import { useEffect, useMemo, useState } from "react";
import { CheckIcon, Combobox, Loader, Text, useCombobox } from "@mantine/core";
import { Album } from "../types/album.type";
import { useLazyQuery } from "@apollo/client";
import { PaginatedType } from "../types/paginated.type";
import { allAlbumsQuery } from "../graphql/albumQueries";
import { OperationType } from "../types/operation-type.enum";
import { ErrorService } from "../services";
import { useTranslations } from "use-intl";
import { IconChevronDown } from "@tabler/icons-react";
import { Input } from ".";

/**
 * Component used for Admin access, to assign album to badge, do not use for user role access
 */
const AlbumSelectWithLoading = ({
  value,
  disabled,
  setValue,
}: {
  value?: Album;
  disabled?: boolean;
  mandatory?: boolean;
  setValue: (value: Album | undefined) => void;
  error?: string;
}) => {
  const t = useTranslations("Album");
  const [albums, setAlbums] = useState<Album[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [loadAlbums, { loading, error }] = useLazyQuery<
    { albums: PaginatedType<Album> },
    {
      first?: number;
      after?: string;
      id?: string;
      name?: string;
      operationType?: OperationType;
      shortId?: string;
    }
  >(allAlbumsQuery, {
    fetchPolicy: "no-cache",
  });

  /**
   * If value passed to component changes, update input value
   * This will be triggered when user selects a new option
   * As value is passed to component and saved also outside of component
   */
  useEffect(() => {
    if (value) {
      setSearchValue(value.name);
    } else {
      setSearchValue("");
    }
  }, [value]);

  useEffect(() => {
    if (error) {
      ErrorService.showError(t("errorLoadingAlbums"));
    }
  }, [error]);

  /**
   * Load albums when search value changes
   * To avoid unnecessary API calls, do not fetch if value is same as previous value
   */
  useEffect(() => {
    if (searchValue && searchValue.length > 0 && searchValue !== value?.name) {
      loadAlbums({
        variables: {
          id: searchValue,
          name: searchValue,
          operationType: OperationType.OR,
        },
      }).then((res) => {
        setAlbums(res.data?.albums?.nodes ?? []);
      });
    }
  }, [searchValue]);

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: (eventSource) => {
      if (eventSource === "keyboard") {
        combobox.selectActiveOption();
      } else {
        combobox.updateSelectedOptionIndex("active");
      }
    },
  });

  const options = useMemo(() => {
    return albums.map((item) => (
      <Combobox.Option
        value={item.id}
        key={item.id}
        active={item.id === value?.id}
      >
        <div className="flex gap-4">
          {value?.id.includes(item.id) ? (
            <CheckIcon color="gray" size={12} />
          ) : null}
          <div>
            <Text size="sm">{item.name}</Text>
          </div>
        </div>
      </Combobox.Option>
    ));
  }, [albums, value]);

  return (
    <Combobox
      store={combobox}
      onOptionSubmit={(value) => {
        setValue(albums ? albums.find((a) => a.id === value) : undefined);
        const albumName = albums
          ? albums.find((a) => a.id === value)?.name ?? ""
          : "";
        setSearchValue(albumName);
        combobox.updateSelectedOptionIndex("active");
        combobox.closeDropdown();
      }}
    >
      <Combobox.Target>
        <Input
          rightSection={
            loading ? (
              <Loader size={"xs"} />
            ) : (
              <IconChevronDown size={16} className=" stroke-black" />
            )
          }
          label={t("selectAlbum")}
          disabled={disabled}
          onClick={() => combobox.openDropdown()}
          onFocus={() => combobox.openDropdown()}
          onBlur={() => {
            combobox.closeDropdown();
            searchValue !== value?.name && value && setSearchValue(value?.name);
          }}
          placeholder="Search value"
          value={searchValue}
          onChange={(event) => {
            combobox.updateSelectedOptionIndex();
            setSearchValue(event.currentTarget.value);
          }}
        />
      </Combobox.Target>

      <Combobox.Dropdown>
        <Combobox.Options>
          {options.length > 0 ? (
            options
          ) : (
            <Combobox.Empty>Nothing found</Combobox.Empty>
          )}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
};

export default AlbumSelectWithLoading;
