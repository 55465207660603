import { useTranslations } from "use-intl";
import { Text } from "../../../common";
import { LocalizedLink } from "../../../router";

const NoPhotos = () => {
  const t = useTranslations("Photos");

  return (
    <div
      id="no-photos"
      className="flex-1 flex py-[91px] justify-center items-center px-4 md:px-0 bg-white rounded-xl mt-6"
    >
      <Text className="md:max-w-[500px]  text-center" color="gray">
        {t.rich("noPhotos", {
          sharing: (chunks) => (
            <LocalizedLink className="underline text-black" to="/">
              {chunks}
            </LocalizedLink>
          ),
        })}
      </Text>
    </div>
  );
};

export default NoPhotos;
