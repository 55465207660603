import imageCompression from "browser-image-compression";

export const compressImage = async (file: File): Promise<Blob> => {
  console.log("originalFile instanceof Blob", file instanceof Blob); // true
  console.log(`originalFile size ${file.size / 1024 / 1024} MB`);

  const options = {
    maxWidthOrHeight: 2560,
    useWebWorker: true,
    maxIteration: 1,
    preserveExif: true,
  };
  try {
    const compressedFile = await imageCompression(file, options);
    console.log(
      "compressedFile instanceof Blob",
      compressedFile instanceof Blob
    );
    console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
    return compressedFile;
  } catch (error) {
    console.log(error);
    throw new Error("Error during image compression");
  }
};
