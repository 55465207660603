import { useTranslations } from "use-intl";
import { ActivePlan, PlanCard } from ".";
import { BadgeProduct } from "../../../config/pricing.config";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { Album } from "../../../types/album.type";
import { Plan } from "../../../types/plan.enum";

export type PlanConfig = {
  term: number;
  productNamespace: string;
  plan: string;
  badgeText?: string;
  priceDescription: string;
  planDescription: string;
  included: {
    title: string;
    details?: any;
  }[];
  storageOptions: number[];
  discountedPrice?: number;
  defaultStorage?: number;
  popular?: number;
};

const Plans = ({
  purchase,
  loading,
}: {
  purchase: (prices: string[]) => void;
  loading: boolean;
}) => {
  const album = useSelector((state: RootState) => state.album.album) as Album;

  const t = useTranslations("Pricing");

  const onPurchase = (priceId: string, withBadge?: boolean) => {
    const productsToBuy = [priceId];
    if (withBadge) {
      if (BadgeProduct?.priceId) {
        productsToBuy.push(BadgeProduct?.priceId);
      }
    }
    purchase(productsToBuy);
  };

  const planConfigs: PlanConfig[] = [
    {
      term: 6,
      productNamespace: "payOnce",
      plan: t("payOnce"),
      badgeText: t("mostPopular"),
      priceDescription: t("perAlbum"),
      planDescription: t("payOnceDescription"),
      included: [
        {
          title: t("unlimitedInvites"),
        },
        {
          title: t("savedFor6months"),
          details: t("storageTooltip6m"),
        },
      ],
      popular: 20,
      storageOptions: [10, 20, 30, 40, 50],
      defaultStorage: 20,
    },
    {
      term: 12,
      productNamespace: "subscription",
      plan: t("subscriptionPlan"),
      priceDescription: t("yearlyPerAlbum"),
      planDescription: t("subscriptionPlanDescription"),
      included: [
        {
          title: t("unlimitedInvites"),
        },
        {
          title: t("savedForSubscriptionDuratinon"),
          details: t("storageTooltipSubscription"),
        },
      ],
      storageOptions: [10, 20, 30, 40, 50],
    },
  ];

  const activePlan = planConfigs.find((p) => p.productNamespace === album.plan);
  const albumIsExpired =
    album.expirationDate && new Date(album?.expirationDate) < new Date();

  return (
    <div className="flex gap-6 md:flex-row flex-col">
      {album.plan === Plan.free || albumIsExpired
        ? planConfigs.map((plan, index) => (
            <PlanCard
              defaultStorage={plan.defaultStorage}
              popular={plan.popular}
              key={index}
              loading={loading}
              onPurchase={onPurchase}
              productNamespace={plan.productNamespace}
              plan={plan.plan}
              badgeText={plan.badgeText}
              priceDescription={plan.priceDescription}
              planDescription={plan.planDescription}
              included={plan.included}
              storageOptions={plan.storageOptions}
              discountedPrice={plan.discountedPrice}
            />
          ))
        : activePlan && (
            <ActivePlan
              loading={loading}
              purchase={purchase}
              album={album}
              planConfig={activePlan}
            />
          )}
    </div>
  );
};

export default Plans;
