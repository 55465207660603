import { useTranslations } from "use-intl";
import {
  Back,
  Button,
  CenteredBlockWrapper,
  Error,
  Input,
  Text,
  Title,
} from "../../common";
import { Formik } from "formik";
import * as Yup from "yup";
import { apolloClientWithoutErrorLink } from "../../lib/apolloClient";
import { useState } from "react";
import { forgotPasswordMutation } from "../../graphql/userQueries";
import { ErrorService } from "../../services";
import { useLocalizedNavigate } from "../../hooks";
import { Helmet } from "react-helmet-async";

const ForgotPasswordPage = () => {
  const t = useTranslations("ForgotPassword");
  const tV = useTranslations("Validation");
  const navigate = useLocalizedNavigate();

  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(false);

  return (
    <CenteredBlockWrapper
      footer={
        <Text className=" mt-10 text-center" color="gray">
          {t.rich("remembered", {
            link: (chunk) => (
              <span
                onClick={() => navigate("/signin")}
                className="underline cursor-pointer text-black"
              >
                {chunk}
              </span>
            ),
          })}
        </Text>
      }
    >
      <Helmet>
        <title>Rompolo - {t("forgotPassword")}</title>
      </Helmet>
      <Formik
        validateOnChange={false}
        enableReinitialize
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email(tV("invalidEmail"))
            .required(tV("required")),
        })}
        initialValues={{
          email: "",
        }}
        onSubmit={(values) => {
          setLoading(true);
          apolloClientWithoutErrorLink
            .mutate<
              { forgotPassword: boolean },
              {
                input: {
                  email: string;
                };
              }
            >({
              mutation: forgotPasswordMutation,
              variables: { input: values },
            })
            .then((resp) => {
              if (resp.data?.forgotPassword) {
                ErrorService.showMessage(t("forgotPasswordEmailSent"));
              } else {
                setError(t("errorSendingResetEmail"));
              }
            })
            .catch(() => {
              setError(t("errorSendingResetEmail"));
            })
            .finally(() => {
              setLoading(false);
            });
        }}
      >
        {(props) => {
          return (
            <div>
              <Back onClick={() => navigate(-1)} />
              <Title className="mt-6" size="H3">
                {t("forgotPassword")}
              </Title>
              <Text className="mt-2 mb-8" color="gray">
                {t("forgotPasswordDescription")}
              </Text>
              {error && <Error message={error} />}
              <Input
                name="email"
                onChange={props.handleChange}
                className="mb-4"
                placeholder={t("email")}
                error={props.errors.email}
              />
              <Button
                loading={loading}
                onClick={props.submitForm}
                fullWidth
                title={t("send")}
              />
            </div>
          );
        }}
      </Formik>
    </CenteredBlockWrapper>
  );
};

export default ForgotPasswordPage;
