import { Navigate, createBrowserRouter } from "react-router-dom";
import { LocaleProtectedRoutes } from ".";
import { applicationRoutes } from "./routes";
import React from "react";
import { ErrorPage } from "../pages";
import { DEFAULT_LOCALE, LOCALES, Messages } from "../locales/Locales";
import * as Sentry from "@sentry/react";
import { ErrorCatcher } from "../common";

// Get the locale from localStorage and ensure it's a valid key of Messages
const storedLocale = localStorage.getItem("locale") as keyof Messages | null;

const defaultLocale: keyof Messages =
  storedLocale && LOCALES.includes(storedLocale as keyof Messages)
    ? (storedLocale as keyof Messages)
    : DEFAULT_LOCALE;

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const router = sentryCreateBrowserRouter([
  {
    path: "/",
    element: <Navigate to={`/${defaultLocale}/`} />,
  },
  {
    path: "/:locale",
    element: <LocaleProtectedRoutes />,
    children: [
      {
        children: applicationRoutes().map((r) => {
          const RouteComponent = r.component;
          const WrapperComponent = r.wrapperComponent || React.Fragment;
          return {
            errorElement: <ErrorCatcher />,
            id: r.path,
            path: r.path,
            index: r.index,
            element: (
              <WrapperComponent>
                <RouteComponent />
              </WrapperComponent>
            ),
          };
        }),
      },
      {
        path: "*",
        element: <ErrorPage />,
      },
    ],
  },
]);
