import { FormikContextType } from "formik";
import { Album, CreateAlbumInput } from "../../../types/album.type";
import AlbumDetails from "../AlbumDetails";
import ImageMock from "../ImageMock";

const CircleStylePreview = ({
  album,
  formik,
  thumbnailUrl,
}: {
  album?: Album;
  formik: FormikContextType<CreateAlbumInput>;
  thumbnailUrl?: string;
}) => {
  return (
    <div
      style={{
        ...(formik.values.color === "#F7F7F7" && {
          backgroundColor: "#E0E0E0",
        }),
      }}
      className="flex flex-col justify-center flex-1 md:py-14 py-10 px-5 mb-4 bg-light-gray max-h-[250px] rounded-lg"
    >
      <div
        className="md:w-auto w-[100px] mx-auto md:mx-0"
        style={{ backgroundColor: formik.values.color }}
      >
        <div className="md:w-[220px] flex flex-col md:flex-row md:h-20 items-center md:px-4 md:mx-auto">
          <img
            src={thumbnailUrl ?? "/gradient-pattern.webp"}
            alt={album?.name}
            className=" w-[50px] h-[50px] object-cover rounded-full md:my-0 my-2"
          />
          <div className="flex-1 flex justify-center">
            <div className=" max-w-[80px]">
              <AlbumDetails album={album} />
            </div>
          </div>
        </div>

        <div className="flex gap-1 py-2 md:px-4 px-1">
          <ImageMock formik={formik} />
        </div>
      </div>
    </div>
  );
};

export default CircleStylePreview;
