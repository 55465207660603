import { Loader, Tooltip } from "@mantine/core";

interface IconProps {
  icon: React.ElementType;
  onClick: () => void;
  className: string;
  width: number;
  height: number;
  tooltip?: string;
  loading?: boolean;
}

interface IconsProps {
  icons: IconProps[];
}

const ActionIcons: React.FC<IconsProps> = ({ icons }) => {
  return (
    <>
      {icons.map((icon, index) => (
        <Tooltip key={index} label={icon.tooltip} disabled={!icon.tooltip}>
          <div
            onClick={icon.onClick}
            className={`p-2 rounded-sm ${icon.className} cursor-pointer`}
          >
            {icon.loading ? (
              <Loader size={icon.width} color={"white"} />
            ) : (
              <icon.icon
                className={icon.className}
                width={icon.width}
                height={icon.height}
              />
            )}
          </div>
        </Tooltip>
      ))}
    </>
  );
};

export default ActionIcons;
