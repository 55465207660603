import { useRouteError } from "react-router-dom";
import { CenteredBlockWrapper, Text, Title } from ".";
import { useTranslations } from "use-intl";
import * as Sentry from "@sentry/react";

const ErrorBoundary = () => {
  const t = useTranslations("Error");
  let error = useRouteError() as Error;

  if (error) {
    Sentry.captureException(error); // Send to Sentry
  }

  return (
    <CenteredBlockWrapper>
      <Title size="H5">{t("somethingHappened")}</Title>
      <Text className="mt-4 mb-4">{t("weAreInformed")}</Text>
    </CenteredBlockWrapper>
  );
};

export default ErrorBoundary;
