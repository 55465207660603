import { useEffect, useRef, useState } from "react";
import { Tooltip } from "@mantine/core";
import { Text } from ".";

const TextWithTooltip = ({ text }: { text: string }) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [hasClamp, setHasClamp] = useState(false);

  useEffect(() => {
    if (contentRef.current) {
      if (contentRef.current.scrollHeight > contentRef.current.clientHeight) {
        setHasClamp(true);
      } else {
        setHasClamp(false);
      }
    }
  }, [contentRef, text]);

  return hasClamp ? (
    <Tooltip
      position="right-start"
      multiline
      w={250}
      label={text?.length > 300 ? text.slice(0, 300) + "..." : text}
      withArrow
    >
      <Text size="sm" className=" line-clamp-1" ref={contentRef}>
        {text}
      </Text>
    </Tooltip>
  ) : (
    <Text size="sm" className=" line-clamp-1" ref={contentRef}>
      {text}
    </Text>
  );
};

export default TextWithTooltip;
