import { FormikContextType } from "formik";
import { Album, CreateAlbumInput } from "../../../types/album.type";
import ImageMock from "../ImageMock";
import AlbumDetails from "../AlbumDetails";

const CardStylePreview = ({
  album,
  formik,
  thumbnailUrl,
}: {
  album?: Album;
  formik: FormikContextType<CreateAlbumInput>;
  thumbnailUrl?: string;
}) => {
  return (
    <div
      style={{
        ...(formik.values.color === "#F7F7F7" && {
          backgroundColor: "#E0E0E0",
        }),
      }}
      className="flex flex-col justify-center flex-1 md:py-14 py-10 px-5 mb-4 bg-light-gray max-h-[250px] rounded-lg"
    >
      <div
        className="md:w-auto w-[100px] mx-auto md:mx-0"
        style={{ backgroundColor: formik.values.color }}
      >
        <div className="flex md:w-[190px] flex-col-reverse md:flex-row justify-between pt-4 md:pb-4 md:mx-auto">
          <div className="flex items-center justify-start text-left flex-1">
            <div className=" md:w-[70px] md:pt-0 pt-3 px-1 mx-auto md:mx-0">
              <AlbumDetails album={album} />
            </div>
          </div>
          <div className="flex-1 flex items-center justify-center">
            <img
              src={thumbnailUrl ?? "/gradient-pattern.webp"}
              alt={album?.name}
              className=" max-w-[70px] max-h-[60px] object-contain rounded-sm rotate-6"
            />
          </div>
        </div>
        <div className="flex gap-1 py-2 md:px-4 px-1">
          <ImageMock formik={formik} />
        </div>
      </div>
    </div>
  );
};

export default CardStylePreview;
