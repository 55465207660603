import React, { useRef } from "react";
import {
  motion,
  AnimatePresence,
  useMotionValue,
  useTransform,
  PanInfo,
} from "framer-motion";
import CustomScrollArea from "./CustomScrollArea";

const BottomToolbar = ({
  children,
  onClose,
  opened,
}: {
  children: React.ReactNode;
  onClose: () => void;
  opened: boolean;
}) => {
  const y = useMotionValue(window.innerHeight);
  const opacity = useTransform(y, [0, window.innerHeight], [0.5, 0]);
  const scale = useTransform(y, [0, window.innerHeight], [1, 0.9]);
  const containerRef = useRef<HTMLDivElement>(null);
  const dragHandleRef = useRef<HTMLDivElement>(null);

  const backdropVariants = {
    visible: { opacity: 0.5 },
    hidden: { opacity: 0 },
  };

  const toolbarVariants = {
    visible: {
      y: 0,
      transition: {
        type: "spring",
        damping: 40,
        stiffness: 400,
      },
    },
    hidden: {
      y: window.innerHeight,
      transition: {
        type: "spring",
        damping: 40,
        stiffness: 400,
      },
    },
  };

  const handleDrag = (
    _: MouseEvent | TouchEvent | PointerEvent,
    info: PanInfo
  ) => {
    y.set(Math.max(0, info.offset.y));
  };

  const handleDragEnd = (
    _: MouseEvent | TouchEvent | PointerEvent,
    info: PanInfo
  ) => {
    const threshold = window.innerHeight * 0.3;
    if (info.offset.y > threshold) {
      onClose();
    } else {
      y.set(0);
    }
  };

  return (
    <AnimatePresence>
      {opened && (
        <>
          <motion.div
            key="backdrop"
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={backdropVariants}
            onClick={(e) => {
              onClose();
              e.stopPropagation();
            }}
            className="fixed inset-0 bg-black z-[45]"
            style={{ opacity }}
          />
          <motion.div
            key="toolbar"
            ref={containerRef}
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={toolbarVariants}
            style={{ y, scale }}
            className="fixed z-[100] left-0 bg-white rounded-t-xl bottom-0 w-full"
          >
            <div className="absolute left-0 right-0 mx-auto h-2 w-9 bg-medium-black/30 mt-2 rounded-full -z-10"></div>
            <motion.div
              dragSnapToOrigin
              ref={dragHandleRef}
              drag="y"
              dragElastic={0.0}
              dragConstraints={containerRef}
              onDrag={handleDrag}
              onDragEnd={handleDragEnd}
              className="h-8 w-full flex justify-center items-center z-10"
            ></motion.div>
            <CustomScrollArea
              classNames={{
                viewport: "max-h-[calc(95dvh-24px)] flex flex-col p-4 pt-0",
              }}
            >
              {children}
            </CustomScrollArea>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
};

export default BottomToolbar;
