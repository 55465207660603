import { motion } from "framer-motion";
import { Image } from "../../../types/image.type";
import React, { useEffect, useMemo, useState } from "react";
import { getImageUrl } from "../../../lib/utils";

const AnimatedThumbnail = ({
  image,
  onComplete,
}: {
  image: Image;
  onComplete: (image: Image) => void; // Callback to notify when the animation is complete
}) => {
  const thumbnailUrl = getImageUrl(image.thumbnailUrl);
  const [animationCompleted, setAnimationCompleted] = useState(false);

  useEffect(() => {
    if (animationCompleted) {
      // Delay calling onComplete to ensure it happens after the exit animation
      const timeoutId = setTimeout(() => onComplete(image), 1000); // Adjust delay as needed
      return () => clearTimeout(timeoutId);
    }
  }, [animationCompleted, image, onComplete]);

  const rand = useMemo(() => Math.random(), []);
  const xPosStart = useMemo(
    () => (rand < 0.5 ? rand * 20 : 80 + rand * 10),
    []
  );
  const xPosVariation = useMemo(() => (Math.random() < 0.5 ? -2 : 2), []); // Randomly choose between -2 and 2
  const xPosEnd = useMemo(() => xPosStart + xPosVariation, []);

  return (
    <motion.div
      key={image.id}
      initial={{
        y: "100vh",
        x: xPosStart + "vw",
        scale: 1,
        opacity: 1,
      }}
      animate={{
        y: "-30vh",
        x: xPosEnd + "vw",
        scale: 0.1,
        opacity: 0,
      }}
      transition={{
        duration: 5,
      }}
      onAnimationComplete={() => setAnimationCompleted(true)}
      className="rounded-full"
      style={{
        zIndex: 45,
        width: 120,
        height: 120,
        backgroundImage: `url(${thumbnailUrl})`,
        backgroundSize: "cover",
        position: "absolute",
      }}
    />
  );
};

export default React.memo(AnimatedThumbnail);
