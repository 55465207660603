import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useTranslations } from "use-intl";
import Title from "../Title";
import Text from "../Text";
import { IconChevronRight, IconX } from "@tabler/icons-react";
import { useDispatch, useSelector } from "react-redux";
import { closeHowTo } from "../../store/howto.reducer";
import { RootState } from "../../store";
import Back from "../Back";
import { motion, AnimatePresence } from "framer-motion";
import RoundedBox from "../RoundedBox";
import YouTubeVideo from "./YoutubeVideo";
import ResponsiveContainer from "../ResponsiveContainer";

// Individual HowTo components
const HowToCable = ({ t }: { t: any }) => {
  return (
    <div className="">
      <Title size="H3">{t("cable.title")}</Title>
      <Text className="mt-6">{t("cable.description")}</Text>
      <ul className=" list-decimal mt-4 space-y-4 *:ml-6">
        <li>
          <Text className="">{t("cable.one")}</Text>
        </li>
        <li>
          <Text className="">{t("cable.two")}</Text>
        </li>
        <li>
          <Text className="">{t("cable.three")}</Text>
        </li>
      </ul>
      <Text className="mt-4 font-semibold">{t("proTips")}</Text>
      <ul className="*:ml-6 list-disc mt-4 space-y-4">
        <li>
          <Text className="">{t("cable.tipOne")}</Text>
        </li>
        <li>
          <Text className="">{t("cable.tipTwo")}</Text>
        </li>
      </ul>
      <div className="mt-4">
        <Text>{t("cable.checkVideoBelow")}</Text>
      </div>
      <YouTubeVideo videoId="g9xxQSr5Ts8" />
      <div className="mt-4">
        <Text>{t("cable.checkVideoBelowHdmi")}</Text>
      </div>
      <YouTubeVideo videoId="rkksybDrtkw" />
    </div>
  );
};

const HowToApple = ({ t }: { t: any }) => {
  return (
    <div className="">
      <Title size="H3">{t("apple.title")}</Title>
      <Text className="mt-6">{t("apple.description")}</Text>
      <Text className="font-semibold mt-6">{t("apple.forMac")}</Text>
      <ul className=" list-decimal mt-4 space-y-4 *:ml-6">
        <li>
          <Text>{t("apple.onAppleTv")}</Text>
          <ul className=" list-disc mt-4 space-y-4 *:ml-6">
            <li>
              <Text className="">{t("apple.onAppleTvOne")}</Text>
            </li>
            <li>
              <Text className="">{t("apple.onAppleTvTwo")}</Text>
            </li>
          </ul>
        </li>
        <li>
          <Text>{t("apple.onMac")}</Text>
          <ul className=" list-disc mt-4 space-y-4 *:ml-6">
            <li>
              <Text className="">{t("apple.onMacOne")}</Text>
            </li>
            <li>
              <Text className="">{t("apple.onMacTwo")}</Text>
            </li>
            <li>
              <Text className="">{t("apple.onMacThree")}</Text>
            </li>
          </ul>
        </li>
      </ul>
      <Text className="mt-4">{t("apple.willAppearMac")}</Text>

      <YouTubeVideo videoId="1cZfomu5iIM" />

      <Text className="font-semibold mt-6">{t("apple.forWindows")}</Text>
      <ul className=" list-decimal mt-4 space-y-4 *:ml-6">
        <li>
          <Text>{t("apple.onAppleTv")}</Text>
          <ul className=" list-disc mt-4 space-y-4 *:ml-6">
            <li>
              <Text className="">{t("apple.onAppleTvOne")}</Text>
            </li>
            <li>
              <Text className="">{t("apple.onAppleTvTwo")}</Text>
            </li>
          </ul>
        </li>
        <li>
          <Text>{t("apple.onWindows")}</Text>
          <ul className=" list-disc mt-4 space-y-4 *:ml-6">
            <li>
              <Text className="">{t("apple.onWindowsOne")}</Text>
            </li>
            <li>
              <Text className="">{t("apple.onWindowsTwo")}</Text>
            </li>
          </ul>
        </li>
      </ul>
      <Text className="mt-4">{t("apple.willAppearWindows")}</Text>

      <Text className="mt-6 font-semibold">{t("proTips")}</Text>
      <ul className=" list-disc mt-4 space-y-4 *:ml-6">
        <li>
          <Text className="">{t("apple.proTipOne")}</Text>
        </li>
        <li>
          <Text className="">{t("apple.proTipTwo")}</Text>
        </li>
        <li>
          <Text className="">{t("apple.proTipThree")}</Text>
        </li>
        <li>
          <Text className="">{t("apple.proTipFour")}</Text>
        </li>
      </ul>
    </div>
  );
};

const HowToSmart = ({ t }: { t: any }) => {
  return (
    <div className="">
      <Title size="H3">{t("smart.title")}</Title>
      <Text className="mt-6">{t("smart.description")}</Text>
      <Text className="font-semibold mt-5">{t("smart.optionOne")}</Text>
      <ul className=" list-decimal mt-4 space-y-4 *:ml-6">
        <li>
          <Text>{t("smart.smartOne")}</Text>
        </li>
        <li>
          <Text>{t("smart.smartTwo")}</Text>
        </li>
        <li>
          <Text>{t("smart.smartThree")}</Text>
        </li>
      </ul>
      <Text className="mt-4">{t("smart.smartNote")}</Text>
      <Text className="font-semibold mt-6">{t("smart.optionTwo")}</Text>
      <ul className=" list-decimal mt-4 space-y-4 *:ml-6">
        <li>
          <Text>{t("smart.castOne")}</Text>
        </li>
        <li>
          <Text>{t("smart.castTwo")}</Text>
        </li>
      </ul>
      <Text className="mt-4">{t("smart.checkVideo")}</Text>
      <YouTubeVideo videoId="1cZfomu5iIM" />
    </div>
  );
};

const HowToLink = ({
  children,
  onClick,
}: {
  children: React.ReactNode;
  onClick: () => void;
}) => {
  return (
    <div
      className="p-4 bg-light-gray rounded-xl flex justify-between gap-4 cursor-pointer md:hover:translate-x-2 transition-all"
      onClick={onClick}
    >
      {children}
      <IconChevronRight size={24} className="stroke-medium-black" />
    </div>
  );
};

// HowItWorksBody Component
const HowItWorksBody = ({
  t,
  view,
  setView,
  close,
  scrolltotop,
}: {
  t: any;
  view: string;
  setView: (view: string) => void;
  close: () => void;
  scrolltotop?: () => void;
}) => {
  const [height, setHeight] = useState("auto");
  const contentRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const header = 50;
    if (contentRef.current) {
      setHeight(`${contentRef.current.scrollHeight + header}px`);
    }
  }, [view]);

  useEffect(() => {
    scrolltotop && scrolltotop();
  }, [height]);

  const getViewContent = () => {
    switch (view) {
      case "cable":
        return <HowToCable t={t} />;
      case "apple":
        return <HowToApple t={t} />;
      case "smart":
        return <HowToSmart t={t} />;

      default:
        return (
          <div className="h-full">
            <Title size="H3">{t("title")}</Title>
            <Text className="mt-2" color="gray">
              {t("description")}
            </Text>
            <img
              className="rounded-lg md:w-[300px] h-[220px] md:h-[190px] mt-6 w-full object-contain"
              src="/how-to/photowall.png"
              alt={t("title")}
            />
            <div className="flex flex-col gap-2 pt-10">
              <Title size="H4">{t("howToConnect")}</Title>
              <Text color="gray">{t("howToConnectDescription")}</Text>
            </div>
            <div className="mt-6 flex flex-col gap-4">
              <HowToLink onClick={() => setView("cable")}>
                <Text className="font-semibold">{t("cable.title")}</Text>
              </HowToLink>
              <HowToLink onClick={() => setView("apple")}>
                <Text className="font-semibold">{t("apple.title")}</Text>
              </HowToLink>
              <HowToLink onClick={() => setView("smart")}>
                <Text className="font-semibold">{t("smart.title")}</Text>
              </HowToLink>
            </div>
            <RoundedBox color="light-beige" className="mt-6">
              <Text>{t("tip")}</Text>
            </RoundedBox>
            <div className="flex flex-col gap-2 mt-10">
              <Title size="H4">{t("howToCustomize")}</Title>
              <Text color="gray">{t("howToCustomizeDescription")}</Text>
            </div>
          </div>
        );
    }
  };

  const motionVariants = {
    initial: (direction: number) => ({
      x: direction > 0 ? "100%" : "-100%",
      opacity: 0,
    }),
    animate: { x: 0, opacity: 1 },
    exit: (direction: number) => ({
      x: direction > 0 ? "-100%" : "100%",
      opacity: 0,
    }),
  };

  return (
    <div style={{ height }} className="overflow-hidden transition-all relative">
      <div className="flex md:mb-6 mb-4">
        {view !== "main" && <Back onClick={() => setView("main")} />}
        <div className="flex-1 justify-end flex">
          <IconX
            size={24}
            className="stroke-black cursor-pointer"
            onClick={close}
          />
        </div>
      </div>
      <AnimatePresence initial={false} custom={view !== "main" ? 1 : -1}>
        <motion.div
          ref={contentRef}
          key={view}
          custom={view !== "main" ? 1 : -1}
          variants={motionVariants}
          initial="initial"
          animate="animate"
          className="absolute"
          exit="exit"
          transition={{ duration: 0.3 }}
        >
          {getViewContent()}
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

// Main HowItWorks component
const HowItWorksPhotoWall = () => {
  const dispatch = useDispatch();
  const howToOpened = useSelector((state: RootState) => state.howto.opened);
  const opened = howToOpened === "photowall";
  const t = useTranslations("HowItWorks.PhotoWall");
  const close = () => dispatch(closeHowTo());
  const [view, setView] = useState("main");

  return (
    <ResponsiveContainer opened={opened} close={close}>
      <HowItWorksBody t={t} view={view} setView={setView} close={close} />
    </ResponsiveContainer>
  );
};

export default HowItWorksPhotoWall;
