import { useTranslations } from "use-intl";
import { Text } from ".";

const FacebookShareButton = ({
  link,
  onClick,
}: {
  link: string;
  onClick?: () => void;
}) => {
  const t = useTranslations("Album");
  const encodedLink = encodeURIComponent(link);
  const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodedLink}`;

  return (
    <div
      onClick={() => {
        onClick && onClick();
        window.open(facebookUrl, "_blank");
      }}
      className="flex items-center gap-2  cursor-pointer"
    >
      <img
        className="h-8 w-8"
        src="/facebook.png" // Make sure to have a Facebook icon at this path
        alt={t("facebook")}
      />
      <Text size="xs">{t("facebook")}</Text>
    </div>
  );
};

export default FacebookShareButton;
