import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslations } from "use-intl";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { useMutation, useQuery } from "@apollo/client";
import { Badge, ScrollArea, Tabs } from "@mantine/core";
import {
  IconArtboard,
  IconBusinessplan,
  IconLockSquare,
  IconPresentation,
  IconSettings2,
} from "@tabler/icons-react";

import {
  Button,
  Loading,
  Text,
  Title,
  PrivacySettings,
  GeneralSettings,
  SlideShowSettings,
  StyleSettings,
} from "../../common";
import { PlanSettings } from "./components";
import { AdminUpdateAlbumInput, Album, Layout } from "../../types/album.type";
import { EventType } from "../../types/event.enum";
import {
  adminUpdateAlbumMutation,
  findAlbumByShortIdQuery,
} from "../../graphql/albumQueries";
import { ErrorService } from "../../services";
import { COLORS } from "../../common/AlbumColorPicker";
import {
  useConfirmNavigationWithSave,
  useLocalizedLocation,
  useLocalizedNavigate,
} from "../../hooks";
import { Plan } from "../../types/plan.enum";

const TABS = [
  { value: "general", label: "AlbumSettings.general", icon: IconSettings2 },
  { value: "style", label: "AlbumSettings.style", icon: IconArtboard },
  { value: "privacy", label: "AlbumSettings.privacy", icon: IconLockSquare },
  {
    value: "slideshow",
    label: "AlbumSettings.slideShowSettings",
    icon: IconPresentation,
  },
  { value: "plan", label: "AlbumSettings.plan", icon: IconBusinessplan },
];

const AdminAlbumSettingsPage: React.FC = () => {
  const t = useTranslations();
  const { shortId } = useParams<{ shortId: string }>();
  const [album, setAlbum] = useState<Album | undefined>();
  const location = useLocalizedLocation();
  const navigate = useLocalizedNavigate();
  const [modifiedTabs, setModifiedTabs] = useState<Set<string>>(new Set());

  const albumFromNavigate = location.state?.album;

  const {
    loading: albumLoading,
    error: albumError,
    data: { album: albumFromServer } = {},
  } = useQuery<{ album: Album }, { shortId: string }>(findAlbumByShortIdQuery, {
    variables: { shortId: shortId ?? "" },
    skip: !!albumFromNavigate || !shortId,
    fetchPolicy: "no-cache",
  });

  const [updateAlbum, { loading: updateLoading }] = useMutation<
    { adminUpdateAlbum: Album },
    { input: AdminUpdateAlbumInput }
  >(adminUpdateAlbumMutation);

  useEffect(() => {
    if (albumFromNavigate) {
      setAlbum(albumFromNavigate);
    } else if (albumFromServer) {
      setAlbum(albumFromServer);
    }
  }, [albumFromNavigate, albumFromServer]);

  useEffect(() => {
    if (albumError) ErrorService.showError(t("Album.errorOnAlbumUpdate"));
  }, [albumError, t]);

  const handleSubmit = (values: AdminUpdateAlbumInput) => {
    updateAlbum({ variables: { input: values } })
      .then((response) => {
        if (response.data?.adminUpdateAlbum) {
          setAlbum(response.data.adminUpdateAlbum);
          ErrorService.showMessage(t("Album.successUpdate"));
          setModifiedTabs(new Set());
          navigate(location.pathname, {
            state: { album: response.data.adminUpdateAlbum },
            replace: true,
          });
        }
      })
      .catch(() => {
        ErrorService.showError(t("Album.errorOnAlbumUpdate"));
      });
  };

  const handleFieldChange =
    (
      handleChange: (e: React.ChangeEvent<any>) => void,
      setFieldTouched: (
        field: string,
        isTouched?: boolean,
        shouldValidate?: boolean
      ) => void,
      tab: string,
      setFieldValue?: (
        field: string,
        value: any,
        shouldValidate?: boolean
      ) => void
    ) =>
    (e: React.ChangeEvent<any> | any, value?: any) => {
      if (e && e.target) {
        handleChange(e);
        setFieldTouched(e.target.name, true, false);
      } else if (setFieldValue) {
        setFieldValue(e, value);
        setFieldTouched(e, true, false);
      }
      setModifiedTabs((prev) => new Set(prev).add(tab));
    };

  if (albumLoading) {
    return <Loading />;
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .max(200, t("Validation.tooLong"))
      .required(t("Validation.required")),
    password: Yup.string().nullable(),
    color: Yup.string().required(t("Validation.required")),
    description: Yup.string().max(2000, t("Validation.tooLong")),
    public: Yup.boolean().required(t("Validation.required")),
    type: Yup.string()
      .oneOf(Object.values(EventType), t("Validation.invalidType"))
      .required(t("Validation.required")),
    layout: Yup.string()
      .oneOf(Object.values(Layout), t("Validation.invalidType"))
      .required(t("Validation.required")),
    requireApproval: Yup.boolean(),
    eventDate: Yup.date().nullable(),
    yearsFrom: Yup.date().when("type", {
      is: (value: EventType) => value === EventType.MEMORIAL,
      then: (schema) => schema.required(t("Validation.required")),
    }),
    yearsTo: Yup.date().when("type", {
      is: (value: EventType) => value === EventType.MEMORIAL,
      then: (schema) =>
        schema
          .required(t("Validation.required"))
          .min(
            Yup.ref("yearsFrom"),
            t("Validation.yearsToMustBeAfterYearsFrom")
          ),
    }),
    videoDuration: Yup.number().required(t("Validation.required")),
    imageDuration: Yup.number().required(t("Validation.required")),
    displayCaption: Yup.boolean().required(t("Validation.required")),
    displayContributor: Yup.boolean().required(t("Validation.required")),
    wallDisplayCode: Yup.boolean().required(t("Validation.required")),
    wallDisplayCaptions: Yup.boolean().required(t("Validation.required")),
    displayMoreActionsButton: Yup.boolean(),
    displayPhotowallButton: Yup.boolean(),
    removeBranding: Yup.boolean(),
    showLogoOnAlbum: Yup.boolean(),
  });

  const initialValues: AdminUpdateAlbumInput = {
    id: album?.id ?? "",
    name: album?.name ?? "",
    description: album?.description ?? "",
    color: album?.color ?? COLORS["light-gray"],
    bannerColor: album?.bannerColor ?? COLORS["light-beige"],
    public: album?.public ?? true,
    type: album?.type ?? EventType.OTHER,
    password: album?.password ?? undefined,
    requireApproval: album?.requireApproval ?? false,
    yearsFrom: album?.yearsFrom ?? undefined,
    yearsTo: album?.yearsTo ?? undefined,
    videoDuration: album?.videoDuration ?? 8,
    imageDuration: album?.imageDuration ?? 5,
    displayCaption: album?.displayCaption ?? true,
    displayContributor: album?.displayContributor ?? true,
    eventDate: album?.eventDate ?? undefined,
    layout: album?.layout ?? Layout.DOUBLE,
    wallDisplayCode: album?.wallDisplayCode ?? true,
    wallDisplayCaptions: album?.wallDisplayCaptions ?? true,
    plan: album?.plan ?? Plan.free,
    expirationDate: album?.expirationDate ?? undefined,
    maxSize: album?.maxSize ?? undefined,
    displayMoreActionsButton: album?.displayMoreActionsButton ?? true,
    displayPhotowallButton: album?.displayPhotowallButton ?? true,
    removeBranding: album?.removeBranding ?? false,
    showLogoOnAlbum: album?.showLogoOnAlbum ?? false,
    passwordInInvite: album?.passwordInInvite ?? false,
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnChange={false}
      enableReinitialize
    >
      {(props: FormikProps<AdminUpdateAlbumInput>) => {
        useConfirmNavigationWithSave(props.dirty, props.submitForm);

        return (
          <div className="flex-1 md:p-6 flex flex-col max-w-[1000px]">
            <div className="md:mb-6 mb-4 md:px-0 px-4">
              <Title size="H4">{t("AlbumSettings.albumSettings")}</Title>
              <Text className="mt-2" color="gray">
                {t("AlbumSettings.albumSettingsDescription")}
              </Text>
            </div>
            <Tabs
              color="#222"
              classNames={{
                list: "px-4 md:px-0 flex-nowrap md:justify-center",
              }}
              radius="md"
              defaultValue="general"
            >
              <ScrollArea offsetScrollbars>
                <Tabs.List grow>
                  {TABS.map((tab) => (
                    <Tabs.Tab
                      key={tab.value}
                      value={tab.value}
                      leftSection={
                        <tab.icon className="stroke-black" size={16} />
                      }
                    >
                      <div className="flex gap-2 items-center">
                        <Text wrap="nowrap">{t(tab.label)}</Text>
                        {modifiedTabs.has(tab.value) && (
                          <Badge color="red" size="xs">
                            {t("AlbumSettings.modified")}
                          </Badge>
                        )}
                      </div>
                    </Tabs.Tab>
                  ))}
                </Tabs.List>
              </ScrollArea>
              <Tabs.Panel value="general">
                <GeneralSettings
                  album={album}
                  handleFieldChange={(e, value) =>
                    handleFieldChange(
                      props.handleChange,
                      props.setFieldTouched,
                      "general",
                      props.setFieldValue
                    )(e, value)
                  }
                />
              </Tabs.Panel>
              <Tabs.Panel value="style">
                <StyleSettings
                  album={album}
                  handleFieldChange={(e, value) =>
                    handleFieldChange(
                      props.handleChange,
                      props.setFieldTouched,
                      "style",
                      props.setFieldValue
                    )(e, value)
                  }
                />
              </Tabs.Panel>
              <Tabs.Panel value="privacy">
                <PrivacySettings
                  album={album}
                  handleFieldChange={(e, value) =>
                    handleFieldChange(
                      props.handleChange,
                      props.setFieldTouched,
                      "privacy",
                      props.setFieldValue
                    )(e, value)
                  }
                />
              </Tabs.Panel>
              <Tabs.Panel value="slideshow">
                <SlideShowSettings
                  album={album}
                  handleFieldChange={(e, value) =>
                    handleFieldChange(
                      props.handleChange,
                      props.setFieldTouched,
                      "slideshow",
                      props.setFieldValue
                    )(e, value)
                  }
                />
              </Tabs.Panel>
              <Tabs.Panel value="plan">
                <PlanSettings
                  handleFieldChange={(e, value) =>
                    handleFieldChange(
                      props.handleChange,
                      props.setFieldTouched,
                      "plan",
                      props.setFieldValue
                    )(e, value)
                  }
                />
              </Tabs.Panel>
            </Tabs>
            <div className="flex justify-end mt-6 md:px-0 px-4">
              <Button
                loading={updateLoading}
                onClick={props.submitForm}
                title={t("Album.save")}
              />
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

export default AdminAlbumSettingsPage;
