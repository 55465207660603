import { IconPlayerPlay } from "@tabler/icons-react";

const Play = ({
  className,
  onClick,
}: {
  className?: string;
  onClick?: (e: any) => void;
}) => {
  return (
    <div
      onClick={onClick}
      className={`absolute left-0 right-0 top-0 bottom-0 flex justify-center items-center ${
        className || ""
      } `}
    >
      <IconPlayerPlay
        onClick={onClick}
        width={40}
        color="white"
        height={40}
        fill="white"
        className="stroke-white"
      />
    </div>
  );
};

export default Play;
