import {
  Action,
  combineReducers,
  configureStore,
  createAction,
} from "@reduxjs/toolkit";
import userReducer from "./user.reducer";
import albumReducer from "./album.reducer";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import howtoReducer from "./howto.reducer";
import confettiReducer from "./confetti.reducer";

export { userReducer, albumReducer, howtoReducer, confettiReducer };

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["album"], // Only 'album' will be persisted
};

const combinedReducer = combineReducers({
  user: userReducer,
  album: albumReducer,
  howto: howtoReducer,
  confetti: confettiReducer,
});

export const resetState = createAction("resetState");

export const rootReducer = (
  state: ReturnType<typeof combinedReducer> | undefined,
  action: Action
) => {
  if (action.type === resetState.type) {
    // Reset the state to undefined to use the initial state of all reducers
    state = undefined;
  }
  return combinedReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: !import.meta.env.PROD,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["persist/PERSIST", "persist/REHYDRATE"], // Ignore specific actions
        ignoredPaths: ["register"], // Ignore specific paths in the state
      },
    }),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
