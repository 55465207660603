import { CellContext, ColumnDef } from "@tanstack/react-table";
import { Badge } from "../types/badge.type";
import { Text, TextWithTooltip } from "../common";
import { Album } from "../types/album.type";

export const badgeColumns: ColumnDef<Badge, any>[] = [
  {
    header: "Short Id",
    accessorKey: "shortId",
    cell: (props: CellContext<Badge, string>) => (
      <TextWithTooltip text={props.getValue()} />
    ),
  },
  {
    header: "URL",
    accessorKey: "shortId",
    cell: (props: CellContext<Badge, string>) => {
      const badgeUrl = `${window.location.origin}/badge/${props.getValue()}`;
      return <Text size="sm">{badgeUrl}</Text>;
    },
  },
  {
    header: "Payment ID",
    accessorKey: "paymentId",
    cell: (props: CellContext<Badge, string>) => (
      <Text size="sm">{`${
        props.getValue()?.length > 0 ? props.getValue() : "-"
      }`}</Text>
    ),
  },
  {
    header: "Customer",
    accessorKey: "customer",
    accessorFn: (data) => data.album,
    cell: (props: CellContext<Badge, Album>) => (
      <Text size="sm">{`${props.getValue()?.user?.email ?? "-"}`}</Text>
    ),
  },
  {
    header: "Album",
    accessorKey: "album",
    accessorFn: (data) => data.album,
    cell: (props: CellContext<Badge, Album>) => (
      <Text size="sm">{`${props.getValue()?.name ?? "-"}`}</Text>
    ),
  },
  {
    header: "Details",
    accessorKey: "details",
    cell: (props: CellContext<Badge, string>) => (
      <Text size="sm">{`${
        props.getValue()?.length > 0 ? props.getValue() : "-"
      }`}</Text>
    ),
  },
];
