import React, { useState, useEffect } from "react";
import Confetti from "react-confetti";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { showConfetti } from "../store/confetti.reducer";

const SnowFlakes: React.FC = () => {
  const show = useSelector(
    (rootState: RootState) => rootState.confetti.showConfetti
  );
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (!show) return null;

  return (
    <Confetti
      numberOfPieces={400}
      recycle={false}
      onConfettiComplete={() => {
        dispatch(showConfetti(false));
      }}
      width={windowSize.width}
      height={windowSize.height}
      gravity={0.1}
      wind={0.01}
    />
  );
};

export default SnowFlakes;
