import { useTranslations } from "use-intl";
import { Button, Text } from "..";
import { IEdgeType } from "../../types/paginated.type";
import { Image } from "../../types/image.type";
import { useEffect, useMemo, useState } from "react";
import {
  IconArchive,
  IconCheck,
  IconDownload,
  IconPhotoUp,
  IconTrash,
} from "@tabler/icons-react";
import { ActionIcons } from ".";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import useImageActions from "../../hooks/useImageActions";
import { ErrorService } from "../../services";
import { useDisclosure } from "@mantine/hooks";
import { Modal } from "@mantine/core";
import { getImageUrl } from "../../lib/utils";
import { useIsSmallScreen } from "../../hooks";

const Selected = ({
  selectedImages,
  allImages,
  selectImages,
  onUpdated,
  onRemoved,
  albumMode = false,
}: {
  selectedImages: string[];
  allImages: IEdgeType<Image>[];
  selectImages: (imageIds: string[]) => void;
  onUpdated: (image: Image[]) => void;
  onRemoved: (id: string[]) => void;
  albumMode?: boolean;
}) => {
  const t = useTranslations("Photos");
  const [selectedStyle, setSelectedStyle] = useState({});
  const { remove, unPublish, publish, approve } = useImageActions();
  const [loading, setLoading] = useState(false);
  const [opened, { open, close }] = useDisclosure(false);
  const isSmallScreen = useIsSmallScreen();

  const handleDownload = async () => {
    setLoading(true);
    const zip = new JSZip();
    const folder = zip.folder("images");

    // Create an array of promises for each image fetch
    const imagePromises = selectedImages.map((imageId) => {
      const image = allImages.find((img) => img.node.id === imageId);
      if (image) {
        const imageUrl = getImageUrl(image.node.url);
        return fetch(imageUrl + "?fetch-from-server")
          .then((response) => response.blob())
          .then((blob) => {
            const parts = image.node.url.split(".");
            const extension = parts[parts.length - 1];
            folder?.file(`${image.node.id}.${extension}`, blob);
          });
      }
      return Promise.resolve(); // Resolve for images not found
    });

    // Wait for all image fetches to complete
    await Promise.all(imagePromises).then(() => {
      zip.generateAsync({ type: "blob" }).then((content) => {
        saveAs(content, "images.zip");
      });
    });
    setLoading(false);
  };

  const handlePublish = () => {
    setLoading(true);
    let published = [] as Image[];
    Promise.all(
      selectedImages.map(async (imageId) => {
        const image = await publish(imageId);
        image && published.push(image);
      })
    )
      .then(() => {
        selectImages([]);
        onUpdated(published);

        ErrorService.showMessage(t("imagePublished"));
      })
      .catch(() => {
        ErrorService.showError(t("errorPublishing"));
      })
      .finally(() => setLoading(false));
  };

  const handleRemove = () => {
    setLoading(true);
    let removedIds = [] as string[];
    Promise.all(
      selectedImages.map(async (imageId) => {
        const removed = await remove(imageId);
        removed && removedIds.push(imageId);
      })
    )
      .then(() => {
        selectImages([]);
        onRemoved(removedIds);
        close();
      })
      .catch(() => {
        ErrorService.showError(t("errorRemoval"));
      })
      .finally(() => setLoading(false));
  };

  const handleUnPublish = async () => {
    setLoading(true);

    let unPublished = [] as Image[];
    Promise.all(
      selectedImages.map(async (imageId) => {
        const image = await unPublish(imageId);
        image && unPublished.push(image);
      })
    )
      .then(() => {
        selectImages([]);
        onUpdated(unPublished);
        ErrorService.showMessage(t("imageUnpublished"));
      })
      .catch(() => {
        ErrorService.showError(t("errorUnpublishing"));
      })
      .finally(() => setLoading(false));
  };

  const handleApprove = () => {
    setLoading(true);
    let approved = [] as Image[];
    Promise.all(
      selectedImages.map(async (imageId) => {
        const image = await approve(imageId);
        image && approved.push(image);
      })
    )
      .then(() => {
        selectImages([]);
        onUpdated(approved);

        ErrorService.showMessage(t("imageApproved"));
      })
      .catch(() => {
        ErrorService.showError(t("errorApproving"));
      })
      .finally(() => setLoading(false));
  };

  const handleSelectAll = () => {
    if (selectedImages.length === allImages.length) {
      selectImages([]);
    } else {
      selectImages(allImages.map((image) => image.node.id));
    }
  };

  const updateSelectedPosition = () => {
    const container = document.getElementById("image-grid");
    if (container) {
      const containerRect = container.getBoundingClientRect();
      const leftPosition = containerRect.left + containerRect.width / 2;

      if (isSmallScreen) {
        setSelectedStyle({
          width: window.innerWidth,
          left: 0,
          bottom: 0,
        });
      } else {
        setSelectedStyle({
          width: container.offsetWidth,
          left: `${leftPosition}px`,
          bottom: "56px",
          transform: "translateX(-50%)",
        });
      }
    }
  };

  useEffect(() => {
    window.addEventListener("resize", updateSelectedPosition);
    updateSelectedPosition();
    return () => {
      window.removeEventListener("resize", updateSelectedPosition);
    };
  }, []);

  // Memoized values for conditional rendering
  const notApproved = useMemo(
    () => allImages.some((image) => !image.node.approved),
    [allImages]
  );
  const notPublished = useMemo(
    () => allImages.some((image) => !image.node.published),
    [allImages]
  );
  const published = useMemo(
    () => allImages.some((image) => image.node.published),
    [allImages]
  );

  const iconSize = isSmallScreen ? 24 : 16;

  if (selectedImages.length === 0) return null;

  return (
    <div
      id="selector"
      style={selectedStyle}
      className="fixed z-10 md:h-11 py-2 md:py-0 w-full max-w-[800px] md:rounded-lg bg-black flex flex-row  items-center justify-between px-4"
    >
      <Modal
        radius={8}
        withCloseButton={false}
        opened={opened}
        onClose={close}
        centered
      >
        <Text color="gray">{t("confirmRemoval")}</Text>
        <div className="flex items-end justify-end gap-2 mt-6">
          <Button
            small
            type="outline"
            color="black"
            onClick={() => close()}
            title={t("cancel")}
          />
          <Button
            loading={loading}
            small
            onClick={handleRemove}
            color="red"
            title={t("remove")}
          />
        </div>
      </Modal>
      <div className="flex gap-2 md:flex-row flex-col">
        <Text size="sm" color="white">
          {t.rich("uploadsSelected", {
            selected: () => selectedImages.length,
            total: () => allImages.length,
          })}
        </Text>
        <Text
          size="sm"
          color="white"
          className="font-semibold cursor-pointer"
          onClick={handleSelectAll}
        >
          {selectedImages.length === allImages.length
            ? t("deselectAll")
            : t("selectAllVisible")}
        </Text>
      </div>
      <div className="flex items-center">
        <ActionIcons
          icons={
            albumMode
              ? [
                  {
                    loading: loading,
                    icon: IconDownload,
                    onClick: handleDownload,
                    className: "stroke-white bg-black download",
                    width: iconSize,
                    height: iconSize,
                    tooltip: t("download"),
                  },
                ]
              : [
                  {
                    loading: loading,
                    icon: IconDownload,
                    onClick: handleDownload,
                    className: "stroke-white bg-black download",
                    width: iconSize,
                    height: iconSize,
                    tooltip: t("download"),
                  },
                  ...(notApproved
                    ? [
                        {
                          icon: IconCheck,
                          onClick: handleApprove,
                          className: "stroke-white bg-black approve",
                          width: iconSize,
                          height: iconSize,
                          tooltip: t("approve"),
                        },
                      ]
                    : []),
                  ...(notPublished
                    ? [
                        {
                          icon: IconPhotoUp,
                          onClick: handlePublish,
                          className: "stroke-green bg-black publish",
                          width: iconSize,
                          height: iconSize,
                          tooltip: t("publish"),
                        },
                      ]
                    : []),
                  ...(published
                    ? [
                        {
                          icon: IconArchive,
                          onClick: handleUnPublish,
                          className: "stroke-white bg-black unpublish",
                          width: iconSize,
                          height: iconSize,
                          tooltip: t("unpublish"),
                        },
                      ]
                    : []),
                  {
                    icon: IconTrash,
                    onClick: () => {
                      open();
                    },
                    className: "stroke-red bg-black remove",
                    width: iconSize,
                    height: iconSize,
                    tooltip: t("remove"),
                  },
                ]
          }
        />
        <Text
          onClick={() => {
            selectImages([]);
          }}
          className="font-semibold cursor-pointer md:ml-8 ml-4"
          color="white"
          size="sm"
        >
          {t("cancel")}
        </Text>
      </div>
    </div>
  );
};

export default Selected;
