import { ShortLogo } from ".";

const CenteredBlockWrapper = ({
  children,
  footer,
}: {
  children: React.ReactNode;
  footer?: React.ReactNode;
}) => {
  return (
    <div className="flex-1 w-screen bg-cover bg-no-repeat bg-gradient-pattern flex flex-col">
      <div
        className="h-[47px] w-[53px] bg-white/30 flex items-center justify-center absolute left-4 md:left-8 top-0 rounded-b-lg backdrop-blur-lg z-10"
        style={{ backdropFilter: "blur(10px)" }}
      >
        <ShortLogo />
      </div>
      <div className="flex flex-col mx-auto flex-1 justify-center">
        <div className="bg-white rounded-xl p-4 md:px-16 md:py-11 mx-4 md:mx-0 max-w-[470px]">
          {children}
        </div>
        {footer}
      </div>
    </div>
  );
};

export default CenteredBlockWrapper;
