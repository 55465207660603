import { useTranslations } from "use-intl";
import { Input, Loading, ReactTable, Title } from "../../common";
import { useQuery } from "@apollo/client";
import { PaginatedType } from "../../types/paginated.type";
import { useEffect } from "react";
import { ErrorService } from "../../services";
import { useLocalizedNavigate, useSearchParamsState } from "../../hooks";
import { Loader, Pagination } from "@mantine/core";
import { OperationType } from "../../types/operation-type.enum";
import { Album } from "../../types/album.type";
import { albumColumns } from "../../columns/AlbumColumns";
import { allAlbumsQuery } from "../../graphql/albumQueries";

const AlbumsPage = () => {
  const t = useTranslations("AdminAlbums");
  const [queryParams, setQueryParams] = useSearchParamsState({
    search: "",
    page: 1,
    limit: 12,
  });
  const navigate = useLocalizedNavigate();

  const { loading, error, data, refetch } = useQuery<
    { albums: PaginatedType<Album> },
    {
      first?: number;
      after?: string;
      id?: string;
      name?: string;
      operationType?: OperationType;
      shortId?: string;
      user?: string;
      offset?: number;
    }
  >(allAlbumsQuery, {
    fetchPolicy: "no-cache",
    variables: {
      first: queryParams.limit,
      offset: (queryParams.page - 1) * queryParams.limit,
    },
  });

  const loadMore = (newPage: number) => {
    const first = queryParams.limit;
    let offset;

    // If neither after nor before is available, use offset
    offset = (newPage - 1) * first;

    refetch({
      first,
      ...(offset !== undefined && { offset }), // Only add offset if it's defined
    }).then(() => {
      setQueryParams({ page: newPage });
    });
  };

  useEffect(() => {
    if (error) {
      ErrorService.showError(t("errorFetching"));
    }
  }, [error]);

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const search = event.target.value;
    refetch({
      user: search,
      id: search,
      name: search,
      operationType: OperationType.OR,
      first: queryParams.limit,
    });
    setQueryParams({ search, page: 1 });
  };

  const totalCount = data?.albums.totalCount ?? 0;

  return (
    <div className="flex-1 md:px-6 px-4 flex flex-col">
      <div className="flex justify-between md:items-center gap-4 flex-col md:flex-row">
        <Title size="H5">{t("albums")}</Title>
        <div className="flex flex-col md:flex-row gap-6">
          <Input
            rightSection={loading && <Loader color="#222222" size={16} />}
            onChange={onSearchChange}
            placeholder={t("search")}
          />
        </div>
      </div>
      <div className=" mt-8">
        <ReactTable<Album>
          onRowClick={(album) => {
            navigate("/admin/album-settings/" + album.shortId, {
              state: { album },
            });
          }}
          search={""}
          loading={loading}
          columns={albumColumns}
          data={data?.albums.nodes ?? []}
          initialPageSize={30}
          emptyMessage={t("noAlbums")}
        />
        <Pagination
          className="mt-6"
          classNames={{
            control:
              "text-black hover:bg-black hover:text-white border-medium-black data-[active=true]:bg-black data-[active=true]:text-white",
          }}
          size={"sm"}
          value={queryParams.page}
          total={Math.ceil(totalCount / queryParams.limit)}
          onChange={loadMore}
        />
        {loading && <Loading />}
      </div>
    </div>
  );
};

export default AlbumsPage;
