import { useLocale } from "use-intl";
import { Album } from "../../types/album.type";
import moment from "moment";
import Text from "../Text";

const AlbumDetails = ({
  album,
  center = true,
}: {
  album?: Album;
  center?: boolean;
}) => {
  const locale = useLocale();
  const eventDate =
    album?.eventDate &&
    moment(album.eventDate).locale(locale).format("MMMM DD, YYYY");
  const centerClassName = center ? "text-center" : "";
  return (
    <div className="">
      <Text
        size={5}
        className={`font-playfair font-bold ${centerClassName} uppercase line-clamp-1 overflow-hidden text-ellipsis`}
      >
        {album?.name}
      </Text>
      {eventDate && (
        <Text
          size={4}
          className={`font-playfair font-bold ${centerClassName} uppercase`}
        >
          {eventDate}
        </Text>
      )}
      {album?.description && (
        <Text
          size={4}
          className={`line-clamp-3 mt-1 ${centerClassName}`}
          color="gray"
        >
          {album.description}
        </Text>
      )}
    </div>
  );
};

export default AlbumDetails;
