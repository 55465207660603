import { useQuery } from "@apollo/client";
import { Title } from "../../../common";
import { findDailyAccumulatedPaymentAmount } from "../../../graphql/paymentsQueries";
import { findUserCountByDay } from "../../../graphql/userQueries";
import { Loader } from "@mantine/core";
import { useEffect, useState } from "react";
import moment from "moment-timezone";
import { LineChart } from "@mantine/charts";
import _ from "lodash";
import { ErrorService } from "../../../services";

const AccumulatedIncome = () => {
  const [mergedData, setMergedData] = useState<
    {
      date: string;
      accumulatedAmount: number;
      accumulatedCount: number;
    }[]
  >([]);

  const {
    loading: accumulatedAmountLoading,
    error: accumulatedAmountError,
    data: accumulatedAmountRaw,
  } = useQuery<
    {
      findDailyAccumulatedPaymentAmount: {
        accumulated_amount: number;
        date: Date;
      }[];
    },
    {}
  >(findDailyAccumulatedPaymentAmount, {});

  const {
    loading: accumulatedAmountPastMonthLoading,
    error: accumulatedAmountPastMonthError,
    data: accumulatedAmountPastMonthRaw,
  } = useQuery<
    {
      findDailyAccumulatedPaymentAmount: {
        accumulated_amount: number;
        date: Date;
      }[];
    },
    { createdAtEnd?: Date; createdAtStart?: Date }
  >(findDailyAccumulatedPaymentAmount, {
    variables: {
      createdAtStart: moment
        .utc()
        .subtract(1, "month")
        .startOf("month")
        .toDate(), //Begining of last month
      createdAtEnd: moment.utc().subtract(1, "month").endOf("month").toDate(), //End of last month
    },
  });

  const {
    loading: findUserCountByDayLoading,
    error: findUserCountByDayError,
    data: findUserCountByDayRaw,
  } = useQuery<
    { findUserCountByDay: { accumulated_count: number; date: Date }[] },
    {}
  >(findUserCountByDay, {});

  useEffect(() => {
    if (
      findUserCountByDayError ||
      accumulatedAmountError ||
      accumulatedAmountPastMonthError
    ) {
      ErrorService.showError("Error fetching statistics.");
    }
  }, [
    findUserCountByDayError,
    accumulatedAmountError,
    accumulatedAmountPastMonthError,
  ]);

  useEffect(() => {
    if (
      accumulatedAmountRaw &&
      findUserCountByDayRaw &&
      accumulatedAmountPastMonthRaw
    ) {
      const amountData = accumulatedAmountRaw.findDailyAccumulatedPaymentAmount;
      const countData = findUserCountByDayRaw.findUserCountByDay;
      const lastMonthAmountData =
        accumulatedAmountPastMonthRaw.findDailyAccumulatedPaymentAmount;

      const currentMonthDays = moment().daysInMonth();
      const lastMonthDays = moment().subtract(1, "month").daysInMonth();

      const mergedData = [];

      for (let day = 1; day <= currentMonthDays; day++) {
        const currentDate = moment().date(day).startOf("day");

        const amountItem = amountData.find((item) =>
          moment(item.date).startOf("day").isSame(currentDate, "day")
        );

        const countItem = countData.find((item) =>
          moment(item.date).startOf("day").isSame(currentDate, "day")
        );

        let lastMonthAmountItem = null;
        if (day <= lastMonthDays) {
          lastMonthAmountItem = lastMonthAmountData.find(
            (item) => moment(item.date).date() === day
          );
        } else {
          // Use the last available data for days exceeding last month's total
          lastMonthAmountItem =
            lastMonthAmountData[lastMonthAmountData.length - 1];
        }

        mergedData.push({
          date: currentDate.format("DD"),
          accumulatedAmount: amountItem
            ? amountItem.accumulated_amount / 100
            : 0,
          accumulatedCount: countItem ? countItem.accumulated_count : 0,
          accumulatedLastMonthAmount: lastMonthAmountItem
            ? lastMonthAmountItem.accumulated_amount / 100
            : 0,
        });
      }

      setMergedData(mergedData);
    }
  }, [
    accumulatedAmountRaw,
    findUserCountByDayRaw,
    accumulatedAmountPastMonthRaw,
  ]);

  return (
    <div>
      <Title size="H4" className="flex gap-2 items-center mb-6">
        Income correlation with user count
      </Title>
      <div>
        {findUserCountByDayLoading ||
        accumulatedAmountLoading ||
        accumulatedAmountPastMonthLoading ? (
          <Loader color="#222" size="lg" />
        ) : (
          <LineChart
            withDots={false}
            withXAxis={false}
            h={300}
            w={"100%"}
            data={mergedData ?? []}
            dataKey="date"
            curveType="bump"
            withRightYAxis
            series={[
              {
                name: "accumulatedCount",
                label: "Users registered",
                color: "indigo.6",
              },
              {
                name: "accumulatedAmount",
                label: "This month payments",
                color: "red.6",
                yAxisId: "right",
              },
              {
                name: "accumulatedLastMonthAmount",
                label: "Last month payments",
                color: "yellow.6",
                yAxisId: "right",
                strokeDasharray: "5 5",
              },
            ]}
          />
        )}
      </div>
    </div>
  );
};

export default AccumulatedIncome;
