import { useState, useEffect } from "react";
import moment from "moment";
import { CopyButton } from "@mantine/core";
import { Button, RoundedBox, Text } from "../../../common";
import { useTranslations } from "use-intl";
import { Album } from "../../../types/album.type";

const OFFER_DURATION = moment.duration(60, "minutes");

const TimeOffer = ({ album }: { album: Album }) => {
  const t = useTranslations("Pricing");
  const [timeLeft, setTimeLeft] = useState<moment.Duration | null>(null);
  const [isVisible, setIsVisible] = useState(false);

  const TIME_OFFER_KEY = `timeOfferStartTime_${album.id}`;

  useEffect(() => {
    const startTimeString = localStorage.getItem(TIME_OFFER_KEY);
    const now = moment();

    if (!startTimeString) {
      localStorage.setItem(TIME_OFFER_KEY, now.toISOString());
      setIsVisible(true);
      setTimeLeft(OFFER_DURATION.clone());
    } else {
      const startTime = moment(startTimeString);
      const elapsedTime = moment.duration(now.diff(startTime));

      if (elapsedTime.asMilliseconds() < OFFER_DURATION.asMilliseconds()) {
        setIsVisible(true);
        setTimeLeft(OFFER_DURATION.clone().subtract(elapsedTime));
      } else {
        setIsVisible(false);
      }
    }
  }, [TIME_OFFER_KEY]);

  useEffect(() => {
    if (!timeLeft) return;

    const timer = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime && prevTime.asSeconds() > 0) {
          const newTime = prevTime.clone().subtract(1, "second");
          if (newTime.asSeconds() <= 0) {
            clearInterval(timer);
            setIsVisible(false);
          }
          return newTime;
        } else {
          clearInterval(timer);
          setIsVisible(false);
          return null;
        }
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [timeLeft]);

  if (!isVisible) return null;

  const formatTime = (duration: moment.Duration | null) => {
    if (!duration) return "00:00";
    const minutes = duration.minutes().toString().padStart(2, "0");
    const seconds = duration.seconds().toString().padStart(2, "0");
    return `${minutes}:${seconds}`;
  };

  return (
    <div className="my-6">
      <RoundedBox
        roundedOnSmallScreens={false}
        color="orange"
        className="flex flex-col md:flex-row gap-6 md:items-center justify-between relative"
      >
        <div className="absolute -top-4 md:left-6 bg-black py-2 rounded-lg w-[95px]">
          <Text size="sm" color="white" className="font-semibold text-center">
            ⏰ {formatTime(timeLeft)}
          </Text>
        </div>
        <div className="pt-4 md:pt-1">
          <Text color="white" className="font-semibold">
            {t("timeOffer")}
          </Text>
          <Text color="white" className="mt-2">
            {t.rich("timeOfferDescription", {
              b: (child) => <b>{child}</b>,
            })}
          </Text>
        </div>
        <div>
          <CopyButton value="ROMFUN20">
            {({ copied, copy }) => (
              <Button
                data-ph-capture-attribute-event="copyDiscountClicked"
                fullWidth
                color="white"
                type="outline"
                onClick={copy}
                title={copied ? t("copied") : t("copyPromoCode")}
              />
            )}
          </CopyButton>
        </div>
      </RoundedBox>
    </div>
  );
};

export default TimeOffer;
