import { useEffect } from "react";

export function useLockBodyScroll(open: boolean): void {
  useEffect(() => {
    if (open) {
      // Get scrollbar width
      const scrollBarWidth =
        window.innerWidth - document.documentElement.clientWidth;

      // Store the original body styles
      const originalStyles = {
        overflow: document.body.style.overflow,
        position: document.body.style.position,
        width: document.body.style.width,
        paddingRight: document.body.style.paddingRight,
      };

      // Lock the body scroll position
      document.body.style.overflow = "hidden";
      document.body.style.position = "absolute";
      document.body.style.width = "100%";
      document.body.style.paddingRight = `${scrollBarWidth}px`; // Add scrollbar width to prevent layout shift

      return () => {
        // Restore the original styles
        Object.assign(document.body.style, originalStyles);
      };
    }
  }, [open]); // Depend only on the `open` state
}
