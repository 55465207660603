import { useEffect } from "react";
import { useBlocker } from "react-router-dom";
import { closeModal, openModal } from "@mantine/modals";
import { useTranslations } from "use-intl";
import { Button, Text, Title } from "../common";

export const useConfirmNavigationWithSave = (
  shouldBlockNavigation: boolean,
  saveFunction: () => void
) => {
  const t = useTranslations("Blocker");

  const blocker = useBlocker(shouldBlockNavigation);

  useEffect(() => {
    if (blocker.state === "blocked") {
      openModal({
        styles: {
          content: {
            borderRadius: 8,
          },
        },
        modalId: "blocker",
        centered: true,
        withCloseButton: false,
        children: (
          <div>
            <Title size="H4">{t("holdOn")}</Title>
            <Text className="mt-4">{t("aboutToLeave")}</Text>
            <div className="mt-4 flex justify-end gap-2">
              <Button
                type="outline"
                small
                onClick={() => {
                  blocker.proceed();
                  closeModal("blocker");
                }}
                title={t("dontSave")}
              />
              <Button
                small
                onClick={() => {
                  saveFunction();
                  blocker.reset();
                  closeModal("blocker");
                }}
                title={t("save")}
              />
            </div>
          </div>
        ),
      });
    }
  }, [blocker]);
};
