import { useTranslations } from "use-intl";
import { Button, Text, Title } from "../../../common";
import { useMutation } from "@apollo/client";
import { User } from "../../../types/user.type";
import { updateUserMutation } from "../../../graphql/userQueries";
import { useDispatch } from "react-redux";
import { setUser } from "../../../store/user.reducer";
import errorService from "../../../services/error.service";
import { OnboardingWrapper } from ".";
import { showConfetti } from "../../../store/confetti.reducer";

const FourthStep = () => {
  const t = useTranslations();
  const dispatch = useDispatch();
  const [updateUser] = useMutation<
    { updateUser: User },
    {
      input: { onboarded: Date };
    }
  >(updateUserMutation);

  const handleFinishOnboarding = async () => {
    try {
      const resp = await updateUser({
        variables: {
          input: {
            onboarded: new Date(),
          },
        },
      });
      dispatch(setUser(resp.data?.updateUser ?? null));
      dispatch(showConfetti(true));
    } catch (error) {
      errorService.showError(t("Onboarding.errorOnObarordingFinish"));
    }
  };

  return (
    <OnboardingWrapper>
      <div className="flex flex-1 justify-center">
        <div className="md:pt-10 pt-4 md:max-w-[340px] px-4 md:px-0 flex-1 flex-col flex">
          <div className="flex-1 flex flex-col md:justify-center mt-14 md:mt-0">
            <Title size="H3">{t("Onboarding.nicelyDone")}</Title>
            <Text color="gray" className="pt-2">
              {t("Onboarding.firstMemories")}
            </Text>
            <Text color="gray" className=" pt-4 pb-10">
              {t.rich("Onboarding.clickHere", {
                click: (chunks) => (
                  <a
                    onClick={() => {
                      handleFinishOnboarding();
                    }}
                    className="underline text-black cursor-pointer"
                  >
                    {chunks}
                  </a>
                ),
              })}
            </Text>
          </div>
        </div>
      </div>
      <div className="justify-center items-start flex md:items-end md:justify-end md:flex-col pt-4 px-4 md:px-10 pb-10 md:pt-0">
        <Button
          title={t("Onboarding.exploreDashboard")}
          onClick={() => {
            handleFinishOnboarding();
          }}
        />
      </div>
    </OnboardingWrapper>
  );
};

export default FourthStep;
