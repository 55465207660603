import { Tooltip } from "@mantine/core";
import { useClickOutside, useDisclosure } from "@mantine/hooks";
import { IconInfoCircle } from "@tabler/icons-react";

const InfoToolitp = ({ text }: { text: any }) => {
  const [opened, { open, close }] = useDisclosure(false);
  const ref = useClickOutside(() => close());

  return (
    <span>
      <Tooltip
        opened={opened}
        ref={ref}
        classNames={{ tooltip: "max-w-[200px] md:max-w-[375px]" }}
        position="top"
        label={text}
        multiline
      >
        <IconInfoCircle
          onMouseEnter={open}
          onMouseLeave={close}
          onClick={open}
          size={16}
          className=" stroke-light-black inline-block ml-1"
        />
      </Tooltip>
    </span>
  );
};

export default InfoToolitp;
