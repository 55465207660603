import { useTranslations } from "use-intl";
import {
  Button,
  CenteredBlockWrapper,
  Error,
  PasswordInput,
  Text,
  Title,
} from "../../common";
import { useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { apolloClientWithoutErrorLink } from "../../lib/apolloClient";
import { passwordResetMutation } from "../../graphql/userQueries";
import { ErrorService } from "../../services";
import { useSearchParams } from "react-router-dom";
import { useLocalizedNavigate } from "../../hooks";
import { Helmet } from "react-helmet-async";

const PasswordResetPage = () => {
  const t = useTranslations("PasswordReset");
  const tV = useTranslations("Validation");
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const navigate = useLocalizedNavigate();

  return (
    <CenteredBlockWrapper
      footer={
        <Text className=" mt-10 text-center" color="gray">
          {t.rich("remembered", {
            link: (chunk) => (
              <span
                onClick={() => navigate("/signin")}
                className="underline cursor-pointer text-black"
              >
                {chunk}
              </span>
            ),
          })}
        </Text>
      }
    >
      <Helmet>
        <title>Rompolo - {t("passwordReset")}</title>
      </Helmet>
      <Formik
        validateOnChange={false}
        enableReinitialize
        validationSchema={Yup.object().shape({
          password: Yup.string()
            .min(8)
            .required(tV("required"))
            .matches(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
              tV("passwordError")
            ),
        })}
        initialValues={{
          password: "",
          resetToken: token ?? "",
        }}
        onSubmit={(values) => {
          if (!token) {
            setError(t("noResetToken"));
            return;
          }

          setLoading(true);
          apolloClientWithoutErrorLink
            .mutate<
              { resetPassword: boolean },
              {
                input: {
                  password: string;
                  resetToken: string;
                };
              }
            >({
              mutation: passwordResetMutation,
              variables: { input: values },
            })
            .then((resp) => {
              if (resp.data?.resetPassword) {
                ErrorService.showMessage(t("success"));
                navigate("/signin");
              } else {
                setError(t("errorResetingPassword"));
              }
            })
            .catch(() => {
              setError(t("errorResetingPassword"));
            })
            .finally(() => {
              setLoading(false);
            });
        }}
      >
        {(props) => {
          return (
            <div>
              <Title size="H3">{t("passwordReset")}</Title>
              <Text className="mt-2 mb-8" color="gray">
                {t("passwordRestDescription")}
              </Text>
              {error && <Error message={error} />}
              <PasswordInput
                name="password"
                onChange={props.handleChange}
                className="mb-4"
                placeholder={t("password")}
                error={props.errors.password}
              />
              <Button
                loading={loading}
                onClick={props.submitForm}
                fullWidth
                title={t("submit")}
              />
            </div>
          );
        }}
      </Formik>
    </CenteredBlockWrapper>
  );
};

export default PasswordResetPage;
