import { useTranslations } from "use-intl";
import { Button, InfoToolitp, Text, Title } from "../../../common";
import { Album } from "../../../types/album.type";
import moment from "moment";
import { PlanConfig } from "./Plans";
import { PlanProducts } from "../../../config/pricing.config";
import UpgradePlan from "./UpgradePlan";
import ExtendPlan from "./ExtendPlan";

const ActivePlan = ({
  planConfig,
  album,
  purchase,
  loading,
}: {
  album: Album;
  planConfig: PlanConfig;
  purchase: (prices: string[]) => void;
  loading?: boolean;
}) => {
  const SIX_MONTHS_IN_DAYS = 6 * 30;

  const t = useTranslations("Pricing");
  const activatedOn = album.activationDate
    ? moment(album.activationDate).format("DD.MM.YYYY")
    : null;
  const expirationDate = album.expirationDate
    ? moment(album.expirationDate).format("DD.MM.YYYY")
    : null;

  const onManageSubscription = () => {
    const billingPortalUrl = import.meta.env.VITE_STRIPE_BILLING_PORTAL_URL;
    window.open(billingPortalUrl, "_blank");
  };

  //If not subscription and expiration date exists or subscription has expired, then allow extend plan
  const showExtendButton =
    (planConfig.productNamespace !== "subscription" && album.expirationDate) ||
    (planConfig.productNamespace === "subscription" &&
      album.expirationDate &&
      album.expirationDate < new Date());

  const plans = PlanProducts[planConfig.productNamespace];
  const maxAvaliableStorage = Math.max(...Object.keys(plans).map(Number));
  const maximumStorageReached = maxAvaliableStorage === album.maxSize;

  const expiresInLessThan6Months = moment()
    .add(SIX_MONTHS_IN_DAYS + 1, "days")
    .isAfter(moment(album.expirationDate));

  const showUpgradeButton =
    (!maximumStorageReached &&
      album.expirationDate &&
      moment().isBefore(album.expirationDate) &&
      expiresInLessThan6Months) ||
    (!maximumStorageReached && !album.expirationDate);

  return (
    <div className="flex flex-col md:flex-row *:flex-1 md:gap-24 bg-white rounded-xl p-6 flex-1 w-full flex-wrap">
      <div>
        <div className="flex gap-4 ">
          <Title wrap="nowrap" size="H5">
            {planConfig.plan}
          </Title>
          <div className=" rounded-lg px-4 py-1 bg-light-beige flex items-center">
            <Text wrap="nowrap" size="xs" className="font-semibold">
              {t("activePlan")}
            </Text>
          </div>
        </div>
        <div className="mt-4 flex flex-col gap-2">
          <Text color="gray">
            {t("activatedOn")}
            <span className=" text-black">
              {activatedOn ?? t("notYetActivated")}
            </span>
          </Text>
          <Text color="gray" wrap="nowrap" className="flex items-center">
            {t("uploadSavedUntil")}
            <span className="text-black ml-1">
              {expirationDate ?? "-"}
              <InfoToolitp text={t("storageTooltip")} />
            </span>
          </Text>
        </div>
      </div>
      <div className="md:hidden flex my-6 border border-light-beige"></div>
      <div className="">
        <Text className="font-semibold" size="sm">
          {t("whatsIncluded")}
        </Text>
        <ul className="mt-2 list-image-checkmark">
          <li className="pt-2 ml-5">
            <div className="flex items-center gap-[6px]">
              <Text>
                <b className="mr-1">{album.maxSize}GB</b>
                {t("gbIncluded")}
              </Text>
            </div>
          </li>
          {planConfig.included.map((i, index) => (
            <li className="pt-2 ml-5" key={index}>
              <div className="flex items-center gap-[6px]">
                <Text>
                  {i.title}
                  {i.details && <InfoToolitp text={i.details} />}
                </Text>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div className="flex flex-col gap-4 md:mt-0 mt-6">
        <div className="flex justify-end">
          <UpgradePlan
            maximumStorageReached={maximumStorageReached}
            disabled={!showUpgradeButton}
            planConfig={planConfig}
            storageOptions={Object.keys(plans).map(Number)}
            album={album}
            purchase={purchase}
            loading={loading}
          />
        </div>
        <div className="flex justify-end">
          {album.plan !== "subscription" && (
            <ExtendPlan
              disabled={!showExtendButton}
              loading={loading}
              planConfig={planConfig}
              album={album}
              purchase={purchase}
            />
          )}
        </div>
        {planConfig.productNamespace === "subscription" && (
          <div className="flex justify-end">
            <Button
              minWidth={190}
              type="outline"
              onClick={onManageSubscription}
              title={t("manageSubscription")}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ActivePlan;
