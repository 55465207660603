import { useTranslations } from "use-intl";
import SettingsBlock from "../SettingsBlock";
import { Album, CreateAlbumInput } from "../../types/album.type";
import { useFormikContext } from "formik";
import Text from "../Text";
import { ChangeEvent, useRef, useState } from "react";
import { IconPencil, IconTrash } from "@tabler/icons-react";
import Input from "../Input";
import Button from "../Button";
import HashtagBadge from "../HashtagBadge";
import { Plan } from "../../types/plan.enum";

type Hashtag = {
  id: string | null;
  title: string;
};

const AlbumHashTagsSettings = ({
  album,
  handleFieldChange,
}: {
  album?: Album;
  handleFieldChange: (e: React.ChangeEvent<any> | string, value?: any) => void;
}) => {
  const t = useTranslations("AlbumSettings");
  const formik = useFormikContext<CreateAlbumInput>();
  const isFree = album?.plan === Plan.free;

  return (
    <SettingsBlock
      premium={isFree}
      description={t.rich("hashtagsSettingsDescription", {
        link: (children) => (
          <a
            href="https://rompolo.com/blog/say-cheese-and-go-chase-the-ultimate-guide-to-photo-scavenger-hunts"
            target="_blank"
            rel="noopener noreferrer"
            className="underline hover:text-black"
          >
            {children}
          </a>
        ),
      })}
    >
      <div>
        <div>
          <HashtagManager
            album={album}
            handleFieldChange={handleFieldChange}
            hashtags={formik.values.hashtags ?? []}
            t={t}
          />
        </div>
        {/* <Radio.Group
          value={String(formik.values.allowCreateHashtags)}
          onChange={(val) => {
            handleFieldChange("allowCreateHashtags", JSON.parse(val));
          }}
          name="allowCreateHashtags"
        >
          <Text className="mb-4 mt-6 flex gap-1 items-center" color="gray">
            <span>
              {t("allowAddHashtags")}
              <span className=" whitespace-nowrap">{upgrade}</span>
            </span>
          </Text>
          <Group>
            <Radio
              disabled={isFree}
              variant="outline"
              color="#222222"
              styles={{ label: { fontSize: 16 } }}
              value={"false"}
              label={t("no")}
            />
            <Radio
              disabled={isFree}
              variant="outline"
              color="#222222"
              styles={{ label: { fontSize: 16 } }}
              value={"true"}
              label={t("yes")}
            />
          </Group>
        </Radio.Group> */}
      </div>
    </SettingsBlock>
  );
};

const HashtagManager = ({
  t,
  handleFieldChange,
  hashtags,
  album,
}: {
  album?: Album;
  t: any;
  hashtags: Hashtag[];
  handleFieldChange: (e: React.ChangeEvent<any> | string, value?: any) => void;
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const isFree = album?.plan === Plan.free;

  const [currentTag, setCurrentTag] = useState<Hashtag>({
    id: null,
    title: "",
  });
  const [editIdx, setEditIdx] = useState<number | null>(null);

  const handleDelete = (index: number): void => {
    const updatedTags = hashtags.filter((_, idx) => idx !== index);
    handleFieldChange("hashtags", updatedTags);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setCurrentTag({ ...currentTag, title: event.target.value });
  };

  const resetInput = () => {
    setCurrentTag({ id: null, title: "" });
    setEditIdx(null);
  };

  const saveTag = (): void => {
    const tagToSave = "#" + currentTag.title;
    currentTag.title = tagToSave;
    if (currentTag.title.trim()) {
      const updatedTags = [...hashtags];
      if (editIdx !== null) {
        // Update existing tag
        updatedTags[editIdx] = {
          ...updatedTags[editIdx],
          title: currentTag.title,
        };
      } else {
        // Add a new tag
        updatedTags.push({ ...currentTag }); // Assign an ID for new tags
      }
      handleFieldChange("hashtags", updatedTags);
      resetInput();
    }
  };

  const handleKeyPress = (event: any) => {
    if (event.code === "Enter") {
      if (currentTag.title.length > 0) {
        saveTag();
      }
    }
  };

  return (
    <div>
      {hashtags.length > 0 && (
        <>
          <div>
            <Text color="gray">{t("addedHashtags")}</Text>
          </div>
          <div className="flex gap-2 md:gap-4 flex-wrap mb-4 mt-4">
            {hashtags.map((tag, index) => (
              <HashtagBadge
                title={tag.title}
                key={index}
                rightBlock={
                  <div className="flex gap-1">
                    <IconPencil
                      className="cursor-pointer"
                      size={16}
                      onClick={() => {
                        setCurrentTag(tag);
                        setEditIdx(index);
                        inputRef.current?.focus();
                      }}
                    />
                    <IconTrash
                      size={16}
                      className="stroke-red cursor-pointer"
                      onClick={() => handleDelete(index)}
                    />
                  </div>
                }
              />
            ))}
          </div>
        </>
      )}
      <div className="flex gap-4 flex-col">
        <div>
          <Text color="gray" className="mb-2">
            {t("addHashtags")}
          </Text>
          <Input
            onKeyDown={handleKeyPress}
            leftSection={"#"}
            disabled={isFree}
            ref={inputRef}
            value={currentTag.title.replace(/#/g, "")}
            onChange={handleChange}
          />
        </div>
        {currentTag.title.length > 0 && (
          <div className="flex gap-4 items-center">
            <Button
              disabled={isFree}
              fullWidth={false}
              onClick={saveTag}
              type="filled"
              title={editIdx !== null ? t("save") : t("add")}
            />
            <Text
              className="md:w-auto w-[200px] text-center cursor-pointer font-semibold"
              onClick={resetInput}
            >
              {t("cancel")}
            </Text>
          </div>
        )}
      </div>
    </div>
  );
};

export default AlbumHashTagsSettings;
