import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { useTranslations } from "use-intl";
import {
  Button,
  RoundedBox,
  ShareAlbumModal,
  Text,
  Title,
} from "../../../common";
import { openHowTo } from "../../../store/howto.reducer";
import DownloadQrCode from "./DownloadQrCode";
import { useDisclosure } from "@mantine/hooks";
import { useLocalizedNavigate } from "../../../hooks";

const AlbumDashboard = () => {
  const dispatch = useDispatch();
  const album = useSelector((root: RootState) => root.album.album);
  const t = useTranslations("Dashboard");
  const albumUrl = `${window.location.origin}/album/${album?.shortId}`;
  const [shareOpened, { close: closeShare, open: openShare }] =
    useDisclosure(false);
  const navigate = useLocalizedNavigate();

  const openHowItWorksGeneral = () => {
    dispatch(openHowTo("general"));
  };

  return (
    <RoundedBox roundedOnSmallScreens={false} className="">
      <div className="flex justify-between">
        <div className="">
          <Title size="H5">{t("yourAlbum")}</Title>
        </div>
        <div>
          <span
            className="cursor-pointer font-semibold whitespace-nowrap text-black hover:text-very-black"
            onClick={openHowItWorksGeneral}
          >
            {t("howItWorks")}
          </span>
        </div>
      </div>
      <div className=" flex flex-col md:gap-4 gap-10 mt-6">
        {/* Open album */}
        <div className="flex justify-between xl:items-center gap-6 flex-col xl:flex-row">
          <div className="flex gap-6 md:items-center">
            <div className="flex-shrink-0 w-[40px] h-[40px] md:w-[72px] md:h-[72px] rounded-lg bg-light-green flex items-center justify-center">
              <span className="md:text-[32px] text-[18px]">👀</span>
            </div>
            <div className="flex flex-col gap-2">
              <Text className=" font-semibold">{t("openAlbum")}</Text>
              <Text color="gray">{t("openAlbumDescription")}</Text>
            </div>
          </div>
          <div className="flex justify-end">
            <Button
              data-ph-capture-attribute-event="openAlbumClickedDashboard"
              className="open-album-btn"
              title={t("open")}
              color="black"
              onClick={() => window.open(albumUrl)}
            />
          </div>
        </div>
        {/* Share album */}
        <div className="flex justify-between xl:items-center gap-6 flex-col xl:flex-row">
          <div className="flex gap-6 md:items-center">
            <div className="flex-shrink-0 w-[40px] h-[40px] md:w-[72px] md:h-[72px] rounded-lg bg-light-blue flex items-center justify-center">
              <span className="md:text-[32px] text-[18px]">💬</span>
            </div>
            <div className="flex flex-col gap-2">
              <Text className=" font-semibold">{t("inviteOthers")}</Text>
              <Text color="gray">{t("inviteOthersDescription")}</Text>
            </div>
          </div>
          <div className="flex justify-end">
            <ShareAlbumModal
              albumUrl={albumUrl}
              opened={shareOpened}
              close={closeShare}
            />
            <Button
              data-ph-capture-attribute-event="shareAlbumClickedDashboard"
              className="share-album-btn"
              title={t("share")}
              type="outline"
              onClick={openShare}
            />
          </div>
        </div>
        {/* Manage uploads */}
        <div className="flex justify-between xl:items-center gap-6 flex-col xl:flex-row">
          <div className="flex gap-6 md:items-center">
            <div className="flex-shrink-0 w-[40px] h-[40px] md:w-[72px] md:h-[72px] rounded-lg bg-light-beige flex items-center justify-center">
              <span className="md:text-[32px] text-[18px]">🖼️</span>
            </div>
            <div className="flex flex-col gap-2">
              <Text className=" font-semibold">{t("manageUploads")}</Text>
              <Text color="gray">{t("manageUploadsDescription")}</Text>
            </div>
          </div>
          <div className="flex justify-end">
            <Button
              data-ph-capture-attribute-event="manageAlbumClickedDashboard"
              className="manage-album-btn"
              title={t("manage")}
              type="outline"
              onClick={() => navigate(`/photos-videos`)}
            />
          </div>
        </div>
        {/* Download QR Code */}
        <div className="flex justify-between xl:items-center gap-6 flex-col xl:flex-row ">
          <div className="flex gap-6 md:items-center">
            <div className="flex-shrink-0 w-[40px] h-[40px] md:w-[72px] md:h-[72px] rounded-lg bg-light-pink flex items-center justify-center">
              <span className="md:text-[32px] text-[18px]">🖨️</span>
            </div>
            <div className="flex flex-col gap-2">
              <Text className=" font-semibold">{t("downloadQr")}</Text>
              <Text color="gray">{t("downloadQrDescription")}</Text>
            </div>
          </div>
          <div className="flex justify-end">
            <DownloadQrCode url={albumUrl} />
          </div>
        </div>
      </div>
    </RoundedBox>
  );
};

export default AlbumDashboard;
