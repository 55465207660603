import { CellContext, ColumnDef } from "@tanstack/react-table";
import { Text, TextWithTooltip } from "../common";
import { Album } from "../types/album.type";
import { User } from "../types/user.type";
import { Plan } from "../types/plan.enum";
import moment from "moment";
import { EventType } from "../types/event.enum";

export const albumColumns: ColumnDef<Album, any>[] = [
  {
    header: "Short Id",
    accessorKey: "shortId",
    cell: (props: CellContext<Album, string>) => (
      <TextWithTooltip text={props.getValue()} />
    ),
  },
  {
    header: "Album Url",
    accessorKey: "shortId",
    cell: (props: CellContext<Album, string>) => (
      <a className="hover:underline" href={`/album/${props.getValue()}`}>
        <Text>{`/album/${props.getValue()}`}</Text>
      </a>
    ),
  },
  {
    header: "User email",
    accessorKey: "userEmail",
    accessorFn: (data) => data.user,
    cell: (props: CellContext<Album, User>) => (
      <Text size="sm">{`${props.getValue()?.email}`}</Text>
    ),
  },
  {
    header: "User name",
    accessorKey: "userName",
    accessorFn: (data) => data.user,
    cell: (props: CellContext<Album, User>) => (
      <Text size="sm">{`${props.getValue()?.fullName}`}</Text>
    ),
  },
  {
    header: "Album",
    accessorKey: "name",
    cell: (props: CellContext<Album, string>) => (
      <Text size="sm">{props.getValue()}</Text>
    ),
  },
  {
    header: "Type",
    accessorKey: "type",
    cell: (props: CellContext<Album, EventType>) => (
      <Text size="sm">{props.getValue()}</Text>
    ),
  },
  {
    header: "Plan",
    accessorKey: "plan",
    cell: (props: CellContext<Album, Plan>) => (
      <Text size="sm">{props.getValue()}</Text>
    ),
  },
  {
    header: "Image count",
    accessorKey: "imageCount",
    cell: (props: CellContext<Album, number>) => (
      <Text size="sm">{props.getValue()}</Text>
    ),
  },
  {
    header: "Max Storage",
    accessorKey: "maxSize",
    cell: (props: CellContext<Album, number>) => (
      <Text size="sm">{props.getValue()}</Text>
    ),
  },
  {
    header: "Expiration date",
    accessorKey: "expirationDate",
    cell: (props: CellContext<Album, Date>) => {
      return (
        <Text size="sm">
          {props.getValue()
            ? moment(props.getValue()).format("DD-MM-YYYY HH:mm")
            : "-"}
        </Text>
      );
    },
  },
];
