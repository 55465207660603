import Title from "../Title";
import { useTranslations } from "use-intl";
import Text from "../Text";
import Button from "../Button";
import { useLockBodyScroll } from "../../hooks";
import { ScrollArea } from "@mantine/core";

const TemplatesInfo = ({
  onClose,
  onClicked,
}: {
  onClose: () => void;
  onClicked: () => void;
}) => {
  useLockBodyScroll(true);
  const t = useTranslations("InfoCenter");
  return (
    <div className="fixed inset-0 md:w-auto md:h-auto h-dvh w-dvw z-50 flex justify-center items-center">
      <div
        className="bg-black/90 absolute inset-0 -z-10"
        onClick={onClose}
      ></div>
      <ScrollArea className="mx-auto md:max-w-[945px] w-dvw h-dvh md:h-[560px]">
        <div className=" z-50  md:rounded-lg flex md:flex-row flex-col-reverse bg-light-purple items-center px-5 py-10 md:px-0 md:py-0 min-h-dvh md:min-h-0 md:h-[560px]">
          <div className="flex-1 md:ml-14 md:justify-normal justify-between flex flex-col">
            <div>
              <Title
                className=" text-center md:text-left md:mt-0 mt-11"
                size="H3"
              >
                {t("introducingTemplates")}
              </Title>
              <Text size={"lg"} className="mt-4 text-center md:text-left">
                {t("templatesDescription")}
              </Text>
            </div>
            <div>
              <Button
                onClick={onClicked}
                className=" mt-8"
                title={t("seeTemplates")}
              />
            </div>
          </div>
          <div className="md:mr-7 flex-1 md:py-6">
            <img
              src="/introducing-templates.webp"
              alt={t("introducingTemplates")}
              className="w-[300px] md:w-[480px] md:h-[440px] object-contain"
            />
          </div>
        </div>
      </ScrollArea>
    </div>
  );
};

export default TemplatesInfo;
