import { DevProducts } from "./dev.products.config";
import { ProdProducts } from "./prod.products.config";

export const findProduct = (
  plan: "payOnce" | "subscription" | "timeCapsule" | "badge",
  storage?: number
) => {
  const isDevelopment = import.meta.env.MODE === "development";
  const productsList = isDevelopment ? DevProducts : ProdProducts;

  const product = productsList.find((product) => {
    if (storage) {
      return product.plan === plan && product.storage === storage;
    } else {
      return product.plan === plan;
    }
  });
  return product;
};

export const PlanProducts: {
  [key: string]: {
    [key: number]: (typeof DevProducts)[0] | undefined;
  };
} = {
  payOnce: {
    50: findProduct("payOnce", 50),
    40: findProduct("payOnce", 40),
    30: findProduct("payOnce", 30),
    20: findProduct("payOnce", 20),
    10: findProduct("payOnce", 10),
  },
  subscription: {
    50: findProduct("subscription", 50),
    40: findProduct("subscription", 40),
    30: findProduct("subscription", 30),
    20: findProduct("subscription", 20),
    10: findProduct("subscription", 10),
  },
  timeCapsule: {
    5: findProduct("timeCapsule", 5),
  },
};

export const BadgeProduct = findProduct("badge");
