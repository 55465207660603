import { Text } from "../../../common";
import { Image } from "../../../types/image.type";

const ImageCaption = ({ image }: { image: Image }) => {
  return (
    <div className="absolute md:left-6 left-4 bottom-4 md:bottom-6 right-4 md:right-auto p-6 backdrop-blur-sm bg-black/60 z-50 md:max-w-[380px] rounded-xl">
      {image.description && (
        <Text className="pb-4" size="xl" color="white">
          {image.description}
        </Text>
      )}
      {image.contributor && (
        <Text size="lg" color="white">
          {image.contributor}
        </Text>
      )}
    </div>
  );
};

export default ImageCaption;
