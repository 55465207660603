import { IconChevronDown } from "@tabler/icons-react";
import { Text } from ".";
import { useMemo, useState } from "react";

const Accordion = ({
  elements,
}: {
  elements: {
    title: string;
    description: string | React.ReactNode;
  }[];
}) => {
  const uniqueId = useMemo(
    () => `accordion-${Date.now()}-${Math.floor(Math.random() * 1000)}`,
    []
  );
  const [selectedId, setSelectedId] = useState<string>();

  const handleRadioChange = (itemId: string) => {
    setSelectedId(selectedId === itemId ? undefined : itemId);
  };

  return (
    <div className="w-full mt-6 md:mt-0 flex flex-col gap-6">
      {elements.map((element, index) => {
        const itemId = `${uniqueId}-${index}`;
        return (
          <div key={index} className="group md:rounded-xl bg-white p-6">
            <input
              type="radio"
              id={itemId}
              checked={selectedId === itemId}
              onChange={() => handleRadioChange(itemId)}
              name={`accordion-${uniqueId}`}
              className="peer/accordion hidden"
              onClick={() => handleRadioChange(itemId)}
            />
            <label
              htmlFor={itemId}
              className="cursor-pointer flex justify-between items-center gap-14"
            >
              <Text className="font-semibold" size="lg">
                {element.title}
              </Text>
              <div>
                <IconChevronDown className="group-has-[input:checked]:rotate-180 transition-transform duration-300 ease-in-out" />
              </div>
            </label>
            <div className="peer-checked/accordion:max-h-[1000px] max-h-0 overflow-hidden transition-all duration-300">
              <div className="mt-3">
                {typeof element.description === "string" ? (
                  <Text color="gray">{element.description}</Text>
                ) : (
                  element.description
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Accordion;
