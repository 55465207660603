import Lottie from "lottie-react";
import animation from "../assets/loading.json";

const Loading = () => {
  return (
    <Lottie
      animationData={animation}
      loop
      autoPlay
      className=" w-[200px] h-[150px] mx-auto"
    />
  );
};

export default Loading;
