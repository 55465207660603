import { VirtualItem } from "@tanstack/react-virtual";
import { IEdgeType } from "../../types/paginated.type";
import { Image } from "../../types/image.type";
import React from "react";
import { Text } from "..";
import { Checkbox } from "@mantine/core";
import { ImageActions, ImageCommentIcon, Play } from ".";
import { ImageType } from "../../types/image-type.enum";
import { Album } from "../../types/album.type";
import { getImageUrl } from "../../lib/utils";

interface ImageGridItemProps {
  totalColumnCount: number;
  virtualRow: VirtualItem;
  withCaptions: boolean;
  item: IEdgeType<Image>;
  itemWidth: number;
  itemHeight: number;
  handleImageSelect: (id: string) => void;
  selectedImages: string[];
  isSmallScreen: boolean;
  itemsLength: number;
  measureElement: any;
  onClick: () => void;
  onUpdated: (image: Image) => void;
  onRemoved: (id: string) => void;
  album: Album;
  withContributors?: boolean;
  albumMode: boolean;
}

const MemorizedImage = React.memo(
  ({ src, alt }: { src: string; alt: string }) => (
    <img
      className="absolute inset-0 w-full h-full object-cover"
      src={src}
      alt={alt}
    />
  )
);

MemorizedImage.displayName = "MemorizedImage";

const ImageCheckbox = ({
  albumMode,
  item,
  selectedImages,
  handleImageSelect,
  isSmallScreen,
}: {
  albumMode: boolean;
  item: IEdgeType<Image>;
  selectedImages: string[];
  handleImageSelect: (id: string) => void;
  isSmallScreen: boolean;
}) => {
  if (albumMode) return null;
  return (
    <Checkbox
      size="md"
      className="absolute top-4 left-4"
      styles={{
        input: {
          border: selectedImages.includes(item.node.id)
            ? "none"
            : "1px solid #ababab",
          borderRadius: 4,
          background: selectedImages.includes(item.node.id)
            ? "#222222"
            : isSmallScreen
            ? "rgba(255, 255, 255, 0.4)"
            : "white",
        },
      }}
      onClick={(e) => e.stopPropagation()}
      color="#222222"
      checked={selectedImages.includes(item.node.id)}
      onChange={() => handleImageSelect(item.node.id)}
    />
  );
};

const ImageDescription = ({
  item,
  withCaptions,
  withContributors,
}: {
  item: IEdgeType<Image>;
  withCaptions: boolean;
  withContributors?: boolean;
}) => {
  if (!withCaptions || !item.node.description) return null;
  return (
    <div className="px-3 pb-2 grid-item-caption">
      <Text className="line-clamp-5">{item.node.description}</Text>
      {withContributors && (
        <Text
          wrap="nowrap"
          color="gray"
          className="mt-2 text-ellipsis overflow-hidden"
        >
          {item.node.contributor}
        </Text>
      )}
    </div>
  );
};

const ImageGridItem: React.FC<ImageGridItemProps> = ({
  albumMode,
  virtualRow,
  withCaptions,
  item,
  itemWidth,
  itemHeight,
  handleImageSelect,
  selectedImages,
  itemsLength,
  isSmallScreen,
  onUpdated,
  onRemoved,
  measureElement,
  onClick,
  album,
  withContributors,
  totalColumnCount,
}) => {
  const index = virtualRow.index;
  if (index >= itemsLength) return null;

  const thumbnailUrl = getImageUrl(item.node.thumbnailUrl);
  const showDescription = withCaptions && !!item.node.description;
  const imageVariableHeight = itemHeight;
  const withLeftGap = !(virtualRow.lane % totalColumnCount === 0); //if not first column, add left gap

  return (
    <div
      key={virtualRow.key}
      data-index={virtualRow.index}
      ref={measureElement}
      onClick={onClick}
      className="mb-2 grid-item cursor-pointer group flex flex-col rounded-lg"
      style={{
        transition: "transform 0.3s ease",
        position: "absolute",
        top: 0,
        left: 0,
        width: itemWidth,
        minHeight: imageVariableHeight,
        transform: `translateX(${
          itemWidth * virtualRow.lane + (withLeftGap ? virtualRow.lane * 16 : 0)
        }px) translateY(${virtualRow.start}px)`,
        overflow: "hidden",
      }}
    >
      <div
        className={`${
          showDescription
            ? "bg-white border border-light-gray shadow-sm rounded-lg overflow-hidden"
            : "rounded-lg overflow-hidden"
        }`}
      >
        <div
          style={{
            width: itemWidth - (showDescription ? 12 * 2 : 0),
            minHeight: imageVariableHeight - (showDescription ? 12 * 2 : 0),
          }}
          className={`flex-1 ${
            showDescription && "m-3"
          } relative rounded-lg overflow-hidden`}
        >
          <MemorizedImage
            src={thumbnailUrl}
            alt={item.node.description ?? ""}
          />
          {item.node.type === ImageType.VIDEO && <Play />}
          {item.node.description && !withCaptions && <ImageCommentIcon />}
          <div className="md:group-hover:block hidden">
            <ImageActions
              album={album}
              image={item.node}
              onUpdated={onUpdated}
              onRemoved={onRemoved}
            />
          </div>
          <ImageCheckbox
            albumMode={albumMode}
            item={item}
            selectedImages={selectedImages}
            handleImageSelect={handleImageSelect}
            isSmallScreen={isSmallScreen}
          />
        </div>
        <ImageDescription
          item={item}
          withCaptions={withCaptions}
          withContributors={withContributors}
        />
      </div>
    </div>
  );
};

export default ImageGridItem;
