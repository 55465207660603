import { useTranslations } from "use-intl";
import { Button, SettingsBlock, Text } from "../../../common";
import { useMutation } from "@apollo/client";
import { deleteAccountMutation } from "../../../graphql/userQueries";
import { Modal } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { ErrorService } from "../../../services";
import { useDispatch } from "react-redux";
import { setUser } from "../../../store/user.reducer";
import { PasswordResetModal } from ".";
import { useAuth } from "../../../hooks";
import Google from "../../../assets/google.svg?react";

const SecuritySettings = () => {
  const t = useTranslations("Account");
  const { user } = useAuth();
  const [opened, { open, close }] = useDisclosure(false);
  const [
    passwordUpdateOpened,
    { open: openPasswordUpdate, close: closePasswordUpdate },
  ] = useDisclosure(false);

  const dispatch = useDispatch();

  const [deleteAccount, { loading }] = useMutation<{ deleteAccount: Boolean }>(
    deleteAccountMutation
  );

  const handleDelete = () => {
    deleteAccount()
      .then((resp) => {
        if (resp.data?.deleteAccount) {
          ErrorService.showMessage(t("accountDeleted"));
          dispatch(setUser(null));
        } else {
          ErrorService.showError(t("errorDeletingAccount"));
        }
      })
      .catch((_) => {
        ErrorService.showError(t("errorDeletingAccount"));
      });
  };

  return (
    <SettingsBlock title={t("security")} description={t("securityDescription")}>
      <Modal
        radius={8}
        withCloseButton={false}
        opened={opened}
        onClose={close}
        centered
      >
        <Text color="gray">{t("sureToDelete")}</Text>
        <div className="flex items-end justify-end gap-2 mt-6">
          <Button
            small
            onClick={() => close()}
            type="outline"
            title={t("cancel")}
          />
          <Button
            loading={loading}
            small
            onClick={handleDelete}
            color="red"
            title={t("remove")}
          />
        </div>
      </Modal>
      {user.googleId && (
        <div>
          <Text color="gray" className="mb-4 flex">
            {t("servicesUsedToLogin")}
          </Text>
          <div className="flex gap-4">
            <Google />
            <Text className="flex">
              Google
              <span className="ml-2 text-medium-black">
                (ID: {user.googleId})
              </span>
            </Text>
          </div>
        </div>
      )}
      <div>
        <Text color="gray" className="mb-4">
          {t("password")}
        </Text>
        <Button
          type="outline"
          onClick={openPasswordUpdate}
          title={t("editPassword")}
        />
      </div>
      <PasswordResetModal
        opened={passwordUpdateOpened}
        close={closePasswordUpdate}
      />
      <div>
        <Text color="gray" className="mb-4">
          {t("deleteAccount")}
        </Text>
        <Button
          title={t("deleteAccount")}
          type="outline"
          onClick={() => open()}
        />
        <Text className="mt-4">
          {t.rich("deleteWarning", {
            b: (chunks) => <span className="font-semibold">{chunks}</span>,
          })}
        </Text>
      </div>
    </SettingsBlock>
  );
};

export default SecuritySettings;
