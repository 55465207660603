import { EventType } from "./event.enum";
import { Hashtag } from "./hashtag.type";
import { Plan } from "./plan.enum";
import { User } from "./user.type";

export type Album = {
  id: string;
  shortId: string;
  imageCount?: number;
  user: User;
  name: string;
  description?: string;
  color: string;
  bannerColor: string;
  type: EventType;
  public: boolean;
  requireApproval: boolean;
  maxSize: number;
  plan: Plan;
  expirationDate?: Date;
  activationDate?: Date;
  invitationDate?: Date;
  createdAt: Date | string;
  updatedAt: Date | string;
  passwordProtected: boolean;
  password?: string;
  logo?: string;
  profileImage?: string;
  profileThumbnail?: string;
  yearsFrom?: Date;
  yearsTo?: Date;
  videoDuration: number;
  imageDuration: number;
  displayCaption: boolean;
  displayContributor: boolean;
  wallDisplayCode: boolean;
  wallDisplayCaptions: boolean;
  eventDate?: Date;
  layout?: Layout;
  passwordInInvite: boolean;
  cleanupDate?: Date;
  hashtags?: Hashtag[];
  allowCreateHashtags?: boolean;
  displayMoreActionsButton?: boolean;
  displayPhotowallButton?: boolean;
  removeBranding?: boolean;
  showLogoOnAlbum?: boolean;
  optimizeImages?: boolean;
};

export type CreateAlbumInput = {
  color?: string;
  bannerColor?: string;
  description?: string;
  name: string;
  public: boolean;
  type: EventType;
  password?: string;
  optimizeImages?: boolean;
  passwordInInvite?: boolean;
  requireApproval?: boolean;
  yearsFrom?: Date;
  yearsTo?: Date;
  invitationDate?: Date;
  videoDuration?: number;
  imageDuration?: number;
  displayCaption?: boolean;
  displayContributor?: boolean;
  eventDate?: Date;
  layout?: Layout;
  wallDisplayCode?: boolean;
  wallDisplayCaptions?: boolean;
  hashtags?: {
    id: string;
    title: string;
  }[];
  allowCreateHashtags?: boolean;
  displayMoreActionsButton?: boolean;
  displayPhotowallButton?: boolean;
  removeBranding?: boolean;
  showLogoOnAlbum?: boolean;
};

export type UpdateAlbumInput = {
  id: string;
} & Partial<CreateAlbumInput>;

export type AdminUpdateAlbumInput = {
  id: string;
  plan?: Plan;
  expirationDate?: Date;
  maxSize?: number;
} & Partial<CreateAlbumInput>;

export type AlbumSizes = {
  maxSize: number;
  currentSize: number;
};

export enum Layout {
  DOUBLE = "DOUBLE",
  SINGLE = "SINGLE",
  CARD = "CARD",
  CIRCLE = "CIRCLE",
}
