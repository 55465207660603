import { useTranslations } from "use-intl";
import {
  Button,
  InfoToolitp,
  PasswordInput,
  SettingsBlock,
  Text,
  Toggle,
} from "../";
import { useFormikContext } from "formik";
import { Group, Modal, Radio } from "@mantine/core";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useDisclosure } from "@mantine/hooks";
import { useMutation } from "@apollo/client";
import { Album, CreateAlbumInput } from "../../types/album.type";
import { useAuth, useLocalizedNavigate } from "../../hooks";
import { deleteAlbumMutation } from "../../graphql/albumQueries";
import { ErrorService } from "../../services";
import { requestAlbumsRefresh } from "../../store/album.reducer";
import { useLocation } from "react-router-dom";

const PrivacySettings = ({
  handleFieldChange,
  album,
}: {
  album?: Album;
  handleFieldChange: (e: React.ChangeEvent<any> | string, value?: any) => void;
}) => {
  const { user } = useAuth();
  const t = useTranslations("AlbumSettings");
  const formik = useFormikContext<CreateAlbumInput>();
  const [opened, { open, close }] = useDisclosure(false);
  const dispatch = useDispatch();
  const navigate = useLocalizedNavigate();
  const location = useLocation();

  const [deleteAlbum, { loading }] = useMutation<
    { removeAlbum: boolean },
    { id: string }
  >(deleteAlbumMutation);

  const handleDelete = () => {
    if (album) {
      deleteAlbum({
        variables: {
          id: album.id,
        },
      })
        .then((resp) => {
          if (resp.data?.removeAlbum) {
            ErrorService.showMessage(t("albumDeleted"));
            close();
            if (album.user.id === user.id) {
              dispatch(requestAlbumsRefresh());
            }

            // Check if the URL contains "admin"
            if (location.pathname.includes("admin")) {
              navigate(-1); // Navigate back if in admin mode
            } else {
              navigate("/");
            }
          } else {
            ErrorService.showError(t("errorDeletingAlbum"));
          }
        })
        .catch((_) => {
          ErrorService.showError(t("errorDeletingAlbum"));
        });
    }
  };

  const [withPassword, setWithPassword] = useState(
    album?.passwordProtected ?? false
  );

  useEffect(() => {
    setWithPassword(album?.passwordProtected ?? false);
  }, [album]);

  const isFree = album?.plan === "free";

  return (
    <SettingsBlock
      id="privacy"
      description={t("privacyDescription")}
      premium={isFree}
    >
      <Modal
        radius={8}
        withCloseButton={false}
        opened={opened}
        onClose={close}
        centered
      >
        <Text color="gray">{t("sureToDelete")}</Text>
        <div className="flex items-end justify-end gap-2 mt-6">
          <Button
            small
            onClick={() => close()}
            type="outline"
            title={t("cancel")}
          />
          <Button
            loading={loading}
            small
            onClick={handleDelete}
            color="red"
            title={t("delete")}
          />
        </div>
      </Modal>
      <Radio.Group
        value={String(formik.values.requireApproval)}
        onChange={(val) =>
          handleFieldChange("requireApproval", JSON.parse(val))
        }
        name="requireApproval"
      >
        <Text className="mb-4" color="gray">
          <span>
            {t("requireApprovalDescription")}
            <span className=" whitespace-nowrap">
              <InfoToolitp text={t("moderationTooltip")} />
            </span>
          </span>
        </Text>
        <Group>
          <Radio
            disabled={isFree}
            variant="outline"
            color="#222222"
            styles={{ label: { fontSize: 16 } }}
            value={"true"}
            label={t("requireApproval")}
          />
          <Radio
            disabled={isFree}
            variant="outline"
            color="#222222"
            styles={{ label: { fontSize: 16 } }}
            value={"false"}
            label={t("dontRequireApproval")}
          />
        </Group>
      </Radio.Group>

      <Radio.Group
        value={String(formik.values.public)}
        onChange={(val) => handleFieldChange("public", JSON.parse(val))}
        name="public"
      >
        <Text className="mb-4 flex gap-1 items-center" color="gray">
          <span>
            {t("publicDescription")}
            <span className=" whitespace-nowrap">
              <InfoToolitp text={t("actionsTooltip")} />
            </span>
          </span>
        </Text>
        <Group>
          <Radio
            disabled={isFree}
            variant="outline"
            color="#222222"
            styles={{ label: { fontSize: 16 } }}
            value={"false"}
            label={t("view")}
          />
          <Radio
            disabled={isFree}
            variant="outline"
            color="#222222"
            styles={{ label: { fontSize: 16 } }}
            value={"true"}
            label={t("upload")}
          />
        </Group>
      </Radio.Group>

      <Radio.Group
        value={String(withPassword)}
        onChange={(val) => {
          const addPassword = JSON.parse(val);
          if (!addPassword) {
            handleFieldChange("password", null);
          }
          setWithPassword(addPassword);
        }}
        name="withPassword"
      >
        <Text className="mb-4 flex gap-1 items-center" color="gray">
          <span>
            {t("withPassword")}
            <span className=" whitespace-nowrap">
              <InfoToolitp text={t("passwordTooltip")} />
            </span>
          </span>
        </Text>
        <Group>
          <Radio
            disabled={isFree}
            variant="outline"
            color="#222222"
            styles={{ label: { fontSize: 16 } }}
            value={"false"}
            label={t("none")}
          />
          <Radio
            disabled={isFree}
            variant="outline"
            color="#222222"
            styles={{ label: { fontSize: 16 } }}
            value={"true"}
            label={t("addPassword")}
          />
        </Group>
      </Radio.Group>

      {withPassword && (
        <PasswordInput
          value={formik.values.password ?? ""}
          name="password"
          label={t("password")}
          onChange={handleFieldChange}
          error={formik.errors.password}
        />
      )}
      {withPassword && (
        <div className="flex gap-1 items-center">
          <Toggle
            fontSize={16}
            label={t("includePassword")}
            checked={formik.values.passwordInInvite ?? true}
            onChange={(value) => handleFieldChange("passwordInInvite", value)}
          />
          <InfoToolitp text={t("includePasswordTooltip")} />
        </div>
      )}
      <div>
        <Text color="gray" className="mb-4">
          {t("deleteAlbum")}
        </Text>
        <Button
          title={t("deleteAlbum")}
          type="outline"
          onClick={() => open()}
        />
        <Text className="mt-4">
          {t.rich("deleteWarning", {
            b: (chunks) => <span className="font-semibold">{chunks}</span>,
          })}
        </Text>
      </div>
    </SettingsBlock>
  );
};

export default PrivacySettings;
