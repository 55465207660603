import { useTranslations } from "use-intl";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { EventType } from "../../types/event.enum";
import { Album, CreateAlbumInput } from "../../types/album.type";
import {
  createAlbumMutation,
  userAlbumsQuery,
} from "../../graphql/albumQueries";
import { ErrorService } from "../../services";
import { FirstStep, SecondStep, ThirdStep, FourthStep } from "./components";
import { COLORS } from "../../common/AlbumColorPicker";
import { useDispatch, useSelector } from "react-redux";
import { setAlbum as setAlbumGlobal } from "../../store/album.reducer";
import { PaginatedType } from "../../types/paginated.type";
import { RootState } from "../../store";

export type OnboardingValues = {
  name: string;
  type: EventType;
  color: (typeof COLORS)[keyof typeof COLORS];
  public: boolean;
};

const Onboarding = () => {
  const stateAlbum = useSelector(
    (state: RootState) => state.album.album
  ) as Album;
  const [step, setStep] = useState<number>(1);
  const dispatch = useDispatch();
  const t = useTranslations();

  const goNext = () => setStep((currentStep) => currentStep + 1);

  const [createAlbum, { loading: albumCreating }] = useMutation<
    { createAlbum: Album },
    { input: CreateAlbumInput }
  >(createAlbumMutation);

  const { data: existingAlbum } = useQuery<
    { userAlbums: PaginatedType<Album> },
    { first?: number; after?: string }
  >(userAlbumsQuery, {
    variables: {
      first: 1,
    },
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (existingAlbum?.userAlbums && existingAlbum?.userAlbums.totalCount > 0) {
      const album = existingAlbum.userAlbums.nodes[0];
      dispatch(setAlbumGlobal(album));
      setStep(3);
    }
  }, [existingAlbum]);

  const onSubmit = (values: CreateAlbumInput) => {
    createAlbum({ variables: { input: values } })
      .then((response) => {
        response.data?.createAlbum &&
          dispatch(setAlbumGlobal(response.data?.createAlbum));
        goNext();
      })
      .catch(() => {
        ErrorService.showError(t("Album.errorOnAlbumCreation"));
      });
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return <FirstStep goNext={goNext} />;
      case 2:
        return <SecondStep loading={albumCreating} goNext={goNext} />;
      case 3:
        return stateAlbum ? (
          <ThirdStep album={stateAlbum} goNext={goNext} />
        ) : null;
      case 4:
        return <FourthStep />;
      default:
        return null;
    }
  };

  return (
    <Formik
      initialValues={{
        name: "",
        type: EventType.OTHER,
        color: COLORS["light-gray"],
        public: true,
      }}
      onSubmit={onSubmit}
      validateOnChange={false}
      enableReinitialize
    >
      {renderStep}
    </Formik>
  );
};

export default Onboarding;
