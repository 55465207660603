import { useEffect, useRef } from "react";

const Trustpilot = ({
  template = "5419b732fbfb950b10de65e5",
}: {
  template?: string;
}) => {
  // Create a reference to the <div> element which will represent the TrustBox

  const ref = useRef(null);
  useEffect(() => {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
    // When it is, it will automatically load the TrustBox.
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);
  return (
    <div className="flex justify-start items-center md:-ml-[13px] pb-4">
      <div
        ref={ref}
        className="trustpilot-widget"
        data-locale="en-US"
        data-template-id={template}
        data-businessunit-id="66265cf2a21a8d427174970a"
        data-style-height="24px"
        data-style-width="100%"
        data-theme="light"
      >
        <a
          href="https://www.trustpilot.com/review/rompolo.com"
          target="_blank"
          rel="noopener"
        >
          Trustpilot
        </a>
      </div>
    </div>
  );
};
export default Trustpilot;
