import { Album, AlbumSizes } from "../types/album.type";
import { useTranslations } from "use-intl";
import { Text } from ".";
import { Plan } from "../types/plan.enum";
import { LocalizedLink } from "../router";
import { Progress } from "@mantine/core";
import { TextColor, TextSize } from "../types/text.type";

const AlbumSize = ({
  sizes,
  album,
  withBar,
  textSize = "base",
  textColor = "black",
  withStorageWord = true,
}: {
  textSize?: TextSize;
  sizes: AlbumSizes;
  album: Album;
  withBar?: boolean;
  textColor?: TextColor;
  withStorageWord?: boolean;
}) => {
  const t = useTranslations("Photos");

  //Take sizes and convert them to gb as sizes are in bytes
  const maxSize = sizes.maxSize / 1024 / 1024 / 1024;
  const currentSize = sizes.currentSize / 1024 / 1024 / 1024;

  //Calculate percentage of used space
  const percentage = (currentSize / maxSize) * 100;

  return (
    <div>
      {withBar && (
        <Progress color="#222222" className="mb-2" value={percentage} />
      )}
      <Text color={textColor} size={textSize} wrap="nowrap">
        {withStorageWord && <span className="mr-1">{t("storageWord")}</span>}
        {t.rich("storage", {
          current: () => currentSize.toFixed(2),
          max: () => maxSize.toFixed(1),
          percentage: () => percentage.toFixed(0) + "%",
        })}
        {album.plan === Plan.free && (
          <LocalizedLink
            to="/pricing"
            className="font-semibold ml-2 hover:underline"
          >
            {t("upgrade")}
          </LocalizedLink>
        )}
      </Text>
    </div>
  );
};

export default AlbumSize;
