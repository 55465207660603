import { useTranslations } from "use-intl";
import { InfoToolitp, Input, SettingsBlock, Text } from "../../../common";
import { useFormikContext } from "formik";
import { UpdateUserInput } from "../../../types/user.type";
import { Checkbox } from "@mantine/core";
import { useEffect, useState } from "react";

const GeneralSettings = () => {
  const t = useTranslations("Account");
  const formik = useFormikContext<UpdateUserInput>();
  const [cookiesAccepted, setCookiesAccepted] = useState<boolean>(false);

  useEffect(() => {
    if ((window as any).CookieScript && (window as any).CookieScript.instance) {
      const state = (window as any).CookieScript.instance.currentState();
      const status = state.action === "accept";
      setCookiesAccepted(status);
    }
  }, []);

  const handleCookieConsentChange = (accept: boolean) => {
    const cookieScript = (window as any).CookieScript.instance;
    if (cookieScript) {
      if (accept) {
        cookieScript.acceptAllAction();
        setCookiesAccepted(true);
      } else {
        cookieScript.rejectAllAction();
        setCookiesAccepted(false);
      }
    }
  };

  return (
    <SettingsBlock title={t("general")} description={t("generalDescription")}>
      <Input
        value={formik.values.fullName}
        name="fullName"
        label={t("fullName")}
        onChange={formik.handleChange}
        error={formik.errors.fullName}
      />
      <Input
        value={formik.values.email}
        name="email"
        label={t("email")}
        onChange={formik.handleChange}
        error={formik.errors.email}
      />
      <div>
        <Text color="gray" className="mb-4">
          {t("whichTopics")}
        </Text>
        <Checkbox
          name="newsAndReleases"
          checked={formik.values.newsAndReleases}
          onChange={formik.handleChange}
          styles={{
            label: {
              fontSize: 16,
            },
          }}
          color="#222222"
          label={t("newsAndReleases")}
          className="mt-4"
        />
      </div>
      <div>
        <Text color="gray" className="mb-4">
          {t("wouldAccpetCookies")}
          <InfoToolitp text={t("cookieTooltip")} />
        </Text>
        <Checkbox
          checked={cookiesAccepted}
          onChange={(event) => handleCookieConsentChange(event.target.checked)}
          styles={{
            label: {
              fontSize: 16,
            },
          }}
          color="#222222"
          label={t("acceptAllCookies")}
          className="mt-4"
        />
      </div>
    </SettingsBlock>
  );
};

export default GeneralSettings;
