import Text from "./Text";

const HashtagBadge = ({
  title,
  onClick,
  rightBlock,
  selected = false,
  disabled = false,
  withStroke = false,
}: {
  title: string;
  onClick?: () => void;
  rightBlock?: React.ReactNode;
  selected?: boolean;
  disabled?: boolean;
  withStroke?: boolean;
}) => {
  return (
    <div
      onClick={() => {
        onClick && !disabled && onClick();
      }}
      className={`
      ${disabled ? "cursor-not-allowed text-medium-black" : "cursor-pointer"}
      ${
        selected
          ? "border border-black"
          : `${withStroke ? "border border-light-beige" : ""}`
      } flex items-center gap-2 px-3 py-1 rounded-lg bg-light-gray`}
    >
      <div>
        <Text wrap="nowrap">{title}</Text>
      </div>
      <div className="flex gap-1">{rightBlock}</div>
    </div>
  );
};

export default HashtagBadge;
