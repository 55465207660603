import React from "react";

interface LinkifyTextProps {
  text: string;
}

const LinkifyText: React.FC<LinkifyTextProps> = ({ text }) => {
  // Enhanced regular expression to correctly terminate URLs before non-URL characters
  const urlRegex =
    /(\bhttps?:\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;

  // Function to parse text and convert URLs into anchor tags, ensuring that trailing parts are not incorrectly linked
  const linkifyText = (inputText: string): JSX.Element[] => {
    const parts = inputText.split(urlRegex);

    return parts.map((part, index) => {
      if (part.match(urlRegex)) {
        return (
          <a
            key={index}
            className="hover:underline"
            href={part}
            target="_blank"
            rel="noopener noreferrer"
          >
            {part}
          </a>
        );
      } else {
        return <span key={index}>{part}</span>;
      }
    });
  };

  return <>{linkifyText(text)}</>;
};

export default LinkifyText;
