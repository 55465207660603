import { useTranslations } from "use-intl";
import { Button, Logo, QRCode, Text, Title } from "../../../common";
import { Album } from "../../../types/album.type";
import { useEffect, useState } from "react";
import { FileButton, Progress } from "@mantine/core";
import axiosClient from "../../../lib/axiosClient";
import { ErrorService } from "../../../services";
import { compressImage } from "../../../lib/fileCompressor";
import { useMutation } from "@apollo/client";
import { User } from "../../../types/user.type";
import { updateUserMutation } from "../../../graphql/userQueries";
import { useDispatch } from "react-redux";
import { setUser } from "../../../store/user.reducer";
import { showConfetti } from "../../../store/confetti.reducer";

const ThirdStep = ({ goNext, album }: { album: Album; goNext: () => void }) => {
  const albumUrl = `${window.location.origin}/album/${album?.shortId}`;
  const t = useTranslations();
  const dispatch = useDispatch();
  const [updateUser] = useMutation<
    { updateUser: User },
    {
      input: { onboarded: Date };
    }
  >(updateUserMutation);

  const handleFinishOnboarding = async () => {
    try {
      const resp = await updateUser({
        variables: {
          input: {
            onboarded: new Date(),
          },
        },
      });
      dispatch(setUser(resp.data?.updateUser ?? null));
      dispatch(showConfetti(true));
    } catch (error) {
      ErrorService.showError(t("Onboarding.errorOnObarordingFinish"));
    }
  };

  const [files, setFiles] = useState<File[]>([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const handleUpload = async () => {
      if (!files) return;

      const formData = new FormData();

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        try {
          // Check if the file is JPEG or PNG
          if (/\.(jpe?g|png)$/i.test(file.name)) {
            const compressedFile = await compressImage(file);
            compressedFile &&
              formData.append("files", compressedFile, file.name);
          } else {
            // If the file is not JPEG or PNG, append it as is
            formData.append("files", file, file.name);
          }
        } catch (error) {
          console.error("Error processing file:", file.name, error);
        }
      }
      try {
        setLoading(true);
        const response = await axiosClient.post(
          `/image/${album.id}`,
          formData,
          {
            onUploadProgress: (progressEvent) => {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / (progressEvent?.total ?? 0)
              );
              setUploadProgress(percentCompleted);
            },
          }
        );
        if (response.data) {
          goNext();
        }
      } catch (error) {
        ErrorService.showError(t("Files.errorUploading"));
      } finally {
        setLoading(false);
      }
    };

    if (files && files.length > 0) {
      handleUpload();
    }
  }, [files]);

  return (
    <div style={{ height: "100dvh" }} className="flex bg-white">
      <div className="flex-1 flex flex-col">
        <div className="flex flex-1 justify-center">
          <div className="md:pt-10 pt-4 md:max-w-[340px] px-4 flex-1 flex-col flex">
            <Logo />
            <div className="flex-1 flex flex-col md:justify-center mt-14 md:mt-0">
              <Title size="H3">{t("Onboarding.letsStart")}</Title>
              <Text color="gray" className="pt-2 hidden md:block">
                {t("Onboarding.useQr")}
              </Text>
              <Text color="gray" className="pt-2 md:hidden">
                {t("Onboarding.useQrMobile")}
              </Text>
              <Text color="gray" className=" pt-4 pb-10">
                {t("Onboarding.ps")}
              </Text>
              <div className="flex-1 md:flex-none md:justify-start flex flex-col justify-end">
                {uploadProgress > 0 && (
                  <div className=" py-4">
                    <Progress color="#222222" value={uploadProgress} />
                  </div>
                )}
                <div>
                  <FileButton
                    onChange={setFiles}
                    accept="image/png,image/jpeg,image/gif,video/heic,video/mp4,video/avi,video/mkv,video/quicktime"
                    multiple
                  >
                    {(props) => (
                      <Button
                        loading={loading}
                        {...props}
                        title={t("Onboarding.upload")}
                      />
                    )}
                  </FileButton>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="justify-center items-start flex md:items-end md:justify-end md:flex-col pt-4 px-4 md:px-10 pb-10 md:pt-0">
          <Button
            type="outline"
            title={t("Onboarding.doLater")}
            onClick={handleFinishOnboarding}
          />
        </div>
      </div>
      <div className="hidden md:flex bg-cover bg-no-repeat flex-1 bg-gradient-pattern justify-center items-center">
        <div>
          <QRCode url={albumUrl} size={200} />
          <Text color="gray" className=" mt-8">
            {t("Onboarding.scanToExplore")}
          </Text>
        </div>
      </div>
    </div>
  );
};

export default ThirdStep;
