import { CSSProperties } from "react";
import { Image } from "../../types/image.type";
import Text from "../Text";
import HashtagBadge from "../HashtagBadge";

const ImageDetailsDesktop = ({
  style,
  image,
}: {
  style?: CSSProperties;
  image: Image;
}) => {
  return (
    <div style={style} className=" bg-white mt-6">
      <div className="flex flex-col items-center">
        <Text className=" text-center font-semibold">{image.description}</Text>
        <div className="flex flex-wrap mt-4 gap-4">
          {image.hashtagOnImages?.map((hoi) => (
            <HashtagBadge key={hoi.hashtag.id} title={hoi.hashtag.title} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ImageDetailsDesktop;
