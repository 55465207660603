import { IEdgeType } from "../../types/paginated.type";
import { Image } from "../../types/image.type";
import { IconChevronLeft, IconChevronRight } from "@tabler/icons-react";

const GalleryArrows = ({
  prevImage,
  nextImage,
  currentIndex,
  images,
}: {
  currentIndex: number;
  images: IEdgeType<Image>[];
  prevImage: () => void;
  nextImage: () => void;
}) => {
  return (
    <div className="absolute left-0 top-[50%] h-10 -mt-5 flex w-full justify-between z-50 items-center">
      <div
        onClick={prevImage}
        className={`rounded-md flex justify-center items-center cursor-pointer md:-ml-20 p-8 py-10 ${
          currentIndex <= 0 ? "invisible" : ""
        }`}
      >
        <IconChevronLeft width={32} height={32} className="stroke-white" />
      </div>
      <div
        onClick={nextImage}
        className={`flex justify-center items-center cursor-pointer md:-mr-20 p-8 py-10 ${
          currentIndex >= images.length - 1 ? "invisible" : ""
        }`}
      >
        <IconChevronRight width={32} height={32} className="stroke-white" />
      </div>
    </div>
  );
};

export default GalleryArrows;
