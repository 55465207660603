import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface ConfettiState {
  showConfetti: boolean;
}

const initialState: ConfettiState = { showConfetti: false };

const ConfettiSlice = createSlice({
  name: "confetti",
  initialState,
  reducers: {
    showConfetti(state, action: PayloadAction<boolean>) {
      state.showConfetti = action.payload;
    },
  },
});

export const { showConfetti } = ConfettiSlice.actions;
export default ConfettiSlice.reducer;
