import { useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";
import { useTranslations } from "use-intl";
import { UpdateUserInput, User } from "../../types/user.type";
import { updateUserMutation } from "../../graphql/userQueries";
import { ErrorService } from "../../services";
import { useAuth, useConfirmNavigationWithSave } from "../../hooks";
import * as Yup from "yup";
import { Formik } from "formik";
import { Button, Text, Title } from "../../common";
import { setUser } from "../../store/user.reducer";
import { GeneralSettings, SecuritySettings } from "./components";
import { Helmet } from "react-helmet-async";

const AccountPage = () => {
  const t = useTranslations("Account");
  const tV = useTranslations("Validation");

  const { user } = useAuth();
  const dispatch = useDispatch();
  const [updateUser, { loading }] = useMutation<
    { updateUser: User },
    { input: UpdateUserInput }
  >(updateUserMutation);

  const onSubmit = (values: UpdateUserInput) => {
    updateUser({ variables: { input: values } })
      .then((response) => {
        const updatedUser = response.data?.updateUser;
        if (updatedUser) {
          dispatch(setUser(updatedUser));
          ErrorService.showMessage(t("userUpdated"));
        } else {
          ErrorService.showError(t("errorOnUpdate"));
        }
      })
      .catch(() => {
        ErrorService.showError(t("errorOnUpdate"));
      });
  };

  return (
    <>
      <Helmet>
        <title>Rompolo - {t("settings")}</title>
      </Helmet>
      <Formik
        initialValues={{
          fullName: user.fullName ?? "",
          email: user.email ?? "",
          updatesOnContributions: user.updatesOnContributions ?? true,
          newsAndReleases: user.newsAndReleases ?? true,
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email(tV("invalidEmail"))
            .required(tV("required")),
          fullName: Yup.string().min(2).required(tV("required")),
        })}
        onSubmit={onSubmit}
        validateOnChange={false}
        enableReinitialize
      >
        {(props) => {
          //Block navigation when form is dirty
          useConfirmNavigationWithSave(props.dirty, () => {
            props.submitForm();
          });
          return (
            <div className="flex-1 md:px-6 flex flex-col max-w-[1000px]">
              <div className="md:mb-6 mb-4 md:px-0 px-4">
                <Title size="H4">{t("yourAccount")}</Title>
                <Text className="mt-2" color="gray">
                  {t("yourAccountDescription")}
                </Text>
              </div>

              <GeneralSettings />
              <SecuritySettings />

              <div className="flex justify-end mt-6 md:px-0 px-4">
                <Button
                  loading={loading}
                  onClick={props.submitForm}
                  title={t("save")}
                />
              </div>
            </div>
          );
        }}
      </Formik>
    </>
  );
};

export default AccountPage;
