import { LocalizedLink } from "../../router";

const Logo = ({
  color = "black",
  size = "large",
}: {
  color?: "black" | "white";
  size?: "small" | "large";
}) => {
  return (
    <div>
      <LocalizedLink to={"/"}>
        <span
          className={`font-poppins font-semibold tracking-tighter ${
            size === "small" ? "text-xl" : "text-2xl"
          } ${color === "white" ? "text-white" : "text-black"}`}
        >
          Rompolo.
        </span>
      </LocalizedLink>
    </div>
  );
};

export default Logo;
