import { Pagination as MantinePagination } from "@mantine/core";

const Pagination = ({
  currentPage,
  totalCount,
  pageSize,
  onPageChange,
}: {
  currentPage: number;
  totalCount: number;
  pageSize: number;
  onPageChange: (page: number) => void;
}) => {
  return (
    <MantinePagination
      classNames={{
        control:
          "text-black hover:bg-black hover:text-white border-medium-black",
      }}
      size={"sm"}
      value={currentPage}
      total={Math.ceil(totalCount / pageSize)}
      onChange={onPageChange}
    />
  );
};

export default Pagination;
