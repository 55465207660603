import { ActionIcon, Box, Popover, Select } from "@mantine/core";
import { Column } from "@tanstack/react-table";
import { useMemo } from "react";
import { Input } from "..";
import { IconFilter } from "@tabler/icons-react";

/**
 * Filter component of React Table
 */
const Filter = ({
  column,
  type,
  onChange,
}: {
  column: Column<any, unknown>;
  type: "select" | "input";
  onChange?: (value: string | null) => void;
}) => {
  const columnFilterValue = column.getFilterValue() as string;

  const sortedUniqueValues = useMemo(() => {
    return Array.from(column.getFacetedUniqueValues().keys())
      .sort()
      .reduce((result, k) => {
        if (k !== null) {
          if (typeof k === "boolean") {
            result.push({ label: k ? "Yes" : "No", value: String(k) });
          } else if (Array.isArray(k)) {
            k.map((v) => {
              const filterExists = result.find(
                (r: { label: string; value: string }) => r.value === v
              );
              !filterExists && result.push({ label: v, value: String(v) });
            });
          } else if (k) {
            result.push({ label: k, value: String(k) });
          }
        } else {
          result.push({ label: "Empty", value: String(k) });
        }
        return result;
      }, []);
  }, [
    {
      value: column.getFacetedUniqueValues(),
      label: column.getFacetedUniqueValues(),
    },
  ]);

  return (
    <Popover width={250} position="bottom" withArrow shadow="md">
      <Popover.Target>
        <ActionIcon
          ml={"xs"}
          variant={columnFilterValue ? "light" : "transparent"}
        >
          <IconFilter size={16} className="stroke-black" />
        </ActionIcon>
      </Popover.Target>
      <Popover.Dropdown>
        <Box>
          {type === "input" ? (
            <Input
              type="text"
              value={(columnFilterValue ?? "") as string}
              onChange={(value) => column.setFilterValue(value)}
              placeholder={`Search... (${
                column.getFacetedUniqueValues().size
              })`}
            />
          ) : (
            <Select
              placeholder="Search..."
              clearable
              onChange={(value) =>
                onChange ? onChange(value) : column.setFilterValue(value)
              }
              searchable
              data={sortedUniqueValues}
              value={columnFilterValue ?? null}
            />
          )}
        </Box>
      </Popover.Dropdown>
    </Popover>
  );
};

export default Filter;
