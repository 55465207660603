import { useTranslations } from "use-intl";
import { Button, Input, Loading, ReactTable, Title } from "../../common";
import { IconPlus } from "@tabler/icons-react";
import { useQuery } from "@apollo/client";
import { PaginatedType } from "../../types/paginated.type";
import { Badge } from "../../types/badge.type";
import { badgesQuery } from "../../graphql/badgeQueries";
import { useEffect, useState } from "react";
import { ErrorService } from "../../services";
import { badgeColumns } from "../../columns/BadgeColumns";
import { useDisclosure } from "@mantine/hooks";
import { CreateBadge } from "./components";
import { useSearchParamsState } from "../../hooks";
import { Loader, Pagination } from "@mantine/core";
import { OperationType } from "../../types/operation-type.enum";

const BadgesPage = () => {
  const t = useTranslations("Badges");
  const [opened, { open, close }] = useDisclosure(false);
  const [selectedBadge, setSelectedBadge] = useState<Badge>();
  const [queryParams, setQueryParams] = useSearchParamsState({
    search: "",
    page: 1,
    limit: 12,
  });

  const { loading, error, data, refetch } = useQuery<
    { badges: PaginatedType<Badge> },
    {
      first?: number;
      after?: string;
      before?: string;
      offset?: number;
      paymentId?: string;
      details?: string;
      shortId?: string;
      albumId?: string;
      operationType?: OperationType;
    }
  >(badgesQuery, {
    variables: {
      first: queryParams.limit,
      offset: (queryParams.page - 1) * queryParams.limit,
    },
  });

  const loadMore = (newPage: number) => {
    const first = queryParams.limit;
    let offset;

    // If neither after nor before is available, use offset
    offset = (newPage - 1) * first;

    refetch({
      first,
      ...(offset !== undefined && { offset }), // Only add offset if it's defined
    }).then(() => {
      setQueryParams({ page: newPage });
    });
  };

  useEffect(() => {
    if (error) {
      ErrorService.showError(t("errorFetching"));
    }
  }, [error]);

  const onCreated = () => {
    refetch({ first: queryParams.limit });
  };

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const search = event.target.value;
    refetch({
      paymentId: search,
      details: search,
      shortId: search,
      albumId: search,
      operationType: OperationType.OR,
      first: queryParams.limit,
    });
    setQueryParams({ search });
  };

  const totalCount = data?.badges.totalCount ?? 0;

  return (
    <div className="flex-1 md:px-6 px-4 flex flex-col">
      <CreateBadge
        badge={selectedBadge}
        opened={opened}
        close={() => {
          close();
          setSelectedBadge(undefined);
        }}
        onCreated={onCreated}
      />
      <div className="flex justify-between md:items-center gap-4 flex-col md:flex-row">
        <Title size="H5">{t("badges")}</Title>
        <div className="flex flex-col md:flex-row gap-6">
          <Input
            rightSection={loading && <Loader color="#222222" size={16} />}
            onChange={onSearchChange}
            placeholder={t("search")}
          />
          <Button
            onClick={open}
            icon={<IconPlus stroke={3} size={16} className=" stroke-white" />}
            title={t("addNew")}
          />
        </div>
      </div>
      <div className=" mt-8">
        <ReactTable<Badge>
          search={""}
          loading={loading}
          onRowClick={(badge) => {
            setSelectedBadge(badge);
            open();
          }}
          columns={badgeColumns}
          data={data?.badges.nodes ?? []}
          initialPageSize={30}
          emptyMessage={t("noBadges")}
        />
        <Pagination
          className="mt-6"
          classNames={{
            control:
              "text-black hover:bg-black hover:text-white border-medium-black data-[active=true]:bg-black data-[active=true]:text-white",
          }}
          size={"sm"}
          value={queryParams.page}
          total={Math.ceil(totalCount / queryParams.limit)}
          onChange={loadMore}
        />
        {loading && <Loading />}
      </div>
    </div>
  );
};

export default BadgesPage;
