import { gql } from "@apollo/client";

const ImageDetails = gql`
  fragment ImageDetails on Image {
    id
    url
    album {
      id
    }
    user {
      id
    }
    imageNo
    thumbnailUrl
    description
    contributor
    guestUserId
    size
    type
    createdAt
    updatedAt
    published
    approved
    hashtagOnImages {
      hashtag {
        id
        title
      }
      assignedBy {
        id
      }
      guestUserId
    }
  }
`;

//We are fetching only id and url of image to download all
export const downloadAllQuery = gql`
  query DownloadAllImages(
    $albumId: String!
    $contributor: String
    $createdAtEnd: DateTime
    $createdAtStart: DateTime
    $description: String
    $first: Int
    $guestUserId: String
    $published: Boolean
    $approved: Boolean
    $sizeGreater: Float
    $sizeLess: Float
    $updatedAtEnd: DateTime
    $updatedAtStart: DateTime
    $userId: String
    $after: String
  ) {
    albumImages(
      albumId: $albumId
      after: $after
      first: $first
      contributor: $contributor
      createdAtEnd: $createdAtEnd
      createdAtStart: $createdAtStart
      description: $description
      guestUserId: $guestUserId
      published: $published
      approved: $approved
      sizeGreater: $sizeGreater
      sizeLess: $sizeLess
      updatedAtEnd: $updatedAtEnd
      updatedAtStart: $updatedAtStart
      userId: $userId
    ) {
      edges {
        cursor
        node {
          id
          url
        }
      }
      hasNextPage
      totalCount
    }
  }
`;

export const updateImageMutation = gql`
  mutation UpdateImage($input: UpdateImageInput!) {
    updateImage(updateImageInput: $input) {
      ...ImageDetails
    }
  }
  ${ImageDetails}
`;

export const updateGuestImageMutation = gql`
  mutation UpdateGuestImage($input: UpdateImageInput!) {
    updateGuestImage(updateImageInput: $input) {
      ...ImageDetails
    }
  }
  ${ImageDetails}
`;

export const addedImageSubscription = gql`
  subscription AddedImageSubscription($albumId: String!, $guestUserId: String) {
    imageAdded(albumId: $albumId, guestUserId: $guestUserId) {
      ...ImageDetails
    }
  }
  ${ImageDetails}
`;

export const removedImageSubscription = gql`
  subscription RemovedImageSubscription(
    $albumId: String!
    $guestUserId: String
  ) {
    imageRemoved(albumId: $albumId, guestUserId: $guestUserId) {
      ...ImageDetails
    }
  }
  ${ImageDetails}
`;

export const removeImageMutation = gql`
  mutation RemoveImage($id: String!) {
    removeImage(id: $id)
  }
`;

export const removeGuestImageMutation = gql`
  mutation RemoveGuestImage($id: String!) {
    removeGuestImage(id: $id)
  }
`;

// Query for getting current user data
export const albumImagesQuery = gql`
  query AlbumImages(
    $albumId: String!
    $contributor: String
    $createdAtEnd: DateTime
    $createdAtStart: DateTime
    $description: String
    $first: Int
    $guestUserId: String
    $published: Boolean
    $approved: Boolean
    $sizeGreater: Float
    $sizeLess: Float
    $updatedAtEnd: DateTime
    $updatedAtStart: DateTime
    $userId: String
    $after: String
    $hashtags: [String!]
  ) {
    albumImages(
      albumId: $albumId
      after: $after
      first: $first
      contributor: $contributor
      createdAtEnd: $createdAtEnd
      createdAtStart: $createdAtStart
      description: $description
      guestUserId: $guestUserId
      published: $published
      approved: $approved
      sizeGreater: $sizeGreater
      sizeLess: $sizeLess
      updatedAtEnd: $updatedAtEnd
      updatedAtStart: $updatedAtStart
      userId: $userId
      hashtags: $hashtags
    ) {
      edges {
        cursor
        node {
          ...ImageDetails
        }
      }
      hasNextPage
      totalCount
    }
  }
  ${ImageDetails}
`;

//Find size of album
export const findAlbumSizes = gql`
  query FindAlbumSize($albumId: String!) {
    findAlbumSize(albumId: $albumId) {
      maxSize
      currentSize
    }
  }
`;

//Find total size of all albums
export const findTotalSize = gql`
  query FindTotalSize {
    findTotalSize
  }
`;

//Find total count of images
export const findTotalImageCount = gql`
  query FindTotalImageCount {
    findTotalImageCount
  }
`;

//Find image count by hour for past week
export const findHourlyImageCount = gql`
  query FindHourlyImageCount {
    findHourlyImageCount {
      count
      hour
    }
  }
`;
