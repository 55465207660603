import { useTranslations } from "use-intl";
import { Info, InfoToolitp, SettingsBlock, Text } from "../";
import { useFormikContext } from "formik";
import { Album, CreateAlbumInput } from "../../types/album.type";
import { Group, Radio } from "@mantine/core";

const ImageSettings = ({
  handleFieldChange,
}: {
  album?: Album;
  handleFieldChange: (e: React.ChangeEvent<any> | string, value?: any) => void;
}) => {
  const t = useTranslations("AlbumSettings");
  const formik = useFormikContext<CreateAlbumInput>();

  return (
    <SettingsBlock description={t("imageOptimizationDescription")}>
      <Radio.Group
        value={String(formik.values.optimizeImages)}
        onChange={(val) => handleFieldChange("optimizeImages", JSON.parse(val))}
      >
        <Text className="mb-4 flex gap-1 items-center" color="gray">
          <span>
            {t("wantToOptimize")}
            <span className=" whitespace-nowrap">
              <InfoToolitp text={t("wantToOptimizeTooltip")} />
            </span>
          </span>
        </Text>
        <Group>
          <Radio
            variant="outline"
            color="#222222"
            styles={{ label: { fontSize: 16 } }}
            value={"false"}
            label={t("noKeepQuality")}
          />
          <Radio
            variant="outline"
            color="#222222"
            styles={{ label: { fontSize: 16 } }}
            value={"true"}
            label={t("yesOptimize")}
          />
        </Group>
      </Radio.Group>
      <Info name="qualityInfo" withClose={false}>
        <Text className="font-semibold">{t("weRecommend")}</Text>
        <Text className="mt-2">{t("weRecommendDescription.weOptimize")}</Text>
        <ul className="list-inside list-decimal [&>li]:ml-4 space-y-2 mt-2">
          <li>{t("weRecommendDescription.1")}</li>
          <li>{t("weRecommendDescription.2")}</li>
          <li>{t("weRecommendDescription.3")}</li>
        </ul>
        <Text className="mt-2">{t("weRecommendDescription.conclusion")}</Text>
      </Info>
    </SettingsBlock>
  );
};

export default ImageSettings;
