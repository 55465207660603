const YouTubeVideo = ({ videoId }: { videoId: string }) => {
  const src = `https://www.youtube.com/embed/${videoId}`;

  return (
    <div className="rounded-xl overflow-hidden mt-4 border border-black/10 ">
      <iframe
        width="100%"
        height="315px"
        src={src}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      ></iframe>
    </div>
  );
};

export default YouTubeVideo;
