import { Checkbox, Modal } from "@mantine/core";
import { Button, Text, Title } from "../../../common";
import { useTranslations } from "use-intl";
import { useEffect, useState } from "react";

const RequireApprovalModal = ({
  close,
  show,
}: {
  close: () => void;
  show: boolean;
}) => {
  const t = useTranslations("Photos");
  const [isChecked, setIsChecked] = useState(false);
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    const dontShowModal = JSON.parse(
      localStorage.getItem("dontShowModal") || "false"
    );
    if (dontShowModal) {
      setOpened(false);
    } else {
      setOpened(show);
    }
  }, [show]);

  useEffect(() => {
    if (!opened) {
      localStorage.setItem("dontShowModal", JSON.stringify(isChecked));
    }
  }, [opened]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
  };

  return (
    <Modal
      withCloseButton={false}
      opened={opened}
      onClose={close}
      centered
      radius={8}
      padding={0}
    >
      <div data-testid="requireApprovalModal" className="p-4 pt-8">
        <Title size="H4">{t("thanksForSharing")}</Title>
        <Text className="mt-4">{t("thanksForSharingDescription")}</Text>
        <Checkbox
          checked={isChecked}
          onChange={handleCheckboxChange}
          styles={{
            label: {
              fontSize: 16,
            },
          }}
          color="#222222"
          label={t("dontShowAgain")}
          className="mt-4"
        />
        <div className="flex items-end justify-end gap-2 mt-6">
          <Button small onClick={() => close()} title={t("okGotIt")} />
        </div>
      </div>
    </Modal>
  );
};

export default RequireApprovalModal;
