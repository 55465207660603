import { useTranslations } from "use-intl";
import { AlbumSize, RoundedBox, Text, Title } from "../../../common";
import { useIsSmallScreen, useLoadCounts } from "../../../hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { Album, AlbumSizes } from "../../../types/album.type";
import { useQuery } from "@apollo/client";
import { findAlbumSizes } from "../../../graphql/imageQueries";
import { useEffect } from "react";
import { ErrorService } from "../../../services";
import { Skeleton } from "@mantine/core";
import { IconExternalLink, IconReload } from "@tabler/icons-react";
import { LocalizedLink } from "../../../router";

const Statistics = () => {
  const album = useSelector((state: RootState) => state.album.album) as Album;
  const t = useTranslations("Dashboard");
  const {
    loading: loadingCounts,
    loadCounts,
    unPublishedCount,
    publishedCount,
    notApprovedCount,
  } = useLoadCounts(album?.id ?? null);
  const isSmallScreen = useIsSmallScreen();

  //Find album sizes
  const {
    loading: loadingSizes,
    error,
    data: sizesData,
    refetch: refetchSizes,
  } = useQuery<{ findAlbumSize: AlbumSizes }, { albumId: string }>(
    findAlbumSizes,
    {
      skip: !album,
      variables: {
        albumId: album?.id,
      },
    }
  );

  const handleReload = () => {
    if (album) {
      loadCounts();
      refetchSizes();
    }
  };

  useEffect(() => {
    if (album) loadCounts();
  }, [album]);

  useEffect(() => {
    if (error) {
      ErrorService.showError(t("errorFetchingStatistics"));
    }
  }, [error]);

  return (
    <RoundedBox
      roundedOnSmallScreens={false}
      className="flex flex-col gap-6 lg:w-[340px] md:flex-shrink-0"
    >
      <IconReload
        onClick={handleReload}
        size={isSmallScreen ? 24 : 16}
        className=" stroke-light-black absolute top-4 right-4 cursor-pointer transition-transform md:hover:rotate-45 md:hover:scale-150"
      />
      <div className="">
        <Title wrap="nowrap" size="H5">
          {t("statistics")}
        </Title>
        <Text color="gray" className="mt-2">
          {t("statisticsDescription")}
        </Text>
      </div>
      <div>
        {loadingSizes ? (
          <Skeleton width={"100%"} height={35} />
        ) : (
          sizesData && (
            <AlbumSize
              withStorageWord={false}
              withBar
              textColor="gray"
              textSize="sm"
              album={album}
              sizes={sizesData?.findAlbumSize}
            />
          )
        )}
        <div className="flex flex-col mt-6 gap-4">
          {loadingCounts ? (
            <Skeleton width={"100%"} height={35} />
          ) : (
            <div>
              <Text className="font-semibold flex gap-2 items-center">
                {t("published")}
                <LocalizedLink to="/photos-videos">
                  <IconExternalLink size={16} className="stroke-black" />
                </LocalizedLink>
              </Text>
              <LocalizedLink to="/photos-videos" className="hover:underline">
                <span className=" font-poppins text-black mt-2">
                  {publishedCount}
                </span>
              </LocalizedLink>
            </div>
          )}
          {loadingCounts ? (
            <Skeleton width={"100%"} height={35} />
          ) : (
            <div>
              <Text className="font-semibold flex gap-2 items-center">
                {t("needsApproval")}
                <LocalizedLink to="/photos-videos?filterApproved=false">
                  <IconExternalLink size={16} className="stroke-black" />
                </LocalizedLink>
              </Text>
              <LocalizedLink
                to="/photos-videos?filterApproved=false"
                className="hover:underline"
              >
                <span className=" font-poppins text-black mt-2">
                  {notApprovedCount}
                </span>
              </LocalizedLink>
            </div>
          )}
          {loadingCounts ? (
            <Skeleton width={"100%"} height={35} />
          ) : (
            <div>
              <Text className="font-semibold flex gap-2 items-center">
                {t("unpublished")}
                <LocalizedLink to="/photos-videos?filterPublished=false">
                  <IconExternalLink size={16} className="stroke-black" />
                </LocalizedLink>
              </Text>
              <LocalizedLink
                to="/photos-videos?filterPublished=false"
                className="hover:underline"
              >
                <span className=" font-poppins text-black mt-2">
                  {unPublishedCount}
                </span>
              </LocalizedLink>
            </div>
          )}
        </div>
      </div>
    </RoundedBox>
  );
};

export default Statistics;
