import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type HowTo = "general" | "photowall" | "templates";

interface HowToState {
  opened?: HowTo;
}
const initialState = { opened: undefined } as HowToState;

const howToSlice = createSlice({
  name: "howto",
  initialState,
  reducers: {
    openHowTo(state: HowToState, action: PayloadAction<HowTo>) {
      state.opened = action.payload;
    },

    closeHowTo(state) {
      state.opened = undefined;
    },
  },
});

export const { openHowTo, closeHowTo } = howToSlice.actions;
export default howToSlice.reducer;
