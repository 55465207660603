import React from "react";
import { Logo } from "../../../common";

type OnboardingWrapperProps = {
  children: React.ReactNode;
};

const OnboardingWrapper = ({ children }: OnboardingWrapperProps) => {
  return (
    <div style={{ height: "100dvh" }} className="flex bg-white">
      <div className="flex-1 flex flex-col">
        <div className="md:pt-10 pt-4  px-4 flex md:justify-center">
          <div className="md:w-[340px]">
            <Logo />
          </div>
        </div>
        {children}
      </div>
      <div className="hidden md:block bg-cover bg-no-repeat flex-1 bg-gradient-pattern"></div>
    </div>
  );
};

export default OnboardingWrapper;
