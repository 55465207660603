import { useTranslations } from "use-intl";
import { Text, Title, Trustpilot } from "../../common";
import { Badge, Plans, Questions, Sale, TimeOffer } from "./components";
import { useMutation } from "@apollo/client";
import { createCheckoutSessionMutation } from "../../graphql/paymentsQueries";
import { useEffect } from "react";
import { ErrorService } from "../../services";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { Album } from "../../types/album.type";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const PricingPage = () => {
  const album = useSelector((state: RootState) => state.album.album) as Album;
  const t = useTranslations("Pricing");
  const [createCheckoutSession, { error, loading }] = useMutation<
    { createCheckoutSession: string },
    { input: { priceId: string[]; currency: string; albumId: string } }
  >(createCheckoutSessionMutation);
  const location = useLocation();

  const handlePurchase = async (prices: string[]) => {
    try {
      const checkoutSession = await createCheckoutSession({
        variables: {
          input: { priceId: prices, currency: "USD", albumId: album.id },
        },
      });
      const url = checkoutSession.data?.createCheckoutSession;

      // Redirect the user to the URL
      if (url) {
        window.location.href = url;
      } else {
        ErrorService.showError(t("errorOnPurchase"));
      }
    } catch (error) {
      ErrorService.showError(t("errorOnPurchase"));
    }
  };

  useEffect(() => {
    if (error) {
      ErrorService.showError(t("errorOnPurchase"));
    }
  }, [error]);

  useEffect(() => {
    // Parse the search query string
    const searchParams = new URLSearchParams(location.search);
    const success = searchParams.get("success");
    if (success === "true") {
      // Show the success message
      ErrorService.showMessage(t("upgraded"));
    } else if (success === "false") {
      ErrorService.showError(t("paymentFailure"));
    }
  }, [location.search]);

  return (
    <div className="flex-1 md:px-6 flex flex-col max-w-[1200px]">
      <Helmet>
        <title>Rompolo - {t("metaTitle")}</title>
      </Helmet>
      <div className="mb-6 px-4 md:px-0">
        <Title size="H4">{t("pricing")}</Title>
        <Text className="mt-2" color="gray">
          {t("pricingDescription")}
        </Text>
      </div>
      <Trustpilot />
      {album?.plan === "free" && <TimeOffer album={album} />}
      {album?.plan === "free" && <Sale />}
      <div>
        <Plans loading={loading} purchase={handlePurchase} />
        <Badge loading={loading} purchase={handlePurchase} />
        <Questions />
      </div>
    </div>
  );
};

export default PricingPage;
