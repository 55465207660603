import { useTranslations } from "use-intl";
import { Logo, Text } from "../../../common";

const AlbumFooter = () => {
  const t = useTranslations("Footer");

  return (
    <div className="bg-black h-[150px] px-4 flex items-center">
      <div className="max-w-[1100px] mx-auto md:p-0 p-4 flex-1">
        <Logo size="small" color="white" />
        <Text className=" mt-1" color="white">
          {t("slug")}
        </Text>
      </div>
    </div>
  );
};

export default AlbumFooter;
