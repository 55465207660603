import { IconAlertTriangle } from "@tabler/icons-react";
import { Text } from ".";

const Error = ({ message }: { message: string }) => {
  return (
    <div className="mb-8 p-3 bg-red flex rounded-xl gap-2">
      <div className="">
        <IconAlertTriangle
          width={16}
          height={16}
          className="stroke-white mt-1 "
        />
      </div>

      <Text color="white">{message}</Text>
    </div>
  );
};

export default Error;
