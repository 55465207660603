import { gql } from "@apollo/client";

const BadgeDetails = gql`
  fragment BadgeDetails on Badge {
    album {
      id
      name
      shortId
      user {
        email
        fullName
      }
    }
    albumId
    createdAt
    details
    id
    paymentId
    shortId
    updatedAt
  }
`;

export const createBadgeMutation = gql`
  mutation CreateBadge($input: CreateBadgeInput!) {
    createBadge(createBadgeInput: $input) {
      ...BadgeDetails
    }
  }
  ${BadgeDetails}
`;

export const updateBadgeMutation = gql`
  mutation UpdateBadge($input: UpdateBadgeInput!) {
    updateBadge(updateBadgeInput: $input) {
      ...BadgeDetails
    }
  }
  ${BadgeDetails}
`;

// Query for getting current user data
export const badgesQuery = gql`
  query Badges(
    $after: String
    $before: String
    $albumId: String
    $createdAtEnd: DateTime
    $createdAtStart: DateTime
    $details: String
    $first: Int
    $offset: Int
    $paymentId: String
    $shortId: String
    $updatedAtEnd: DateTime
    $updatedAtStart: DateTime
    $operationType: OperationType
  ) {
    badges(
      before: $before
      after: $after
      offset: $offset
      albumId: $albumId
      createdAtEnd: $createdAtEnd
      createdAtStart: $createdAtStart
      details: $details
      first: $first
      paymentId: $paymentId
      shortId: $shortId
      updatedAtEnd: $updatedAtEnd
      updatedAtStart: $updatedAtStart
      operationType: $operationType
    ) {
      nodes {
        ...BadgeDetails
      }
      hasNextPage
      totalCount
    }
  }
  ${BadgeDetails}
`;

//Query to get album by shortId
export const findBadgeByShortIdQuery = gql`
  query BadgeByShortId($shortId: String!) {
    badgeByShortId(id: $shortId) {
      ...BadgeDetails
    }
  }
  ${BadgeDetails}
`;

//Query to get album by shortId
export const findBadgeByAlbumIdQuery = gql`
  query BadgeByAlbumId($albumId: String!) {
    badgeByAlbumId(albumId: $albumId) {
      ...BadgeDetails
    }
  }
  ${BadgeDetails}
`;
