import { useTranslations } from "use-intl";
import { Title, Text, CenteredBlockWrapper } from "../../common";
import { useAuth, useLocalizedNavigate } from "../../hooks";
import { AuthService, ErrorService } from "../../services";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { useMutation } from "@apollo/client";
import {
  resendVerificationEmailMutation,
  verifyEmailMutation,
} from "../../graphql/userQueries";
import { useDispatch } from "react-redux";
import { setUser } from "../../store/user.reducer";

const VerifyEmail = () => {
  const { user } = useAuth();
  const t = useTranslations("Verify");
  const dispatch = useDispatch();
  const [resendVerificationEmail] = useMutation<boolean>(
    resendVerificationEmailMutation
  );
  const navigate = useLocalizedNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const [verifyEmail] = useMutation<
    { verifyEmail: Boolean },
    {
      input: string;
    }
  >(verifyEmailMutation);

  const resendEmail = () => {
    resendVerificationEmail().then((resp) => {
      resp.data && ErrorService.showMessage(t("resent"));
    });
  };

  const signUpAgain = async () => {
    const loggedOut = await AuthService.logout();

    if (loggedOut) {
      navigate("/signup");
    }
  };
  const signInAgain = async () => {
    const loggedOut = await AuthService.logout();

    if (loggedOut) {
      navigate("/signin");
    }
  };

  useEffect(() => {
    if (token) {
      verifyEmail({ variables: { input: token } })
        .then(async (verified) => {
          if (verified.data?.verifyEmail) {
            //If user not logged in, redirect to sign in page
            if (user.isGuest || !user) {
              ErrorService.showMessage(t("successfullyVerifiedPleaseSignIn"));
              navigate("/signin");
            } else {
              ErrorService.showMessage(t("successfullyVerified"));
              //If verified successfully, refetch user and update user store for routing
              await AuthService.getUser().then((user) => {
                dispatch(setUser(user));
              });
            }
          } else {
            ErrorService.showError(t("errorDuringVerification"));
          }
        })
        .catch((_) => {
          ErrorService.showError(t("errorDuringVerification"));
        });
    }
  }, [token]);

  return (
    <CenteredBlockWrapper
      footer={
        <Text className="pt-10 text-center" color="gray">
          {t.rich("alreadyHaveAccount", {
            signin: (chunks) => (
              <span
                onClick={signInAgain}
                className="underline text-black cursor-pointer"
              >
                {chunks}
              </span>
            ),
          })}
        </Text>
      }
    >
      <Title size="H3">{t("verify")}</Title>
      <Text className="pt-2" color="gray">
        {t("ensure")}
      </Text>
      <ol className="list-decimal text-medium-black mt-6 pl-4">
        <li>
          <Text className="pt-2" color="gray">
            {t.rich("checkEmail", {
              email: () => (
                <strong className="text-black">{user?.email}</strong>
              ),
            })}
          </Text>
        </li>
        <li className=" mt-3">
          <Text className="pt-2" color="gray">
            {t("verificationLink")}
          </Text>
        </li>
      </ol>
      <Text className=" pt-6" color="gray">
        {t.rich("didntReceived", {
          resend: (chunks) => (
            <span
              onClick={resendEmail}
              className="underline text-black cursor-pointer"
            >
              {chunks}
            </span>
          ),
        })}
      </Text>
      <Text className="pt-6" color="gray">
        {t.rich("wrongEmail", {
          again: (chunks) => (
            <span
              onClick={signUpAgain}
              className="underline text-black cursor-pointer"
            >
              {chunks}
            </span>
          ),
        })}
      </Text>
    </CenteredBlockWrapper>
  );
};

export default VerifyEmail;
