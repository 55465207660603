import { useState } from "react";
import { useTranslations } from "use-intl";
import {
  Back,
  Button,
  ResponsiveContainer,
  Text,
  Title,
} from "../../../common";
import { Album } from "../../../types/album.type";
import { PlanConfig } from "./Plans";
import { PlanProducts } from "../../../config/pricing.config";
import { Tooltip } from "@mantine/core";

interface ExtendPlanProps {
  purchase: (products: string[]) => void;
  album: Album;
  planConfig: PlanConfig;
  loading?: boolean;
  disabled?: boolean;
}

const ExtendPlan: React.FC<ExtendPlanProps> = ({
  purchase,
  album,
  planConfig,
  loading,
  disabled = false,
}) => {
  const t = useTranslations("Pricing.extendPlan");
  const [isOpen, setIsOpen] = useState(false);

  const handleExtend = () => {
    const product = PlanProducts[planConfig.productNamespace][album.maxSize];
    product && purchase([product.priceId]);
  };

  return (
    <>
      <Tooltip label={t("notStarted")} disabled={!disabled}>
        <Button
          disabled={disabled}
          type="outline"
          minWidth={190}
          onClick={() => setIsOpen(true)}
          title={t("extend")}
        />
      </Tooltip>

      <ResponsiveContainer opened={isOpen} close={() => setIsOpen(false)}>
        <div className="">
          <Back onClick={() => setIsOpen(false)} />
          <Title className="mt-6" size="H3">
            {t("title")}
          </Title>
          <Text className="mt-6" color="gray">
            {t("description")}
          </Text>
          <div className="flex justify-end mt-10">
            <Button
              loading={loading}
              className="mt-6"
              onClick={handleExtend}
              title={t("continue")}
            />
          </div>
        </div>
      </ResponsiveContainer>
    </>
  );
};

export default ExtendPlan;
