import { useTranslations } from "use-intl";
import { Text } from "../../../common";
import { ScrollArea } from "@mantine/core";
import { useIsSmallScreen } from "../../../hooks";

const ImageGridFilters = ({
  setFilter,
  publishedCount,
  notApprovedCount,
  unPublishedCount,
  published,
  approved,
}: {
  setFilter: (value: any) => void;
  publishedCount: number;
  notApprovedCount: number;
  unPublishedCount: number;
  published?: boolean | null;
  approved?: boolean | null;
}) => {
  const t = useTranslations("Photos");
  const isSmallScreen = useIsSmallScreen();

  //Check if small screen
  const widthOfFilterContainer = window.innerWidth - 32; //minus padding
  return (
    <ScrollArea
      style={{ width: isSmallScreen ? widthOfFilterContainer : "auto" }}
    >
      <div className="flex gap-2 ">
        <Tag
          active={published && approved}
          onClick={() => {
            setFilter({ filterPublished: true, filterApproved: true });
          }}
        >
          <Text wrap="nowrap">
            {t.rich("published", {
              number: () => publishedCount,
            })}
          </Text>
        </Tag>
        <Tag
          active={!approved && approved !== undefined}
          onClick={() => {
            setFilter({ filterPublished: undefined, filterApproved: false });
          }}
        >
          <Text wrap="nowrap">
            {t.rich("needsApproval", {
              number: () => notApprovedCount,
            })}
          </Text>
        </Tag>
        <Tag
          active={!published && published !== undefined}
          onClick={() => {
            setFilter({ filterPublished: false, filterApproved: undefined });
          }}
        >
          <Text wrap="nowrap">
            {t.rich("unpublished", {
              number: () => unPublishedCount,
            })}
          </Text>
        </Tag>
      </div>
    </ScrollArea>
  );
};

const Tag = ({
  children,
  active,
  onClick,
}: {
  children: React.ReactNode;
  active?: boolean | null;
  onClick: () => void;
}) => {
  const borderStyle = active ? "border-black" : "border-light-beige";
  return (
    <div
      onClick={onClick}
      className={`px-4 py-2 rounded-full cursor-pointer bg-transparent border ${borderStyle}`}
    >
      {children}
    </div>
  );
};

export default ImageGridFilters;
