import React from "react";
import { FormikContextType } from "formik";
import { CreateAlbumInput } from "../../types/album.type";

const ImageMock: React.FC<{
  formik: FormikContextType<CreateAlbumInput>;
}> = ({ formik }) => {
  return (
    <div className="flex flex-wrap gap-1 mx-auto">
      {[...Array(3)].map((_, index) => (
        <div
          key={index}
          style={{
            ...(formik.values.color === "#ece9e0" && {
              backgroundColor: "white",
            }),
          }}
          className={`
            bg-light-beige md:h-16 md:max-h-16 h-10 max-h-10 w-10 max-w-10 md:w-16 md:max-w-16 rounded-sm
            sm:flex-1
            ${index < 2 ? "flex-1" : "max-sm:hidden"}
          `}
        ></div>
      ))}
    </div>
  );
};

export default ImageMock;
