import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Album } from "../types/album.type";

interface AlbumState {
  albums: Album[];
  album?: Album; //Selected album
  refreshNeeded: boolean;
}

const initialState = {
  album: undefined,
  albums: [],
  refreshNeeded: false,
} as AlbumState;

const albumSlice = createSlice({
  name: "album",
  initialState,
  reducers: {
    addAlbum: (state, action: PayloadAction<Album>) => {
      state.albums = [action.payload, ...state.albums];
      state.album = action.payload;
    },
    setAlbums: (state, action: PayloadAction<Album[]>) => {
      state.albums = action.payload;
    },
    setAlbum(state, action: PayloadAction<Album>) {
      state.album = action.payload;
      //Find album in existing album list
      const index = state.albums.findIndex((a) => a.id === action.payload?.id);
      if (index > -1) {
        //Update album in list
        state.albums[index] = action.payload;
      }
    },
    requestAlbumsRefresh: (state) => {
      state.refreshNeeded = true;
    },
    resetAlbumsRefreshNeeded: (state) => {
      state.refreshNeeded = false;
    },
  },
});

export const {
  setAlbum,
  requestAlbumsRefresh,
  setAlbums,
  resetAlbumsRefreshNeeded,
  addAlbum,
} = albumSlice.actions;
export default albumSlice.reducer;
