import { apolloClient } from "../lib/apolloClient";
import { resetState, store } from "../store";
import { setUser } from "../store/user.reducer";
import { User } from "../types/user.type";
import { AuthenticationResponse } from "../types/authentication.response.type";
import {
  signInMutation,
  logOutMutation,
  refreshTokensMutation,
} from "../graphql/authQueries";
import { currentUserQuery } from "../graphql/userQueries";
import { v4 as uuidv4 } from "uuid";

class AuthService {
  ongoingAuthentication = false;

  /**
   * Login using local authentication
   */
  async loginLocal(
    email: string,
    password: string
  ): Promise<AuthenticationResponse | null | undefined> {
    try {
      this.ongoingAuthentication = true;
      const response = await apolloClient.mutate<
        { signIn: AuthenticationResponse },
        { email: string; password: string }
      >({
        mutation: signInMutation,
        variables: { email, password },
      });

      const user = await this.getUser();
      store.dispatch(setUser(user));
      return response.data?.signIn;
    } catch (error) {
      console.error("Error during login:", error);
      return null;
    } finally {
      this.ongoingAuthentication = false;
    }
  }

  /**
   * Calls backend API to remove cookies and logout
   */
  async logout(): Promise<boolean> {
    try {
      const response = await apolloClient.mutate<{
        logOut: boolean;
      }>({
        mutation: logOutMutation,
      });
      console.log("Logged out from system");
      this.dispatchLogout();
      return response.data?.logOut ?? false;
    } catch (error) {
      console.error("Error during logout:", error);
      return false;
    }
  }

  /**
   * Method to handle unauthenticated status
   */
  async handleUnauthenticated(): Promise<boolean> {
    if (this.ongoingAuthentication) {
      return false;
    }
    const refreshed = await this.refreshToken();
    return refreshed != null;
  }

  /**
   * Generates a guest user with a unique ID if not already present in localStorage.
   *
   * @return {Object} The guest user object with unique ID and isGuest flag set to true.
   */
  getGuestUser() {
    const guestUserId = localStorage.getItem("guestUserId") || uuidv4();
    localStorage.setItem("guestUserId", guestUserId);
    const user = { id: guestUserId, isGuest: true };
    return user;
  }

  /**
   * Method to dispatch logout action
   */
  async dispatchLogout(): Promise<void> {
    console.log("Dispatching logout");
    const user = store.getState().user;
    if (!user.user?.isGuest) {
      store.dispatch(resetState());
      const guestUser = this.getGuestUser();
      store.dispatch(setUser(guestUser)); //Replace user with guest user, this will logout
    }
  }

  /**
   * Refresh token when local authentication is used
   */
  async refreshToken(): Promise<string | undefined | null> {
    try {
      const response = await apolloClient.mutate<{
        refreshTokens: AuthenticationResponse;
      }>({
        mutation: refreshTokensMutation,
      });
      return response.data?.refreshTokens.accessToken;
    } catch (error) {
      this.dispatchLogout();
      return null;
    }
  }

  /**
   * Method to get user data from backend
   */
  async getUser(): Promise<User | null> {
    try {
      const response = await apolloClient.query<{ currentUser: User }>({
        query: currentUserQuery,
        fetchPolicy: "network-only",
      });

      return response?.data?.currentUser;
    } catch (error) {
      return null;
    }
  }
}

export default new AuthService();
