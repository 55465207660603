import React, { useState, FC } from "react";
import { Account, AlbumSelectWithModal, Logo, SnowFlakes } from "..";
import { useAuth, useLockBodyScroll } from "../../hooks";
import { IconMenu2, IconX } from "@tabler/icons-react";
import { Menu, NavigationFooter } from ".";
import { User } from "../../types/user.type";
import { useTranslations } from "use-intl";
import { Helmet } from "react-helmet-async";
import { ScrollArea } from "@mantine/core";

interface IHeaderProps {
  user: User | null;
  toggleMenu: () => void;
}

const NavigationWrapper: FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const { user } = useAuth();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const t = useTranslations("Document");

  useLockBodyScroll(isMenuOpen);

  return (
    <div className=" min-h-screen min-w-screen flex flex-col">
      <SnowFlakes />
      <Helmet>
        <title>{t("title")}</title>
      </Helmet>
      <Header user={user} toggleMenu={toggleMenu} />
      <div className="flex flex-1">
        <SideMenu
          isMenuOpen={isMenuOpen}
          closeMenu={() => setIsMenuOpen(false)}
        />
        <MainContent>{children}</MainContent>
      </div>
    </div>
  );
};

const Header: FC<IHeaderProps> = ({ user, toggleMenu }) => (
  <div className="w-full md:h-[56px] h-[100px]">
    <div className="md:h-14 w-full bg-white fixed top-0 z-30 px-4 py-3 md:py-0 flex gap-6 md:items-center justify-between shadow-sm">
      <div className="flex md:flex-row flex-col md:gap-6 gap-5">
        <Logo size="small" />
        <AlbumSelectWithModal />
      </div>
      <div className="flex justify-between gap-4">
        <div>{user && <Account user={user} />}</div>
        <MobileMenuButton onClick={toggleMenu} />
      </div>
    </div>
  </div>
);

const SideMenu: FC<{ closeMenu: () => void; isMenuOpen: boolean }> = ({
  closeMenu,
  isMenuOpen,
}) => (
  <div className="md:w-[240px] z-50 md:z-20">
    <div
      onClick={() => {
        closeMenu();
      }}
      className={`${
        isMenuOpen ? "block" : "hidden"
      } fixed inset-0 bg-black/60 md:bg-transparent md:hidden`}
    />
    <ScrollArea
      onClick={(e) => {
        e.stopPropagation();
      }}
      className={`${
        isMenuOpen ? "flex" : "hidden"
      } fixed top-0 bg-black w-[300px] md:w-[240px] h-full md:flex flex-col`}
    >
      <div className="flex flex-col p-6 md:pt-20 h-dvh">
        <LogoAndCloseButton closeMenu={closeMenu} />
        <div className="flex-1 justify-between flex-col flex">
          <Menu closeMenu={closeMenu} />
          <NavigationFooter closeMenu={closeMenu} />
        </div>
      </div>
    </ScrollArea>
  </div>
);

const LogoAndCloseButton: FC<{ closeMenu: () => void }> = ({ closeMenu }) => (
  <div className="flex justify-between items-center mb-[72px] md:hidden">
    <Logo color="white" />
    <button
      type="button"
      className="text-white focus:outline-none"
      aria-label="Close menu"
      onClick={closeMenu}
    >
      <IconX className="h-6 w-6" />
    </button>
  </div>
);

const MainContent: FC<{ children?: React.ReactNode }> = ({ children }) => {
  return (
    <div id="main-container" className="flex-1 flex flex-col relative">
      <div
        id="main-content"
        className="absolute right-0 left-0 md:px-4 py-6 pb-16"
      >
        {children}
      </div>
    </div>
  );
};

interface MobileMenuButtonProps {
  onClick: () => void;
}

const MobileMenuButton: FC<MobileMenuButtonProps> = ({ onClick }) => (
  <div className=" w-8 h-8 flex items-center md:hidden">
    <button
      type="button"
      className="text-medium-black hover:text-medium-black focus:outline-none block"
      aria-label="Toggle menu"
      onClick={onClick}
    >
      <IconMenu2 className="h-6 w-6" />
    </button>
  </div>
);

export default NavigationWrapper;
