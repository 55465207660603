import { gql } from "@apollo/client";

export const createCheckoutSessionMutation = gql`
  mutation CreateCheckoutSession($input: CreateCheckoutSessionInput!) {
    createCheckoutSession(createCheckoutSessionInput: $input)
  }
`;

export const findTotalIncomeQuery = gql`
  query FindTotalIncome {
    findTotalPaymentIncome {
      totalIncome
      paymentCount
    }
  }
`;

export const findDailyAccumulatedPaymentAmount = gql`
  query FindDailyAccumulatedPaymentAmount(
    $createdAtEnd: DateTime
    $createdAtStart: DateTime
  ) {
    findDailyAccumulatedPaymentAmount(
      createdAtEnd: $createdAtEnd
      createdAtStart: $createdAtStart
    ) {
      accumulated_amount
      date
    }
  }
`;
