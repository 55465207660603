import { Modal, Title } from "@mantine/core";
import { IconX } from "@tabler/icons-react";
import { Formik } from "formik";
import { useTranslations } from "use-intl";
import * as Yup from "yup";
import {
  AlbumSelectWithLoading,
  Button,
  Input,
  Text,
  TextArea,
} from "../../../common";
import {
  Badge,
  CreateBadgeInput,
  UpdateBadgeInput,
} from "../../../types/badge.type";
import { useMutation } from "@apollo/client";
import {
  createBadgeMutation,
  updateBadgeMutation,
} from "../../../graphql/badgeQueries";
import { ErrorService } from "../../../services";

const CreateBadge = ({
  opened,
  close,
  badge,
  onCreated,
}: {
  opened: boolean;
  close: () => void;
  badge?: Badge;
  onCreated: () => void;
}) => {
  const t = useTranslations("Badges");
  const [createBadge, { loading }] = useMutation<
    { createBadge: Badge },
    { input: CreateBadgeInput }
  >(createBadgeMutation);

  const [updateBadge, { loading: updating }] = useMutation<
    { updateBadge: Badge },
    { input: UpdateBadgeInput }
  >(updateBadgeMutation);

  const onSubmit = (values: CreateBadgeInput) => {
    if (badge) {
      updateBadge({ variables: { input: { ...values, id: badge.id } } })
        .then((response) => {
          const updatedBadge = response.data?.updateBadge;

          if (updatedBadge) {
            onCreated();
            close();
            ErrorService.showMessage(t("badgeUpdated"));
          } else {
            ErrorService.showError(t("errorOnUpdate"));
          }
        })
        .catch(() => {
          ErrorService.showError(t("errorOnUpdate"));
        });
    } else {
      createBadge({ variables: { input: values } })
        .then((response) => {
          const createdBadge = response.data?.createBadge;

          if (createdBadge) {
            onCreated();
            close();
            ErrorService.showMessage(t("badgeCreated"));
          } else {
            ErrorService.showError(t("errorOnCreation"));
          }
        })
        .catch(() => {
          ErrorService.showError(t("errorOnCreation"));
        });
    }
  };

  const badgeUrl = badge?.shortId
    ? `${window.location.origin}/badge/${badge?.shortId}`
    : undefined;

  return (
    <Modal
      radius={8}
      padding={40}
      size={600}
      onClose={close}
      centered
      opened={opened}
      withCloseButton={false}
    >
      <Formik
        initialValues={{
          albumId: badge?.albumId,
          details: badge?.details ?? "",
          paymentId: badge?.paymentId,
        }}
        validationSchema={Yup.object().shape({
          albumId: Yup.string().uuid().nullable(),
          paymentId: Yup.string().nullable(),
          details: Yup.string().max(2000),
        })}
        onSubmit={onSubmit}
        validateOnChange={false}
        enableReinitialize
      >
        {(props) => (
          <div className="flex flex-col justify-between flex-1 md:gap-10 gap-6">
            <div>
              <div className="flex justify-between">
                <Title size="H5">
                  {badge ? t("updateBadge") : t("createBadge")}
                </Title>
                <IconX
                  onClick={close}
                  size={24}
                  className=" cursor-pointer stroke-medium-black"
                />
              </div>
            </div>
            {badgeUrl && (
              <div>
                <Text color="gray">{t("badgeUrl")}</Text>
                <a className="underline" href={badgeUrl}>
                  {badgeUrl}
                </a>
              </div>
            )}
            <Input
              value={props.values.paymentId ?? ""}
              name="paymentId"
              label={t("paymentId")}
              placeholder={t("add")}
              onChange={props.handleChange}
              error={props.errors.paymentId}
            />
            <AlbumSelectWithLoading
              value={badge?.album}
              setValue={(album) => {
                props.setFieldValue("albumId", album?.id);
              }}
            />
            <TextArea
              value={props.values.details}
              name="details"
              onChange={props.handleChange}
              label={t("details")}
              error={props.errors.details}
              minRows={6}
              maxRows={6}
            />
            <div className="flex justify-end">
              <Button
                loading={loading || updating}
                onClick={() => {
                  props.submitForm();
                }}
                className="mb-6 md:m-0"
                title={badge ? t("update") : t("create")}
              />
            </div>
          </div>
        )}
      </Formik>
    </Modal>
  );
};

export default CreateBadge;
