import { IconMessageCircle } from "@tabler/icons-react";
import { Text } from "..";
import { useTranslations } from "use-intl";

const ShowDetailsButton = ({
  showDetails,
}: {
  showDetails: (value: boolean) => void;
}) => {
  const t = useTranslations("Photos");
  return (
    <div
      onClick={() => showDetails(true)}
      className="absolute cursor-pointer left-0 right-0 bottom-10 m-auto bg-light-black px-3 py-2 rounded-full z-30 flex items-center gap-2"
      style={{ width: "fit-content", height: "fit-content" }}
    >
      <IconMessageCircle width={16} height={16} className="stroke-white" />
      <Text size="sm" color="white">
        {t("details")}
      </Text>
    </div>
  );
};

export default ShowDetailsButton;
