import { IconChevronLeft, IconDownload } from "@tabler/icons-react";
import { Text } from "..";
import { useTranslations } from "use-intl";
import { Image } from "../../types/image.type";
import { getImageUrl } from "../../lib/utils";
import { ImageType } from "../../types/image-type.enum";
import { useAuth } from "../../hooks";

const ImageGalleryHeader = ({
  onClose,
  count,
  currentImage,
  image,
}: {
  onClose: () => void;
  count: number;
  currentImage: number;
  image?: Image;
}) => {
  const { user } = useAuth();
  const t = useTranslations("Photos");

  const handleDownload = () => {
    if (image) {
      const imageUrl = getImageUrl(image.url);
      //If image open in tab if video save as
      if (image.type === ImageType.PICTURE) {
        // Open the image URL in a new tab
        window.open(imageUrl, "_blank");
      } else {
        // For videos
        let downloadUrl =
          import.meta.env.VITE_API_URL +
          `/image/download/${image.album.id}/${image.id}`;
        // If user is guest, add guest user id to download url
        if (user && user.isGuest) {
          downloadUrl = downloadUrl + `?guestUserId=${user.id}`;
        }
        //Open url in new tab
        window.open(downloadUrl, "_blank");
      }
    }
  };

  return (
    <div className="h-12 flex md:hidden items-center relative ">
      <div className="fixed h-12 left-0 flex-1 flex w-full justify-center z-30 bg-black items-center">
        <Text color="white">
          {t.rich("imageNumber", {
            number: () => currentImage + 1,
            total: () => count,
          })}
        </Text>
        <div className="h-12 w-12 flex justify-center items-center absolute left-0">
          <IconChevronLeft
            onClick={onClose}
            width={24}
            height={24}
            className="stroke-white cursor-pointer"
          />
        </div>
        <div className="h-12 w-12 flex justify-center items-center absolute right-0">
          <IconDownload
            onClick={handleDownload}
            width={24}
            height={24}
            className="stroke-white cursor-pointer"
          />
        </div>
      </div>
    </div>
  );
};

export default ImageGalleryHeader;
