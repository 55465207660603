import { useTranslations } from "use-intl";
import QRCode from "qrcode";
import Logo from "../../../assets/qr-logo.png";
import { Button } from "../../../common";

const DownloadQrCode = ({ url }: { url: string }) => {
  const t = useTranslations("Dashboard");

  const downloadQR = async () => {
    const highResSize = 600; // High resolution size for download
    let logoPadding = Math.round(highResSize * 0.05);

    // Create a canvas element
    const canvas = document.createElement("canvas");
    canvas.width = highResSize;
    canvas.height = highResSize;

    // Generate QR code onto the canvas
    await QRCode.toCanvas(canvas, url, {
      width: highResSize,
      margin: 0,
      color: {
        dark: "#222",
        light: "#ffffff", // White background
      },
    });

    // Load the logo image
    const logo = new Image();
    logo.src = Logo;
    logo.onload = () => {
      const ctx = canvas.getContext("2d");
      if (ctx) {
        // Calculate logo size and position, including padding
        const logoSize = 100;
        const totalLogoSize = logoSize + 2 * logoPadding;
        const x = (canvas.width - totalLogoSize) / 2;
        const y = (canvas.height - totalLogoSize) / 2;

        // Draw a white rectangle as background for the logo
        ctx.fillStyle = "#ffffff";
        ctx.fillRect(x, y, totalLogoSize, totalLogoSize);

        // Draw the logo on the canvas
        ctx.drawImage(
          logo,
          x + logoPadding,
          y + logoPadding,
          logoSize,
          logoSize
        );

        // Trigger download
        const pngUrl = canvas
          .toDataURL("image/png")
          .replace("image/png", "image/octet-stream");

        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = "QRCode.png";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }
    };
  };

  return (
    <Button
      data-ph-capture-attribute-event="downloadQrCodeClicked"
      className="open-album-btn"
      title={t("download")}
      type="outline"
      onClick={downloadQR}
    />
  );
};

export default DownloadQrCode;
