import { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslations } from "use-intl";
import { AuthenticationResponse } from "../../types/authentication.response.type";
import {
  googleSignUpMutation,
  signInMutation,
} from "../../graphql/authQueries";
import { Formik } from "formik";
import * as Yup from "yup";
import { setUser } from "../../store/user.reducer";
import {
  AuthPageWrapper,
  Button,
  Error,
  Input,
  PasswordInput,
  Text,
} from "../../common";
import { LocalizedLink } from "../../router";
import { apolloClientWithoutErrorLink } from "../../lib/apolloClient";
import { Helmet } from "react-helmet-async";
import Google from "../../assets/google.svg?react";
import { TokenResponse, useGoogleLogin } from "@react-oauth/google";
import { ErrorService } from "../../services";

const SignInPage = () => {
  const t = useTranslations("SignIn");
  const dispatch = useDispatch();
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [googleLoading, setGoogleLoading] = useState(false);

  /**
   * Process google token and get access token and refresh token
   * @param tokenResponse google token response
   */
  const handleGoogleLogin = (tokenResponse: TokenResponse) => {
    apolloClientWithoutErrorLink
      .mutate<
        { signUpWithGoogle: AuthenticationResponse },
        {
          input: {
            accessToken: string;
          };
        }
      >({
        mutation: googleSignUpMutation,
        variables: { input: { accessToken: tokenResponse.access_token } },
      })
      .then((resp) => {
        dispatch(setUser(resp.data?.signUpWithGoogle.user ?? null));
      })
      .catch((_) => {
        setError(t("googleAuthenticationError"));
      })
      .finally(() => {
        setGoogleLoading(false);
      });
  };

  const onGoogleError = (
    error: Pick<TokenResponse, "error" | "error_description" | "error_uri">
  ) => {
    console.error(error);
    ErrorService.showError(t("googleAuthenticationError"));
  };

  const login = useGoogleLogin({
    onSuccess: handleGoogleLogin,
    onError: onGoogleError,
  });

  return (
    <AuthPageWrapper title={t("welcomBack")} subtitle={t("account")}>
      <Helmet>
        <title>Rompolo - {t("signIn")}</title>
      </Helmet>
      <div>
        <Button
          loading={googleLoading}
          onClick={() => {
            setGoogleLoading(true);
            login();
          }}
          isBold={false}
          fullWidth
          type="outline"
          icon={<Google className=" w-5 h-5" />}
          title={t("continueWithGoogle")}
        />
      </div>
      <div className="flex items-center justify-center my-6">
        <hr className=" border-very-light-black w-full" />
        <Text color="gray" className="mx-4">
          {t("or")}
        </Text>
        <hr className=" border-very-light-black w-full" />
      </div>

      <Formik
        validateOnChange={false}
        enableReinitialize
        validationSchema={Yup.object().shape({
          email: Yup.string().email(t("invalidEmail")).required(t("required")),
          password: Yup.string().required(t("required")),
        })}
        initialValues={{
          email: "",
          password: "",
        }}
        onSubmit={(values) => {
          setLoading(true);
          apolloClientWithoutErrorLink
            .mutate<
              { signIn: AuthenticationResponse },
              {
                input: {
                  email: string;
                  password: string;
                };
              }
            >({ mutation: signInMutation, variables: { input: values } })
            .then((resp) => {
              dispatch(setUser(resp.data?.signIn.user ?? null));
            })
            .catch(() => {
              setError(t("signInError"));
            })
            .finally(() => {
              setLoading(false);
            });
        }}
      >
        {(props) => {
          return (
            <div>
              {error && <Error message={error} />}
              <Input
                name="email"
                onChange={props.handleChange}
                className="mb-3"
                placeholder={t("email")}
                error={props.errors.email}
              />
              <PasswordInput
                name="password"
                onChange={props.handleChange}
                className="mb-3"
                placeholder={t("password")}
                error={props.errors.password}
              />
              <div className="mb-3">
                <LocalizedLink to={"/forgot-password"}>
                  <Text>{t("forgotPassword")}</Text>
                </LocalizedLink>
              </div>

              <Button
                small={false}
                loading={loading}
                onClick={props.submitForm}
                fullWidth
                title={t("continue")}
              />
              <div className="pt-8">
                <Text className=" text-center">
                  {t.rich("dontHaveAccount", {
                    signup: (chunks) => (
                      <LocalizedLink to={"/signup"} className="underline">
                        {chunks}
                      </LocalizedLink>
                    ),
                  })}
                </Text>
              </div>
            </div>
          );
        }}
      </Formik>
    </AuthPageWrapper>
  );
};

export default SignInPage;
