import { DateInputProps, DateInput as MantineDateInput } from "@mantine/dates";

interface CustomInputProps extends DateInputProps {
  colorScheme?: "black" | "white";
}

const DateInput = ({
  className,
  styles,
  colorScheme = "white",
  ...props
}: CustomInputProps) => {
  const dynamicStyles = {
    input: {
      height: 44,
      fontSize: 16,
      borderRadius: 8,
      backgroundColor: colorScheme === "black" ? "#222222" : "white",
      color: colorScheme === "black" ? "white" : "#222222",
      borderColor: colorScheme === "black" ? "#7A7A7A" : "#E0E0E0",
    },
    label: {
      fontSize: 16,
      fontWeight: 400,
      marginBottom: 8,
      color: colorScheme === "black" ? "#7A7A7A" : "#626262",
    },
    ...styles,
  };

  return (
    <MantineDateInput
      autoComplete="off"
      className={`${className}`}
      styles={dynamicStyles}
      {...props}
    />
  );
};

export default DateInput;
