import React, {
  useRef,
  useEffect,
  useCallback,
  ReactNode,
  FC,
  ReactElement,
} from "react";
import { ScrollArea, ScrollAreaProps } from "@mantine/core";

interface CustomScrollAreaProps extends Omit<ScrollAreaProps, "children"> {
  children: ReactNode | ((props: { scrolltotop: () => void }) => ReactNode);
}

const CustomScrollArea: FC<CustomScrollAreaProps> = ({
  children,
  ...props
}) => {
  const scrollRef = useRef<HTMLDivElement>(null);

  // Method to scroll to the top
  const scrolltotop = useCallback(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = 0;
    }
  }, []);

  useEffect(() => {
    scrolltotop(); // Automatically scroll to top when component updates or mounts
  }, [scrolltotop]);

  const enhanceChildren = (children: ReactNode): ReactNode => {
    return React.Children.map(children, (child) => {
      if (React.isValidElement(child)) {
        if (typeof child.type === "string") {
          // This is a native HTML element, don't pass scrolltotop
          return child;
        } else {
          // This is a custom React component, pass scrolltotop
          return React.cloneElement(child as ReactElement, { scrolltotop });
        }
      }
      return child;
    });
  };

  return (
    <ScrollArea viewportRef={scrollRef} {...props}>
      {typeof children === "function"
        ? children({ scrolltotop })
        : enhanceChildren(children)}
    </ScrollArea>
  );
};

export default CustomScrollArea;
