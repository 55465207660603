import { IconLink } from "@tabler/icons-react";

const CanvaEmbed = ({
  designUrl,
  templateLink,
  paddingTop,
  title,
}: {
  paddingTop: string;
  designUrl: string;
  title: string;
  templateLink?: string;
  width?: number;
}) => {
  const embedUrl = `${designUrl}?embed`;
  const rootClassName = `min-w-[300px] md:min-w-[250px] md:max-w-[300px] flex-1`;

  return (
    <div className={rootClassName}>
      <div
        style={{
          position: "relative",
          width: "100%",
          height: 0,
          paddingTop: paddingTop,
          paddingBottom: 0,
          boxShadow: "0 2px 8px 0 rgba(63,69,81,0.16)",
          overflow: "hidden",
          borderRadius: "8px",
          willChange: "transform",
        }}
      >
        <iframe
          loading="lazy"
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            border: "none",
            padding: 0,
            margin: 0,
          }}
          src={embedUrl}
          allowFullScreen={true}
          allow="fullscreen"
        ></iframe>
      </div>
      <div>
        <a
          className="flex items-center mt-4"
          href={templateLink}
          target="_blank"
          rel="noreferrer"
        >
          <span className="tracking-tight text-medium-black hover:text-black">
            {title}
          </span>
          <IconLink className="w-4 h-4 ml-1 stroke-black" />
        </a>
      </div>
    </div>
  );
};

export default CanvaEmbed;
