import { useNavigate, useParams } from "react-router-dom";
import { NavigateFunction, NavigateOptions } from "react-router-dom";
import { LOCALES, Messages } from "../locales/Locales";

const useLocalizedNavigate = (): ((
  path: string | number,
  options?: NavigateOptions
) => void) => {
  const navigate: NavigateFunction = useNavigate();
  const { locale } = useParams<{ locale: string }>(); // Assuming 'locale' is always a string

  const localizedNavigate = (
    path: string | number,
    options: NavigateOptions = {}
  ): void => {
    // Check if the path is a number, then navigate back by that number
    if (typeof path === "number") {
      navigate(path);
      return;
    }

    let newPath: string;

    // Check if the path already starts with a known locale
    const pathLocale = path.split("/")[1];
    if (LOCALES.includes(pathLocale as keyof Messages)) {
      newPath = path;
    } else {
      newPath = `/${locale}${path}`;
    }

    setTimeout(() => navigate(newPath, options), 300);
  };

  return localizedNavigate;
};

export default useLocalizedNavigate;
