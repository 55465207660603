import { CopyButton } from "@mantine/core";
import { Button, RoundedBox, Text } from "../../../common";
import { useTranslations } from "use-intl";
import moment from "moment";

const Sale = () => {
  const t = useTranslations("Pricing");

  // Configure the start and end dates for the sale
  const saleStartDate = moment(`${moment().year()}-06-01`); // June 1st
  const saleEndDate = moment(`${moment().year()}-08-31`); // August 31st

  // Check if current date is within the sale period
  const isWithinSalePeriod = moment().isBetween(
    saleStartDate,
    saleEndDate,
    null,
    "[]"
  );

  // If it's not within the sale period, don't show the discount
  if (!isWithinSalePeriod) {
    return null;
  }

  return (
    <div className="mb-6">
      <RoundedBox
        roundedOnSmallScreens={false}
        color="blue"
        className="flex flex-col md:flex-row gap-6 md:items-center justify-between"
      >
        <div>
          <Text color="white" className=" font-semibold">
            {t("summerSale")}
          </Text>
          <Text color="white" className="mt-2 ">
            {t.rich("summerSaleDescription", {
              b: (child) => <b>{child}</b>,
            })}
          </Text>
        </div>
        <div>
          <CopyButton value="SUMMER24">
            {({ copied, copy }) => (
              <Button
                data-ph-capture-attribute-event="copyDiscountClicked"
                fullWidth
                color="white"
                type="outline"
                onClick={copy}
                title={copied ? t("copied") : t("copyPromoCode")}
              />
            )}
          </CopyButton>
        </div>
      </RoundedBox>
    </div>
  );
};

export default Sale;
