import { useMutation } from "@apollo/client";
import { Modal } from "@mantine/core";
import { Formik } from "formik";
import { useTranslations } from "use-intl";
import * as Yup from "yup";
import { updateUserPasswordMutation } from "../../../graphql/userQueries";
import { AuthService, ErrorService } from "../../../services";
import { Button, Info, PasswordInput, Text, Title } from "../../../common";
import { isUnauthenticatedError } from "../../../lib/utils";
import { IconX } from "@tabler/icons-react";
import { useAuth } from "../../../hooks";

const PasswordResetModal = ({
  opened,
  close,
}: {
  opened: boolean;
  close: () => void;
}) => {
  const { user } = useAuth();
  const t = useTranslations("Account");
  const tV = useTranslations("Validation");

  const [updatePassword, { loading }] = useMutation<
    { updateUserPassword: Boolean },
    { input: { newPassword: string; currentPassword?: string } }
  >(updateUserPasswordMutation);

  const onSubmit = (values: {
    newPassword: string;
    currentPassword?: string;
    confirmPassword: string;
  }) => {
    updatePassword({
      variables: {
        input: {
          newPassword: values.newPassword,
          ...(values.currentPassword && {
            currentPassword: values.currentPassword,
          }),
        },
      },
    })
      .then((response) => {
        const passwrodUpdated = response.data?.updateUserPassword;
        if (passwrodUpdated) {
          ErrorService.showMessage(t("passwordUpdated"));
          //Logout after password change
          AuthService.logout();

          close();
        } else {
          ErrorService.showError(t("errorOnUpdate"));
        }
      })
      .catch((error) => {
        if (isUnauthenticatedError(error)) {
          ErrorService.showError(t("wrongCurrentPassword"));
        } else {
          ErrorService.showError(t("errorOnUpdate"));
        }
      });
  };

  return (
    <Modal
      centered
      opened={opened}
      onClose={close}
      padding={0}
      withCloseButton={false}
      radius={8}
      classNames={{
        content: "md:min-w-[500px]",
      }}
    >
      <Formik
        initialValues={{
          currentPassword: undefined,
          newPassword: "",
          confirmPassword: "",
        }}
        validationSchema={Yup.object().shape({
          currentPassword: Yup.string()
            .min(8)
            .optional()
            .nullable()
            .matches(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
              tV("passwordError")
            ),
          newPassword: Yup.string()
            .min(8)
            .required(tV("required"))
            .matches(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
              tV("passwordError")
            ),
          confirmPassword: Yup.string()
            .required(tV("required"))
            .oneOf([Yup.ref("newPassword")], tV("passwordMatchError")), // This checks if confirmPassword matches newPassword
        })}
        onSubmit={onSubmit}
        validateOnChange={false}
        enableReinitialize
      >
        {(props) => (
          <div className="md:p-10 p-4 md:min-w-[500px]">
            <div className="flex justify-between">
              <Title size="H5">{t("updatePassword")}</Title>
              <IconX
                onClick={close}
                size={24}
                className=" cursor-pointer stroke-medium-black"
              />
            </div>
            {user.googleId && (
              <div className="mt-4">
                <Info name="googlePasswordInfo">
                  <Text>{t("googleAccountPasswordInfo")}</Text>
                </Info>
              </div>
            )}
            <div className="flex flex-col gap-10 mt-6">
              <PasswordInput
                placeholder={t("password")}
                value={props.values.currentPassword}
                name="currentPassword"
                label={t("currentPassword")}
                onChange={props.handleChange}
                error={props.errors.currentPassword}
              />
              <div className="flex flex-col gap-4">
                <PasswordInput
                  placeholder={t("password")}
                  value={props.values.newPassword}
                  name="newPassword"
                  label={t("newPassword")}
                  onChange={props.handleChange}
                  error={props.errors.newPassword}
                />

                <PasswordInput
                  placeholder={t("password")}
                  value={props.values.confirmPassword}
                  name="confirmPassword"
                  label={t("confirmPassword")}
                  onChange={props.handleChange}
                  error={props.errors.confirmPassword}
                />
              </div>
              <div className="flex justify-end">
                <Button
                  loading={loading}
                  onClick={props.submitForm}
                  title={t("updatePassword")}
                />
              </div>
            </div>
          </div>
        )}
      </Formik>
    </Modal>
  );
};

export default PasswordResetModal;
