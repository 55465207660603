import {
  PasswordInputProps,
  PasswordInput as MantinePasswordInput,
} from "@mantine/core";

const PasswordInput = ({ ...props }: PasswordInputProps) => {
  return (
    <MantinePasswordInput
      {...props}
      classNames={{
        input: `border-very-light-black focus:border-black focus-within:border-black`,
      }}
      styles={{
        input: {
          height: 48,
          fontSize: 16,
          borderRadius: 8,
        },
        label: {
          fontSize: 16,
          fontWeight: 400,
          marginBottom: 8,
        },
      }}
    />
  );
};

export default PasswordInput;
