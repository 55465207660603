import { Premium, RoundedBox, Text } from ".";

const SettingsBlock = ({
  title,
  description,
  children,
  premium = false,
  ...props
}: {
  title?: string;
  description: string | React.ReactNode;
  children: React.ReactNode;
  premium?: boolean;
} & React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      {...props}
      className="flex flex-col xl:flex-row mt-6 max-w-[1248px] mb-6"
    >
      <div className="md:pr-28 xl:w-[450px] px-4 md:px-0 mb-4 xl:mb-0">
        {premium && <Premium />}
        {title && <Text className="mt-2 font-semibold">{title}</Text>}
        <Text className="mt-2" color="gray">
          {description}
        </Text>
      </div>
      <RoundedBox
        roundedOnSmallScreens={false}
        className="flex-1 lg:mt-0 mt-4 flex flex-col gap-8"
      >
        {children}
      </RoundedBox>
    </div>
  );
};

export default SettingsBlock;
