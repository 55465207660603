import { useTranslations } from "use-intl";
import { Button } from "..";
import { useCallback, useEffect, useRef, useState } from "react";
import { ErrorService } from "../../services";
import { compressImage } from "../../lib/fileCompressor";
import axiosClient from "../../lib/axiosClient";
import { FileButton, Tooltip } from "@mantine/core";
import { checkMediaType } from "../../lib/utils";
import { AxiosError } from "axios";
import { useAuth } from "../../hooks";
import { Album } from "../../types/album.type";
import { IconUpload } from "@tabler/icons-react";
import { FileUploadDialog } from ".";
import { Hashtag } from "../../types/hashtag.type";
import { debounce } from "lodash";
import { Image } from "../../types/image.type";
import { allowedMaxSize } from "./FileUploadDialog";
import pLimit from "p-limit";

const FileUpload = ({
  album,
  loadData,
  disabled,
  resize = true, // Resize image before upload
  fullWidth = false,
  tooltip,
  onUploadComplete,
}: {
  fullWidth?: boolean;
  album: Album;
  disabled?: boolean;
  loadData?: () => Promise<number | void>;
  resize?: boolean;
  tooltip?: string;
  onUploadComplete: () => void;
}) => {
  const { user } = useAuth();
  const t = useTranslations();
  const [files, setFiles] = useState<
    {
      description?: string | undefined;
      contributor?: string | undefined;
      hashtags?: Hashtag[] | undefined;
      file: File;
      status: "success" | "error" | "pending";
      statusCode?: number;
      uploadProgress?: number;
    }[]
  >([]);
  const [loading, setLoading] = useState(false);
  const uploadInputRef = useRef<HTMLButtonElement>(null);
  const [contributor, setContributor] = useState<string>("");
  const resetRef = useRef<() => void>(null);
  const limit = pLimit(5);

  const processError = useCallback(
    debounce((error: AxiosError) => {
      const axiosError = error as AxiosError;
      if (axiosError?.code === "507" || axiosError?.response?.status === 507) {
        ErrorService.showError(t("Files.storageLimitExceeded"), 6000);
      } else {
        ErrorService.showError(t("Files.errorUploading"));
      }
    }, 500),
    []
  );

  const closeAndReloadImages = useCallback(() => {
    loadData && loadData();
    setFiles([]);
    setContributor("");
    // If there is successfull uploads call complete functions
    if (files.some((i) => i.status === "success")) {
      onUploadComplete();
    }
  }, [files]);

  useEffect(() => {
    const fetchServerName = async () => {
      try {
        const response = await axiosClient.get("/health");
        const serverName = response.headers["x-server-name"];
        console.log("Connected to server:", serverName);
      } catch (error) {
        console.error("Error fetching server name:", error);
      }
    };
    fetchServerName();
  }, []);

  const uploadImage = useCallback(
    async (
      imageToUpload: {
        description?: string;
        file: File;
        hashtags?: Hashtag[];
        status: "success" | "error" | "pending";
      },
      index: number
    ) => {
      try {
        const file = imageToUpload.file;
        const fileType = await checkMediaType(file);

        let compressedFile: Blob | File = file;
        if (fileType === "image" && resize) {
          compressedFile = await compressImage(file);
        } else if (fileType === "video" && file.type.startsWith("image/")) {
          compressedFile = new File(
            [file],
            file.name.replace(/\..+$/, ".mp4"), //Rename file type to mp4, if image type for video received, this is WA for Whatsapp videos which are image/mp4
            {
              type: "video/mp4",
            }
          );
        }

        const formData = new FormData();
        formData.append("files", compressedFile, (compressedFile as File).name);
        // As we upload only one file, then index will be allways 0
        if (imageToUpload.description) {
          formData.append(`description-0`, imageToUpload.description);
        }
        if (contributor) {
          formData.append(`contributor-0`, contributor);
        }
        if (imageToUpload.hashtags) {
          formData.append(`hashtags-0`, JSON.stringify(imageToUpload.hashtags));
        }

        const imageUploadEndpoint =
          user.isGuest || user.id !== album.user.id
            ? `/image/public-upload/${album.id}`
            : `/image/${album.id}`;

        const response = await axiosClient.post<{
          status: "success" | "error";
          message: string;
          successfulResults: Image[];
          errors: {
            response: { message: string; error: string; statusCode: number };
            status: number;
            options: {};
            message: string;
            name: string;
          }[];
          fileStatuses: {
            fileName: string;
            status: "success" | "error";
            reason: string;
          }[];
        }>(imageUploadEndpoint, formData, {
          headers: {
            ...(user.isGuest && { guestuserid: user.id }),
          },
          timeout: 0,
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / (progressEvent?.total ?? 0)
            );
            setFiles((prevFiles) =>
              prevFiles.map((file, i) =>
                i === index
                  ? { ...file, uploadProgress: percentCompleted }
                  : file
              )
            );
          },
        });

        if (response.data) {
          const { errors = [] } = response.data;

          // If received error on image upload
          if (errors.length > 0) {
            throw new AxiosError(
              response.data.errors[0].message,
              response.data.errors[0].status.toString()
            );
          }

          // If successfull upload without an errors
          setFiles((prevFiles) =>
            prevFiles.map((file, i) =>
              i === index ? { ...file, status: "success" } : file
            )
          );
        }
      } catch (error) {
        const axiosError = error as AxiosError;
        processError(axiosError);
        setFiles((prevFiles) =>
          prevFiles.map((file, i) =>
            i === index
              ? {
                  ...file,
                  status: "error",
                  statusCode: parseInt(axiosError.code ?? ""),
                  uploadProgress: 0,
                }
              : file
          )
        );
      }
    },
    [resize, contributor, files]
  );

  const uploadImages = useCallback(async () => {
    const filesToUpload = files;

    if (filesToUpload.length === 0) {
      return;
    }
    try {
      //Update files which are not sucess to pending state
      setFiles((prevFiles) =>
        prevFiles.map((file) =>
          file.status !== "success" ? { ...file, status: "pending" } : file
        )
      );
      setLoading(true);
      await Promise.all(
        filesToUpload.map((file, index) =>
          limit(() => {
            // If file size is bigger than allowed or file is not sucessfully uploaded
            if (file.status !== "success" && file.file.size <= allowedMaxSize) {
              return uploadImage(file, index);
            } else {
              return Promise.resolve();
            }
          })
        )
      );

      // If all files were sucessfully uploaded
      if (files.every((file) => file.status === "success")) {
        closeAndReloadImages();
        ErrorService.showMessage(t("Files.successfullyUploaded"));
      }
    } catch (error) {
      ErrorService.showError(t("Files.errorUploading"));
    } finally {
      setLoading(false);
    }
  }, [files, contributor, album]);

  const handleFilesChange = useCallback(
    (selectedFiles: File[]) => {
      const updatedImages = [...files];
      selectedFiles.forEach((f) => {
        const index = updatedImages.findIndex(
          (file) => file.file.name === f.name
        );
        if (index === -1) {
          const newFilename = f.name.replace(/[^a-zA-Z0-9._-]/gi, ""); //Remove special characters
          updatedImages.push({
            file: new File([f], newFilename, { type: f.type }),
            status: "pending",
            description: "",
            contributor: "",
            uploadProgress: 0,
          });
        }
      });
      setFiles(updatedImages);
      resetRef.current?.();
    },
    [files]
  );

  return (
    <div className="w-full md:w-auto">
      {files.length > 0 && (
        <FileUploadDialog
          setFiles={setFiles}
          loading={loading}
          setContributor={setContributor}
          contributor={contributor}
          album={album}
          files={files}
          onClose={() => {
            closeAndReloadImages();
          }}
          onSubmit={uploadImages}
          openFileInput={() => {
            if (uploadInputRef.current) {
              uploadInputRef.current.click();
            }
          }}
        />
      )}
      <FileButton
        resetRef={resetRef}
        disabled={disabled}
        onChange={handleFilesChange}
        accept="image/png,image/webp,image/jpeg,video/heic,video/mp4,video/avi,video/mkv,video/quicktime,video/hevc"
        multiple
      >
        {(props) => (
          <Tooltip
            multiline
            maw={250}
            color="#222"
            disabled={!tooltip}
            label={tooltip}
          >
            <Button
              fullWidth={fullWidth}
              ref={uploadInputRef}
              icon={<IconUpload size={16} className="stroke-white" />}
              disabled={disabled}
              small={false}
              loading={loading}
              {...props}
              title={t("Photos.upload")}
            />
          </Tooltip>
        )}
      </FileButton>
    </div>
  );
};

export default FileUpload;
