import { Helmet } from "react-helmet-async";
import {
  getImageUrl,
  isNotFoundError,
  isUnauthenticatedError,
} from "../../lib/utils";
import { useCallback, useEffect, useRef, useState } from "react";
import { apolloClient } from "../../lib/apolloClient";
import { Image, ImageSearchProps } from "../../types/image.type";
import { IEdgeType, PaginatedType } from "../../types/paginated.type";
import { albumImagesQuery } from "../../graphql/imageQueries";
import { useQuery } from "@apollo/client";
import { ErrorService } from "../../services";
import { findAlbumByShortIdQuery } from "../../graphql/albumQueries";
import { Album } from "../../types/album.type";
import { useParams } from "react-router-dom";
import { useAuth, useLocalizedNavigate } from "../../hooks";
import { useTranslations } from "use-intl";
import { Loading } from "../../common";
import { PhotoWall } from "./components";

const PhotoWallPage = () => {
  const t = useTranslations("Photos");
  const { user } = useAuth();
  // Get shortId from url
  const { shortId } = useParams<{ shortId: string }>();
  const [images, setImages] = useState<IEdgeType<Image>[]>([]);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const lastCursor = useRef<string | null>(null);
  const loadCount = 20;

  //Get inital value pf photo wall, if to open or not
  const navigate = useLocalizedNavigate();

  //Load album
  const {
    loading: albumLoading,
    error: albumError,
    data: { album } = {},
  } = useQuery<{ album: Album }, { shortId: string }>(findAlbumByShortIdQuery, {
    variables: {
      shortId: shortId ?? "",
    },
    skip: !shortId,
  });

  const albumIsExpired = album?.expirationDate
    ? new Date(album?.expirationDate) < new Date()
    : false;

  //If album is expired, navigate to expired page
  useEffect(() => {
    if (albumIsExpired) navigate("/album-not-found");
  }, [albumIsExpired]);

  useEffect(() => {
    //If unatuthenticated and is password protected
    if (error && isUnauthenticatedError(error)) {
      navigate(`/album/password-protected/${album?.shortId}`, {
        state: { from: window.location.pathname },
      });
    } else if (error) ErrorService.showError(t("errorFetching"));
  }, [error]);

  useEffect(() => {
    //If album not found navigate to not found page
    if (albumError && isNotFoundError(albumError)) {
      navigate("/album-not-found");
    }
    if (albumError) ErrorService.showError(t("errorLoadingAlbum"));
  }, [albumError]);

  const loadInitialData = async () => {
    if (album) {
      console.log("load initial");

      apolloClient
        .query<{ albumImages: PaginatedType<Image> }, ImageSearchProps>({
          query: albumImagesQuery,
          variables: {
            albumId: album.id,
            first: loadCount,
            published: true,
            approved: true,
          },
          fetchPolicy: "no-cache",
          context: {
            headers: {
              ...(user.isGuest && { guestuserid: user.id }),
            },
          },
        })
        .then((resp) => {
          if (resp.data?.albumImages) {
            // If no images and opened photo wall, exit photowall mode
            if (resp.data?.albumImages.totalCount === 0) {
              navigate("/album/" + shortId);
            }
            setImages(resp.data?.albumImages.edges);
            setHasNextPage(resp.data.albumImages.hasNextPage);
          }
        })
        .catch((err) => {
          setError(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const loadMore = useCallback(
    (cursor: string) => {
      if (album) {
        if (!hasNextPage || lastCursor.current === cursor || loading) return;
        console.log("loadmore");

        apolloClient
          .query<{ albumImages: PaginatedType<Image> }, ImageSearchProps>({
            query: albumImagesQuery,
            variables: {
              after: cursor,
              albumId: album.id,
              first: loadCount,
              published: true,
              approved: true,
            },
            fetchPolicy: "no-cache",
            context: {
              headers: {
                ...(user.isGuest && { guestuserid: user.id }),
              },
            },
          })
          .then((resp) => {
            if (resp.data?.albumImages) {
              lastCursor.current = cursor;
              setImages((img) => [
                ...img,
                ...(resp.data?.albumImages.edges as IEdgeType<Image>[]),
              ]);
              setHasNextPage(resp.data.albumImages.hasNextPage);
            }
          })
          .catch((err) => {
            setError(err);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
    [album, hasNextPage, loading]
  );

  //Load the images
  useEffect(() => {
    if (album && !loading) {
      setLoading(true);
      loadInitialData();
    }
  }, [album]);

  return (
    <div
      id="main-container"
      className="flex-1 flex flex-col justify-center relative min-h-dvh min-w-screen"
    >
      {/* Add page metadata */}
      <Helmet>
        <title>Rompolo - {album?.name ?? "album"}</title>
        <meta property="og:title" content={album?.name ?? "Rompolo album"} />
        {album?.description && (
          <meta property="og:description" content={album?.description} />
        )}
        {album?.profileImage && (
          <meta property="og:image" content={getImageUrl(album.profileImage)} />
        )}
        {/* Dynamically set the URL */}
        <meta
          property="og:url"
          content={typeof window !== "undefined" ? window.location.href : ""}
        />
        <meta property="og:type" content="website" />
      </Helmet>

      {albumLoading && <Loading />}
      {/* Photo wall component, show only when photo wall is true */}
      {images.length > 0 && album && (
        <PhotoWall
          album={album}
          hasNextPage={hasNextPage}
          loadMore={loadMore}
          images={images}
        />
      )}
    </div>
  );
};

export default PhotoWallPage;
