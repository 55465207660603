import { ShortLogo } from ".";

const RompoloMessage = ({
  children,
  className,
  withPadding = true, // Default to true if not provided
}: {
  children: React.ReactNode;
  className?: string;
  withPadding?: boolean; // Adding the withPadding prop
}) => {
  // Define padding classes based on withPadding's value
  const paddingClasses = withPadding ? "p-6 md:p-12" : "p-0";

  return (
    <div
      className={`bg-white border border-light-gray rounded-xl ${paddingClasses} max-w-[420px] mx-auto ${
        className || ""
      }`}
    >
      {children}
      <div className="flex justify-end mt-2">
        <ShortLogo small />
      </div>
    </div>
  );
};

export default RompoloMessage;
