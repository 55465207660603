import { useTranslations } from "use-intl";
import { EventType } from "../types/event.enum";

const EventSelector = ({
  small = false,
  value,
  handleChange,
}: {
  value: string;
  small?: boolean;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  const t = useTranslations();
  const events: { event: EventType; title: string }[] = [
    { event: EventType.WEDDING, title: t("Event.wedding") },
    { event: EventType.MEMORIAL, title: t("Event.memorial") },
    { event: EventType.PARTY, title: t("Event.party") },
    { event: EventType.CORPORATE_EVENT, title: t("Event.corporate") },
    { event: EventType.OTHER, title: t("Event.other") },
  ];

  return (
    <div className={`flex ${small ? "flex-row gap-3 flex-wrap" : "flex-col"}`}>
      {events.map((e) => (
        <div key={e.event} className={`${!small && "mb-3"}`}>
          <input
            value={e.event}
            type="radio"
            className="peer/radio hidden"
            name={"event"}
            radioGroup="event"
            id={e.event}
            onChange={handleChange}
            checked={value === e.event} // This line checks if the current value matches the event value
          />
          <label
            htmlFor={e.event}
            className={`${
              small && "px-8 py-3"
            } whitespace-nowrap font-semibold text-sm flex bg-light-gray peer-checked/radio:border border-black justify-center cursor-pointer py-3 md:hover:bg-light-beige rounded-lg`}
          >
            {e.title}
          </label>
        </div>
      ))}
    </div>
  );
};

export default EventSelector;
