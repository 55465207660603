// hooks/useScrollToHash.ts
import { useEffect } from "react";

const useScrollToHash = (deps: any[] = []) => {
  useEffect(() => {
    const hash = window.location.hash.substring(1);
    if (hash) {
      const element = document.getElementById(hash);
      if (element) {
        element.scrollIntoView({ behavior: "instant" });
      }
    }
  }, deps);
};

export default useScrollToHash;
