import { useTranslations } from "use-intl";
import Text from "./Text";
import { Tooltip } from "@mantine/core";

const Premium = ({ gray = false }: { gray?: boolean }) => {
  const t = useTranslations("AlbumSettings");

  return (
    <Tooltip multiline w={300} color="#222" label={t("premiumTooltip")}>
      <Text
        wrap="nowrap"
        size={"sm"}
        className={` font-semibold px-3 py-2 rounded-full cursor-default ${
          gray ? " bg-light-gray" : "bg-white"
        } inline-block`}
      >
        {t("premium")}
      </Text>
    </Tooltip>
  );
};

export default Premium;
