import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { findBadgeByShortIdQuery } from "../../graphql/badgeQueries";
import { Badge } from "../../types/badge.type";
import {
  CenteredBlockWrapper,
  Loading,
  RompoloMessage,
  Text,
} from "../../common";
import { useEffect } from "react";
import { useTranslations } from "use-intl";
import { ErrorService } from "../../services";
import { useLocalizedNavigate } from "../../hooks";

const BadgeRedirectPage = () => {
  const t = useTranslations("Badges");
  // Get shortId from url
  const { shortId } = useParams<{ shortId: string }>();
  const navigate = useLocalizedNavigate();
  const { loading, error, data } = useQuery<
    { badgeByShortId: Badge },
    {
      shortId?: string;
    }
  >(findBadgeByShortIdQuery, {
    variables: {
      shortId,
    },
    skip: !shortId,
  });

  useEffect(() => {
    if (error) {
      ErrorService.showError(t("badgeNotFound"));
    }
  }, [error]);

  useEffect(() => {
    const album = data?.badgeByShortId.album;

    if (album) {
      navigate("/album/" + album.shortId);
    }
  }, [data]);

  const centeredLoader = (
    <div className="absolute flex justify-center items-center w-screen h-screen">
      <Loading />
    </div>
  );

  return (
    <>
      {!loading ? (
        data && !data.badgeByShortId?.albumId ? (
          <CenteredBlockWrapper>
            <RompoloMessage withPadding={false}>
              <Text>{t("albumNotAssigned")} </Text>
            </RompoloMessage>
          </CenteredBlockWrapper>
        ) : !data ? (
          <CenteredBlockWrapper>
            <RompoloMessage withPadding={false}>
              <Text>{t("badgeNotFound")}</Text>
            </RompoloMessage>
          </CenteredBlockWrapper>
        ) : (
          centeredLoader
        )
      ) : (
        centeredLoader
      )}
    </>
  );
};

export default BadgeRedirectPage;
