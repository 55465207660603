import { useState } from "react";
import { useLockBodyScroll } from "../../hooks";
import { IEdgeType } from "../../types/paginated.type";
import { Album } from "../../types/album.type";
import { Image as DBImage } from "../../types/image.type";
import { Swiper, SwiperSlide, SwiperClass } from "swiper/react";
import { Virtual } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import {
  GalleryArrows,
  GalleryImage,
  ImageDetailsDesktop,
  ImageGalleryHeaderDesktop,
} from ".";
import { getImageUrl } from "../../lib/utils";
import { IconX } from "@tabler/icons-react";

const ImageGallerySwipable = ({
  images,
  imageIndex = 0,
  loadMore,
  onClose,
  onUpdated,
  onRemoved,
  album,
}: {
  album: Album;
  images: IEdgeType<DBImage>[];
  imageIndex: number;
  loadMore: (cursor: string) => void;
  onClose: (currentIndex: number) => void;
  onUpdated: (image: DBImage) => void;
  onRemoved: (id: string) => void;
}) => {
  const [currentImage, setCurrentImage] = useState<number>(imageIndex);
  const [swiper, setSwiper] = useState<SwiperClass>();

  const remove = (id: string) => {
    onRemoved(id);

    // If the image being removed is the last one
    if (currentImage === images.length - 1) {
      // If there are no images left
      if (images.length === 1) {
        // Close the gallery
        onClose(currentImage);
      } else {
        // Slide back one image
        setCurrentImage(currentImage - 1);
        goToSlide(currentImage - 1);
      }
    }
  };

  useLockBodyScroll(true);

  // Use this function to change slide programmatically
  const goToSlide = (index: number) => {
    swiper?.slideTo(index);
  };

  return (
    <div className="fixed w-screen h-screen inset-0 z-50 flex items-center justify-center">
      <div
        onClick={() => onClose(currentImage)}
        className="fixed z-30 bg-black/50 inset-0"
      ></div>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="w-[90vw] h-[90vh] bg-white p-6 rounded-lg z-50 relative flex flex-col"
      >
        <IconX
          className=" stroke-white cursor-pointer top-4 -right-10 absolute stroke-3"
          size={24}
          onClick={() => onClose(currentImage)}
        />
        <GalleryArrows
          currentIndex={currentImage}
          images={images}
          nextImage={() => goToSlide(currentImage + 1)}
          prevImage={() => goToSlide(currentImage - 1)}
        />
        <div className="flex">
          {images[currentImage] && (
            <ImageGalleryHeaderDesktop
              image={images[currentImage].node}
              album={album}
              onRemoved={remove}
              onUpdated={onUpdated}
            />
          )}
        </div>
        <div className="flex-1 flex relative overflow-hidden">
          <Swiper
            watchSlidesProgress
            onSwiper={setSwiper}
            modules={[Virtual]}
            virtual
            direction={"horizontal"}
            pagination={{
              clickable: true,
            }}
            className="flex flex-1 z-10 rounded-t-lg"
            initialSlide={currentImage}
            onSlideChange={(swiper) => {
              // Set current slide number to state
              setCurrentImage(swiper.activeIndex);

              // Load more when there are only 3 images left till the end
              if (swiper.activeIndex >= images.length - 3) {
                loadMore(images[images.length - 1].cursor);
              }
            }}
          >
            {images.map((image) => (
              <SwiperSlide
                className="gallery-slide flex items-center justify-center max-h-full"
                key={image.node.id}
              >
                {({ isVisible }) => (
                  <GalleryImage
                    isVisible={isVisible}
                    key={image.node.id}
                    image={image.node}
                    url={getImageUrl(image.node.url)}
                    thumbnailUrl={getImageUrl(image.node.thumbnailUrl)}
                  />
                )}
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        {images[currentImage] && (
          <div className="rounded-b-lg overflow-hidden">
            <ImageDetailsDesktop image={images[currentImage]?.node} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageGallerySwipable;
