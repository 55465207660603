import { Album } from "../../../types/album.type";
import { LogoBadge } from "../../../common";
import { getImageUrl } from "../../../lib/utils";
import AlbumDetails from "./AlbumDetails";
import { useIsSmallScreen } from "../../../hooks";

const SingleHeader = ({
  album,
  ...props
}: {
  hasImages?: boolean;
  album: Album;
  disabled?: boolean | undefined;
  onUploadComplete: () => void;
  totalImageCount?: number;
  loadData: () => Promise<number | void>;
  fileUploadTooltip?: string;
}) => {
  const coverImageUrl = album.profileImage && getImageUrl(album.profileImage);
  const isSmallScreen = useIsSmallScreen();

  return (
    <>
      <div
        className={`w-screen flex md:flex-row flex-col-reverse ${
          coverImageUrl && "md:min-h-[560px]"
        } `}
      >
        {!album.removeBranding && <LogoBadge album={album} />}
        {coverImageUrl && (
          <div className="flex-1 max-h-[560px] relative overflow-hidden flex items-center">
            <img
              src={coverImageUrl}
              alt={album.name}
              className="w-full h-full object-cover"
              style={!isSmallScreen ? {} : {}}
            />
          </div>
        )}
      </div>
      <div
        className={`mx-auto max-w-[700px] ${
          !coverImageUrl ? "pt-16" : "pt-12"
        }`}
      >
        <AlbumDetails album={album} {...props} />
      </div>
    </>
  );
};

export default SingleHeader;
