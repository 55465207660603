import { IconMessageCircle2 } from "@tabler/icons-react";

const ImageCommentIcon = () => {
  return (
    <div className=" w-8 h-8 bg-white bg-opacity-20 bg-blur-md bg-gray-300 backdrop-blur-md flex justify-center items-center rounded-tr-md rounded-bl-md absolute bottom-0 left-0">
      <IconMessageCircle2 className="stroke-white" width={16} height={16} />
    </div>
  );
};

export default ImageCommentIcon;
