import { gql } from "@apollo/client";

export const UserDetails = gql`
  fragment UserDetails on User {
    email
    fullName
    id
    role
    verified
    onboarded
    updatesOnContributions
    newsAndReleases
    googleId
  }
`;

// Query for getting current user data
export const currentUserQuery = gql`
  query CurrentUser {
    currentUser {
      ...UserDetails
    }
  }
  ${UserDetails}
`;

export const verifyEmailMutation = gql`
  mutation VerifyEmail($input: String!) {
    verifyEmail(token: $input)
  }
`;

export const forgotPasswordMutation = gql`
  mutation ForgotPassword($input: ForgotPasswordInput!) {
    forgotPassword(forgotPasswordInput: $input)
  }
`;

export const passwordResetMutation = gql`
  mutation ResetPassword($input: PasswordResetInput!) {
    resetPassword(resetPasswordInput: $input)
  }
`;

export const updateUserPasswordMutation = gql`
  mutation UpdateUserPassword($input: UpdateUserPasswordInput!) {
    updateUserPassword(updateUserPasswordInput: $input)
  }
`;

export const resendVerificationEmailMutation = gql`
  mutation ResendVerificationEmail {
    resendVerificationEmail
  }
`;

export const deleteAccountMutation = gql`
  mutation DeleteAccount {
    deleteAccount
  }
`;

export const updateUserMutation = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(updateUserInput: $input) {
      ...UserDetails
    }
  }
  ${UserDetails}
`;

//Find total count of users
export const findTotalUserCount = gql`
  query FindTotalUserCount {
    findTotalUserCount
  }
`;

export const findUserCountByDay = gql`
  query FindUserCountByDay($createdAtEnd: DateTime, $createdAtStart: DateTime) {
    findUserCountByDay(
      createdAtEnd: $createdAtEnd
      createdAtStart: $createdAtStart
    ) {
      accumulated_count
      date
    }
  }
`;
