type TitleSize = "H1" | "H2" | "H3" | "H4" | "H5";
type Wrap = "nowrap" | "wrap";

const sizeClasses: Record<TitleSize, string> = {
  H1: "text-[32px] leading-9 md:text-[58px] md:leading-[69px]",
  H2: "text-2xl md:text-4xl",
  H3: "text-[20px] md:text-2xl",
  H4: "text-lg md:text-[20px]",
  H5: "text-lg",
};

const Title = ({
  children,
  size,
  className,
  wrap = "wrap",
}: {
  children: React.ReactNode;
  size: TitleSize;
  className?: string;
  wrap?: Wrap;
}) => {
  return (
    <p
      className={`${
        className ? className : ""
      }  font-poppins font-semibold tracking-tight text-black ${
        sizeClasses[size]
      } ${wrap === "wrap" ? "whitespace-pre-line" : "whitespace-nowrap"}`}
    >
      {children}
    </p>
  );
};

export default Title;
