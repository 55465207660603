export interface User {
  id: string;
  email?: string;
  fullName?: string;
  role?: Role;
  verified?: Date;
  onboarded?: Date;
  isGuest?: boolean;
  updatesOnContributions?: boolean;
  newsAndReleases?: boolean;
  googleId?: number;
}

export interface UpdateUserInput {
  fullName?: string;
  email?: string;
  onboarded?: Date;
  updatesOnContributions: boolean;
  newsAndReleases: boolean;
}

export enum Role {
  USER = "USER",
  ADMIN = "ADMIN",
}
