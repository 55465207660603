import { FloatingPosition, Popover } from "@mantine/core";
import React, { cloneElement, useState, useMemo, ReactElement } from "react";
import Button from "./Button";
import { IconChevronUp } from "@tabler/icons-react";
import { useTranslations } from "use-intl";
import { useClickOutside } from "@mantine/hooks";

interface MoreActionsProps {
  position: FloatingPosition;
  children?: React.ReactNode;
}

const MoreActions: React.FC<MoreActionsProps> = ({ position, children }) => {
  const t = useTranslations("Photos");
  const [moreOpened, setMoreOpened] = useState(false);
  const ref = useClickOutside(() => setMoreOpened(false));

  const childrenWithOnClick = useMemo(() => {
    return React.Children.map(children, (child, index) => {
      if (React.isValidElement(child)) {
        return cloneElement(child as ReactElement, {
          onClick: () => {
            child.props.onClick && child.props.onClick();
            setMoreOpened(false);
          },
          key: index,
        });
      }
      return null;
    });
  }, [children]);

  if (childrenWithOnClick && childrenWithOnClick.length === 1) {
    return <Button type="outline" title={childrenWithOnClick[0]} />;
  }

  return (
    <Popover opened={moreOpened} width={"60%"} position={position} shadow="md">
      <Popover.Target>
        <Button
          onClick={() => setMoreOpened((s) => !s)}
          type="outline"
          title={t("moreActions")}
          iconRight={<IconChevronUp size={16} className="stroke-black" />}
        />
      </Popover.Target>
      <Popover.Dropdown ref={ref} className="p-0 rounded-xl">
        <div className="flex flex-col gap-6 p-6">{childrenWithOnClick}</div>
      </Popover.Dropdown>
    </Popover>
  );
};

export default MoreActions;
