import { ScrollArea } from "@mantine/core";
import { Album } from "../types/album.type";
import HashtagBadge from "./HashtagBadge";
import { useTranslations } from "use-intl";

const HashtagSelector = ({
  album,
  selectedHashtags,
  onClick,
  disabled,
  className = "",
  withStroke = true,
  withSeeAll = false,
}: {
  album: Album;
  selectedHashtags: string[];
  onClick: (hashtag?: string) => void;
  disabled?: boolean;
  className?: string;
  withStroke?: boolean;
  withSeeAll?: boolean;
}) => {
  const t = useTranslations("Hashtags");
  return (
    <ScrollArea offsetScrollbars>
      <div className={"flex md:flex-wrap gap-2 md:gap-4 " + className}>
        {withSeeAll && (
          <HashtagBadge
            withStroke={withStroke}
            disabled={disabled}
            selected={selectedHashtags.length === 0}
            onClick={() => onClick(undefined)}
            title={t("seeAll")}
          />
        )}
        {album?.hashtags?.map((ht, index) => (
          <HashtagBadge
            withStroke={withStroke}
            disabled={disabled}
            selected={selectedHashtags.includes(ht.id)}
            onClick={() => onClick(ht.id)}
            key={index}
            title={ht.title}
          />
        ))}
      </div>
    </ScrollArea>
  );
};

export default HashtagSelector;
