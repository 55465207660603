import { useTranslations } from "use-intl";
import { OnboardingWrapper } from ".";
import { useFormikContext } from "formik";
import { AlbumColorPicker, Button, Input, Text, Title } from "../../../common";
import { Album } from "../../../types/album.type";
import { OnboardingValues } from "../OnboardingPage";

const SecondStep = ({
  goNext,
  loading,
}: {
  goNext: () => void;
  loading: boolean;
}) => {
  const t = useTranslations();
  const formik = useFormikContext<OnboardingValues>();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue("color", event.target.value);
  };

  return (
    <OnboardingWrapper>
      <div className="flex justify-center px-4 md:px-0">
        <div className="md:max-w-[340px]">
          <div className="pt-14 md:pt-20">
            <Title size="H3">{t("Onboarding.personalization")}</Title>
            <Text className="pt-2" color="gray">
              {t("Onboarding.personalizationDescription")}
            </Text>
          </div>
          <div className=" mt-8 md:mt-10">
            <Input
              name="name"
              onChange={formik.handleChange}
              className="mb-3"
              placeholder={t("Onboarding.albumName")}
              maxLength={200}
            />
            <AlbumColorPicker
              value={formik.values.color}
              name="color"
              className="mt-6"
              handleChange={handleChange}
            />
            <Text className=" mt-10">{t("Onboarding.relax")}</Text>
          </div>
        </div>
      </div>
      <div className="flex-1 justify-center flex mb-8 items-end md:justify-end md:flex-col pt-8 px-4 md:m-10">
        <Button
          loading={loading}
          title={t("Event.continue")}
          onClick={async () => {
            const { values } = formik;
            if (values.name && values.color) {
              const album = (await formik.submitForm()) as unknown as Album;
              if (album) {
                goNext();
              }
            }
          }}
        />
      </div>
    </OnboardingWrapper>
  );
};

export default SecondStep;
