import { useTranslations } from "use-intl";
import { Button, RoundedBox, Text } from "../../../common";
import { useLocalizedNavigate } from "../../../hooks";

const AlbumExpired = () => {
  const t = useTranslations("Dashboard");
  const navigate = useLocalizedNavigate();
  return (
    <RoundedBox
      color="light-beige"
      className=" mb-6 flex lg:flex-row flex-col lg:gap-20 gap-4 justify-between lg:items-center"
    >
      <div>
        <Text className=" font-semibold mb-2">{t("freshStart")}</Text>
        <Text>{t("freshStartDescription")}</Text>
      </div>
      <div className="mt-4 flex justify-end">
        <Button
          onClick={() => {
            navigate("/pricing");
          }}
          type="outline"
          title={t("viewPricingPlans")}
        />
      </div>
    </RoundedBox>
  );
};

export default AlbumExpired;
