import {
  IconAlbum,
  IconBadge,
  IconChartBar,
  IconHome2,
  IconPhoto,
  IconSettings,
  IconTemplate,
} from "@tabler/icons-react";
import { LocalizedLink } from "../../router";
import { Text } from "..";
import { useTranslations } from "use-intl";
import { useAuth, useLocalizedLocation } from "../../hooks";
import { Role } from "../../types/user.type";

const Menu = ({ closeMenu }: { closeMenu: () => void }) => {
  const t = useTranslations("Menu");
  const { user } = useAuth();
  const location = useLocalizedLocation(); // This hook provides the current location

  const menuItems: {
    name: string;
    icon: any;
    path: string;
    extraClass?: string;
  }[] = [
    { name: t("home"), icon: IconHome2, path: "/" },
    { name: t("photosVideos"), icon: IconPhoto, path: "/photos-videos" },
    { name: t("templates"), icon: IconTemplate, path: "/templates" },
    { name: t("albumSettings"), icon: IconSettings, path: "/album-settings" },
  ];

  const adminMenuItems: {
    name: string;
    icon: any;
    path: string;
    extraClass?: string;
  }[] = [
    { name: t("badges"), icon: IconBadge, path: "/admin/badges" },
    { name: t("albums"), icon: IconAlbum, path: "/admin/albums" },
    { name: t("statistics"), icon: IconChartBar, path: "/admin/statistics" },
  ];

  const isActive = (path: string) => location.pathname === path;

  return (
    <div>
      {menuItems.map((item, index) => (
        <LocalizedLink
          onClick={closeMenu}
          key={index}
          className={`flex px-3 py-2 rounded-xl mb-2 items-center ${
            isActive(item.path) ? "bg-medium-black" : "bg-black"
          } ${item.extraClass || ""}`} // Add the extra class if it exists
          to={item.path}
        >
          <item.icon width={16} height={16} className="stroke-white" />
          <Text className="ml-2 font-medium" color="white">
            {item.name}
          </Text>
        </LocalizedLink>
      ))}
      {user?.role === Role.ADMIN && (
        <>
          <div className="border-b border-medium-black w-full my-6"></div>
          {adminMenuItems.map((item, index) => (
            <LocalizedLink
              onClick={closeMenu}
              key={index}
              className={`flex px-3 py-2 rounded-xl mb-2 items-center ${
                isActive(item.path) ? "bg-medium-black" : "bg-black"
              } ${item.extraClass || ""}`} // Add the extra class if it exists
              to={item.path}
            >
              <item.icon width={16} height={16} className="stroke-white" />
              <Text className="ml-2 font-medium" color="white">
                {item.name}
              </Text>
            </LocalizedLink>
          ))}
        </>
      )}
    </div>
  );
};

export default Menu;
