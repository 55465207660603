import { useState } from "react";
import { Button, Text, Title } from ".";

const Dialog = ({
  title,
  onClose,
  description,
  open,
}: {
  open: boolean;
  title: string;
  description: string;
  onClose: () => void;
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(open);

  const closeDialog = () => {
    setIsDialogOpen(false);
    onClose();
  };

  return (
    isDialogOpen && (
      <div
        onClick={closeDialog}
        className=" z-30 fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
      >
        <dialog className="bg-transparent" open={isDialogOpen}>
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            className="max-w-[350px] rounded-xl overflow-hidden p-6 bg-white shadow-xl"
          >
            <Title size="H4">{title}</Title>
            <Text className="pt-4" size="sm">
              {description}
            </Text>
            <div className="flex justify-end pt-4">
              {/* if there is a button in form, it will close the modal */}
              <Button onClick={closeDialog} title="Ok" />
            </div>
          </div>
        </dialog>
      </div>
    )
  );
};

export default Dialog;
