import { Link, useLocation, LinkProps } from "react-router-dom";

// Extending LinkProps to include any additional props if needed
interface LocalizedLinkProps extends LinkProps {
  to: string;
}

const LocalizedLink = ({ to, ...props }: LocalizedLinkProps) => {
  const location = useLocation();
  const locale = location.pathname.split("/")[1];

  // Construct the new path including the locale
  const localizedPath = `/${locale}${to.startsWith("/") ? "" : "/"}${to}`;

  return <Link to={localizedPath} {...props} />;
};

export default LocalizedLink;
