import { useState } from "react";
import { useTranslations } from "use-intl";
import { useAuth, useLocalizedNavigate } from "../../hooks";
import Dialog from "../Dialog";
import TemplatesInfo from "./TemplatesInfo";

const InfoCenter = () => {
  const { user } = useAuth();
  const t = useTranslations("Dashboard");
  const newsInfoStorageKey = "newsInfoShownTemplates";
  const navigate = useLocalizedNavigate();

  // Local storage
  const onboardingDialogShown =
    localStorage.getItem("dialogShown") === user?.id;
  const newsInfoShown = localStorage.getItem(newsInfoStorageKey) === user?.id;

  // State
  const [onboardingDialogFlagShown, setOnboardingDialogFlagShown] = useState(
    onboardingDialogShown
  );
  const [showNews, setNewsShow] = useState(
    onboardingDialogFlagShown && !newsInfoShown
  );

  const onNewsClosed = () => {
    setNewsShow(false);
    localStorage.setItem(newsInfoStorageKey, user?.id);
  };

  const onNewsClicked = () => {
    navigate("/templates");
    onNewsClosed();
  };

  const onDialogClose = () => {
    setOnboardingDialogFlagShown(true);
    setNewsShow(true);
    localStorage.setItem("dialogShown", user?.id); // Set the flag in localStorage
  };

  return (
    <>
      <Dialog
        open={!onboardingDialogFlagShown}
        title={t("getStarted")}
        onClose={onDialogClose}
        description={`${t("getStartedDescription")}\n \n ${t(
          "getStartedNote"
        )}`}
      />
      {showNews && (
        <TemplatesInfo onClicked={onNewsClicked} onClose={onNewsClosed} />
      )}
    </>
  );
};

export default InfoCenter;
