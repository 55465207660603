import React from "react";
import { Logo, Title, Text } from ".";

type AuthPageWrapperProps = {
  title: string;
  subtitle: string;
  children: React.ReactNode;
};

const AuthPageWrapper = ({
  title,
  subtitle,
  children,
}: AuthPageWrapperProps) => {
  return (
    <div className="flex flex-1">
      <div className="flex-1 flex justify-center">
        <div className="md:pt-10 pt-4 md:w-[340px] px-4">
          <Logo />
          <div className="pt-14 md:pt-20">
            <Title size="H3">{title}</Title>
            <Text className="pt-2" color="gray">
              {subtitle}
            </Text>
            <div className="pt-8">{children}</div>
          </div>
        </div>
      </div>
      <div className="hidden md:block bg-cover bg-no-repeat flex-1 bg-gradient-pattern"></div>
    </div>
  );
};

export default AuthPageWrapper;
