import React from "react";
import { TextColor, TextSize, Wrap } from "../types/text.type";

const textColorClasses = {
  gray: "text-medium-black",
  black: "text-black",
  white: "text-white",
  red: "text-red",
  green: "text-green",
};

const textSizeClasses = {
  xs: "text-xs",
  sm: "text-sm",
  base: "text-base",
  lg: "text-lg",
  xl: "text-xl",
  "2xl": "text-2xl",
  "3xl": "text-3xl",
};

const Text = React.forwardRef<
  HTMLParagraphElement,
  {
    inline?: boolean;
    color?: TextColor;
    size?: TextSize | number; // Allow size to be either TextSize or number
    wrap?: Wrap;
  } & React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLParagraphElement>,
    HTMLParagraphElement
  >
>(
  (
    {
      children,
      className,
      color = "black",
      size,
      wrap = "wrap",
      inline = false,
      style,
      ...props
    },
    ref
  ) => {
    const sizeClass =
      typeof size === "number"
        ? undefined
        : size
        ? textSizeClasses[size]
        : "text-base";
    const customStyle =
      typeof size === "number" ? { fontSize: `${size}px`, ...style } : style;

    return (
      <p
        {...props}
        ref={ref}
        style={customStyle}
        className={`${className ? className : ""} ${sizeClass} tracking-tight ${
          wrap === "wrap" ? "whitespace-pre-line" : "whitespace-nowrap"
        } ${textColorClasses[color]} ${inline ? "inline" : ""}`}
      >
        {children}
      </p>
    );
  }
);

export default Text;
