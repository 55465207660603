import { useTranslations } from "use-intl";
import { Button, Text } from "../../../common";
import { useLocalizedNavigate } from "../../../hooks";

const Expired = () => {
  const t = useTranslations("Photos");
  const navigate = useLocalizedNavigate();

  return (
    <div className="flex-1 flex flex-col py-[48px] justify-center items-center px-4 md:px-0 bg-white rounded-xl mt-6">
      <Text className="md:max-w-[690px]  text-center" color="gray">
        {t("albumExpiredDescription")}
      </Text>
      <Button
        type="outline"
        className="mt-6"
        onClick={() => navigate("/pricing")}
        title={t("viewPlans")}
      />
    </div>
  );
};

export default Expired;
